import React, { Component } from "react";
// import { Link } from "react-router-dom";
import moment from "moment";
import SideNav from "../template/SideNav";
import TopHeader from "../template/TopHeader";
import { connect } from "react-redux";
import {
  bookingActions,
  hairdresserActions,
  productAction,
  paymentActions,
  userActions,
} from "../../_actions";
import siteSetting from "../../config/env";
import dateFormat from "dateformat";
import toast from "react-toast-notification";
import animationData from "../../assets/animation-data/loader.json";
import { ServiceCheckout } from "../newBooking/ServiceCheckout";
import ClientCheckout from "../hairdresserBooking/ClientCheckout";
import moment2 from "moment-timezone";

// selectHairdresserBookingDate,
 // selectHairdresserBookingTime

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

var _ = require("lodash");
const isMobile = window.innerWidth <= 768;
let userIdHolder = [];
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// function getslotTime(time) {
//   let hr = parseInt(time / 60);
//   let min = time - hr * 60;
//   return (hr <= 9 ? '0' + hr : hr) + ':' + (min == 0 ? '00' : (min <= 9 ? '0' + min : min));
// }

function getslotTimeSec(time) {
    let hr = parseInt(time / 60);
    let min = time - (hr * 60);
    //console.log("time",time,hr,min);
    return (hr <= 9 ? '0' + hr : hr) + ':' + (min == 0 ? '00' : (min <= 9 ? '0' + min : min)) + ":00";
}

class NewBooking extends Component {
  constructor(props) {
    super(props);
    let bookingDate = this.props.location.state&&this.props.location.state.bookingDate?new Date(this.props.location.state.bookingDate):new Date();
    let bookingdate1=this.props.location.state&&this.props.location.state.bookingDate?this.props.location.state.bookingDate:new Date();
      var seconds = bookingDate.getSeconds();
      var minutes = bookingDate.getMinutes();
      var hour = bookingDate.getHours();
      var getMilliseconds =bookingDate.getMilliseconds()
      //var getTimezone = bookingDate.toString().match(/([A-Z]+[\+-][0-9]+)/)[1];
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const targetTimeZone = timeZone;
      const currentDateInTimeZone = moment2.tz(bookingdate1, targetTimeZone);
      let formattedDate = new Date(currentDateInTimeZone);
      formattedDate.setHours(hour);
      formattedDate.setMinutes(minutes);
      formattedDate.setSeconds(seconds);
      formattedDate.setMilliseconds(getMilliseconds);
      console.log('checkbookingDate',bookingDate,"=====",formattedDate);
    this.state = {
      //bookingDate: new Date(),
      bookingDate: formattedDate,
      selectSlot: this.props.location.state? this.props.location.state.start : "",
      showOptionsModal: false,
      isSelected: false,
      showTipModal: false,
      latitude: siteSetting.default_latitude,
      longitude: siteSetting.default_longitude,
      hairdresserName: "Select Staff Member",
      selectedService: [],
      finalBookingSlots: [],
      includeTimes: [],
      includeEndTimes:[],
      slotInfo: {},
      totalAmount: 0,
      totalTime: 0,
      hairdresserId: this.props.match.params.hairdresserId
        ? this.props.match.params.hairdresserId
        : "",
      selectedClientId: "",
      serviceSelectionError:'',
      selectedClient: siteSetting.noClientDAta,
      isSaveBooking: 0,
      bookingSlots: [],
      selectEndTimeSlot:'',
      paymentType: "",
      isBookingAllowed: false,
      searchKey: "",
      pendingAmount: 0,
      transaction: [],
      paymentAmount: 0,
      totalPaymentAmount: 0,
      isInvoice: 0,
      eventError: "",
      startTimeError: "",
      serviceError: "",
      bookingError: "",
      eventStartTime: 0,
      eventEndTime: 0,
      eventTitle: "",
      durationErrorMessage:'Duration must be a multiple of 5.',
      eventTitleError: "",
      hairdresserIdEventError: "",
      currencySymbol: "£",
      isSelectClient: false,
      mobileSelectedStep: 0,
      selectedPaymentType: null,
      selectedAmount: "",
      checkOutAmountType: true,
      selectedFixedAmount: 0,
      isPaymentComplete: 0,
      bookingID: "",
      isMobileEnableState: true,
      locationId: "",
      selectedCardReader: {},
      bookingSelectedItems: [],
      staffList: [],
      productList: [],
      serviceList: [],
      selectedServices: [{}],
      allServices: [],
      fromServices: [],
      selectedStaff: null,
      cancelButton:true,
      previousPageUrl:'',
      durationError:'',
      isEndDisable:true,
        isbookingSubmit:false,
        hairdresserError:""
    };

    this.saveBooking = this.saveBooking.bind(this);
    this.bookingSlotCreate = this.bookingSlotCreate.bind(this);
    this.selectTimeSlot = this.selectTimeSlot.bind(this);
    this.changeHairdresser = this.changeHairdresser.bind(this);
    this.createBooking = this.createBooking.bind(this);
    this.addServiceItem = this.addServiceItem.bind(this);
    this.setSelectedService = this.setSelectedService.bind(this);
    this.selectDay = this.selectDay.bind(this);
    this.resetBooking = this.resetBooking.bind(this);
    this.checkoutBooking = this.checkoutBooking.bind(this);
    this.selectEndTime = this.selectEndTime.bind(this);
    this.changeServiceDuration = this.changeServiceDuration.bind(this);
    this.onChangeProcessingTime = this.onChangeProcessingTime.bind(this);
  }

  handleOptionModal = () =>
    this.setState({ showOptionsModal: !this.state.showOptionsModal });

  componentDidMount() {
    var self = this;
    let pageStatus = 0;
    const { state } = this.props.location;

      if (state) {
      const { pageLoadingStatus,previousPageUrl,start } = state;
          self.setState({
            previousPageUrl:previousPageUrl,
          })
      
          pageStatus = pageLoadingStatus!=undefined?pageLoadingStatus:0;
        if(pageLoadingStatus===1){
          // all redux clear here
          self.props.dispatch(hairdresserActions.selectedService({}));
          self.props.dispatch(bookingActions?.hairdresserNewBookingData([{
              id: 1,
              indexId: 1,
              type: "service",
              bookingDate: self.state.bookingDate,
          }]));
          self.props.dispatch(bookingActions?.hairdresserNewBookingClient({}));
          self.props.dispatch(bookingActions?.selectHairdresserBookingDate({}));
          self.props.dispatch(bookingActions?.clientBookingSlots({}))
          self.props.dispatch(bookingActions?.hairdresserNewBookingStaff({}));
          self.props.dispatch(productAction.selectedClient({}));
          self.props.dispatch(productAction?.selectedCheckoutList([]));
          self.props.dispatch(hairdresserActions.getHairdresserClient({  'searchKey': '', 'hairdresserId': localStorage.getItem('userId'), page: 1, listType: 1,isDownload:0 }))
          
        } 
      }

    let selectedHairdresserId = self?.props?.match?.params?.hairdresserId!==undefined?self?.props?.match?.params?.hairdresserId:localStorage.getItem("userId");
    let selectedSalonId = self?.props?.match?.params?.salonId;
    let _selectedServicesData = self?.props?.hairdresserNewBookingData;
    const updateBookingInfo = self?.props?.updateBookingInfo;

      const newBookingAssign = [
          {
              id: 1,
              indexId: 1,
              type: "service",
              bookingDate: self.state.bookingDate,
          },
      ];

      let booking__Data =
          _selectedServicesData?.length > 0
              ? _selectedServicesData
              : newBookingAssign;


      self.setState({
          bookingSelectedItems:booking__Data
      })

      self.props.dispatch(bookingActions.hairdresserNewBookingData(booking__Data))
    if(self.props.staffRota?.rota != undefined) {
        let staffData = self.props.staffRota?.rota != undefined ? self.props.staffRota?.rota : [];
        const staffOptionList = staffData?.map((staff) => {
            return {value: staff?._id, label: staff?.name};
        });

        const serviceHardresserList = staffData?.map((staff) => {
            return {id: staff?._id, name: staff?.name};
        });


        self.setState({
            staffList: staffOptionList,
            serviceHardresserList: serviceHardresserList,
        });


        if (
            selectedHairdresserId !== "" &&
            selectedHairdresserId !== undefined
        ) {
            let selectedHairdresser = _.find(
                staffData,
                function (hairdresser) {
                    return hairdresser._id === selectedHairdresserId;
                }
            );

            let selectedStaffData = self?.props?.hairdresserNewBookingStaff;
            if(selectedStaffData?._id===undefined){
                if(pageStatus===0 || pageStatus===2){
                    self.props.dispatch(bookingActions?.hairdresserNewBookingStaff( {_id:selectedHairdresser?._id,name:selectedHairdresser?.name}));
                }
            }
            if(pageStatus===0 || pageStatus===2){
                self.setState({
                    hairdresserName: selectedStaffData?._id !== undefined ? selectedStaffData?.name:selectedHairdresser?.name,
                    selectedStaff:selectedStaffData?._id !== undefined ? selectedStaffData:{_id:selectedHairdresser?._id,name:selectedHairdresser?.name},
                });
            }
            //if(!state?.pageLoadingStatus) {


                self.setSelectedCheckoutData();
                // if(updateBookingInfo!==undefined){
                //   this.selectEndTime(updateBookingInfo?.selectEndTimeSlot,updateBookingInfo?.totalTime);
                //   console.log('checkData22222',self.props?.updateBookingInfo)
                // }
            //}

        }
    }



    let params = {
      defaultRedirectTo: localStorage.getItem("defaultRedirectTo"),
    };

    let params1 = {
      searchKey: this.state.searchKey,
      hairdresserId:selectedHairdresserId,
      page: 1,
      listType: 1,
      // isGetRota:1,
        diary:1,
    };

    self.setState({
      hairdresserId:selectedHairdresserId,
      //totalAmount:0
    })
    //self?.selectSlot()

    if (localStorage.getItem("accessToken")) {
      self.props
        .dispatch(userActions.profile(params))
        .then(function (response) {
          //console.log("response",response.data.data);
          if (response.data.data) {
            self.setState({
              locationId: response.data.data.userInfo.hairdresser
                .stripeLocationId
                ? response.data.data.userInfo.hairdresser.stripeLocationId
                : "",
            });
          }
        });

      if (
        (selectedHairdresserId !== "" && selectedHairdresserId !== undefined) ||
        (selectedSalonId !== undefined && selectedSalonId !== "")
      ) {
        self.props
          .dispatch(hairdresserActions.hairdresserStaffRota(params1))
          .then(function (response) {
            if (
              selectedHairdresserId !== "" &&
              selectedHairdresserId !== undefined
            ) {
              let selectedHairdresser = _.find(
                response.data.data.rota,
                function (hairdresser) {
                  return hairdresser._id === selectedHairdresserId;
                }
              );

              let selectedStaffData = self?.props?.hairdresserNewBookingStaff;
              if(selectedStaffData?._id===undefined){
                if(pageStatus===0){
                self.props.dispatch(bookingActions?.hairdresserNewBookingStaff( {_id:selectedHairdresser?._id,name:selectedHairdresser?.name}));
                }
              }
              if(pageStatus===0){
                self.setState({
                  hairdresserName: selectedHairdresser.name,
                  selectedStaff:
                    selectedStaffData?._id !== undefined
                      ? selectedStaffData
                      :{_id:selectedHairdresser?._id,name:selectedHairdresser?.name},
                });
              }
              //self.setSelectedCheckoutData();
             
            }
            let staffData =
              response?.data?.data?.rota != undefined
                ? response?.data?.data?.rota
                : [];

            const staffOptionList = staffData?.map((staff) => {
              return { value: staff?._id, label: staff?.name };
            });

            const serviceHardresserList = staffData?.map((staff) => {
              return { id: staff?._id, name: staff?.name };
            });
            
        
            self.setState({
              staffList: staffOptionList,
              serviceHardresserList: serviceHardresserList,
            },function (){
                if(staffOptionList.length===1){
                    let selectedStaffData = self?.props?.hairdresserNewBookingStaff;
                    console.log("selectedStaffData",selectedStaffData,staffOptionList[0]);
                    if(selectedStaffData?._id===undefined){
                        self.props.dispatch(bookingActions?.hairdresserNewBookingStaff( {_id:staffOptionList[0]?.value,name:staffOptionList[0]?.label}))
                        self.setState({
                            hairdresserName: staffOptionList[0].label,
                            selectedStaff:{_id:staffOptionList[0]?.value,name:staffOptionList[0]?.label},
                        });
                    }
                }
            });
          });
      }
    } else {
      this.props.history.push("/");
    }

  }

  componentDidUpdate(prevState) {
    let self = this;
    let selectedStaffData = self?.props?.hairdresserNewBookingStaff;

    if (selectedStaffData?.value !== undefined) {
    if (prevState?.selectedStaffData !== selectedStaffData) {
        self.setState({
          selectedStaff: selectedStaffData,
        });
      }
    }
  }


  changeHairdresser(hairdresserId, hairdresserName, hairdresser) {
    //need to blank selected service aaray if param hairdresser is undefined
    let params = {};
    let self = this;
    const {dispatch} = this.props?.dispatch;
   
    if(hairdresser===undefined){
      const newService = {
        id: 1,
        indexId: 1,
        type: "service",
      };
      self.setState({
        bookingSelectedItems: [newService],
      });
    }

    this.setState({
      isSelected: true,
      isLoaderEnabled: true,
      startTimeError: "",
      serviceError: "",
      hairdresserId,
      hairdresserName,
      selectedStaff: { _id: hairdresserId, name: hairdresserName },
    });


    params.hairdresserId = hairdresserId;
    params.latitude = this.state.latitude;
    params.longitude = this.state.longitude;
    params.slotsForBookingDate = dateFormat(
      this.state.bookingDate ? this.state.bookingDate : new Date(),
      "yyyy-mm-dd"
    );

    self.props
      .dispatch(hairdresserActions.getHairdresserInfo(params))
      .then(function (response) {
        if (response.data.errorCode) {
          self.setState({
            isLoaderEnabled: false,
            error: response.data.error.messages,
          });
        } else {
          self.setState(
            {
              isLoaderEnabled: false,
              bookingSlots: response.data.data.bookingSlots,
              date: new Date(),
              selectedService: [],
              isBookingButton: false,
              selectedDay: new Date(),
              month: monthNames[new Date().getMonth()],
              year: new Date().getFullYear(),
              selectedBookingSlots: "",
              selectedBookingSlotsIndex: "",
              finalBookingSlots: [],
              includeTimes: [],
              pendingAmount: 0,
              transaction: [],
              paymentAmount: 0,
              totalPaymentAmount: 0,
              currencySymbol:
                hairdresser?.hairdresserInfo?.currencySymbol &&
                hairdresser?.hairdresserInfo?.currencySymbol != "" &&
                hairdresser?.hairdresserInfo?.currencySymbol != "undefined"
                  ? hairdresser?.hairdresserInfo?.currencySymbol
                  : "£",
            },
            function () {
              self.bookingSlotCreate(0);

            }
          );
        }
      });
  }


  saveBooking() {
    let self = this;

    let data = self.props?.hairdresserNewBookingData?.filter(service=>service?.serviceName==undefined);
    
    if(data?.length>0){
      self.setState({
        serviceSelectionError:`Please select Service ${data[0].indexId} before adding another service`
      })
      return;
    }

    if(self.state.durationError!==''){
      return;
    }

    if(isNaN(self.state.totalTime)){
      self.setState({
        durationError:self.state.durationErrorMessage
      })
      return;
    }

    
    if(self.state.totalTime==='' || self.state.totalTime===0){
      self.setState({
        durationError:self.state.durationErrorMessage
      })
      return;
    }

    
    if (this.state.selectSlot === "") {
      this.setState({
        startTimeError: "Please select start time",
      });
    } else if (this.state.totalTime === 0) {
      this.setState({
        serviceError: "Please select service",
      });
    } else {
      this.setState({
        startTimeError: "",
        serviceError: "",
      });
      this.createBooking(0);
    }
  }

  bookingSlotCreate(index) {
    // console.log("bookingSlotCreate",index);
    this.setState({
        startTimeError:'',
        serviceError:''
    })
    let self = this;
    let finalBookingSlots = [];
    let includeTimes = [];
    let pre;
    let next;
    let tempArr = []
    let flag = false;
    let k = index;
    let arrslots = [];
    let bookingSlots = self.state.bookingSlots;
    //bookingSlots.map(function(slots, i) {
    for (let i = index; i < bookingSlots.length; i++) {
        let check = false;
      
        if (bookingSlots[i].status === 0||bookingSlots[i].status === 3) {
            tempArr.push(bookingSlots[i])
            // console.log('tempArr', tempArr,bookingSlots[i]);
            let _totalSeviceTime = self.state.totalTime==0?5:isNaN(self.state.totalTime)?5:self.state.totalTime;
            // console.log('check hairdresser id',_totalSeviceTime,self.state.totalTime)
            if (((tempArr[tempArr.length - 1]["endTime"]) - (tempArr[0]["startTime"])) ===_totalSeviceTime) {
                finalBookingSlots.push(tempArr[0]);

                let hr = parseInt(tempArr[0].startTime / 60);
                let min = tempArr[0].startTime - (hr * 60);

                let incTimeSlot = new Date(self.state.bookingDate);
                //console.log("incTimeSlot",incTimeSlot);
                incTimeSlot.setHours(hr);
                incTimeSlot.setMinutes(min);


                includeTimes.push(incTimeSlot);
                //console.log("tempArr[0]",incTimeSlot,includeTimes)

                for (let m = 0; m < bookingSlots.length; m++) {
                    if (tempArr[0]["startTime"] === bookingSlots[m]["startTime"]) {
                        k = m + 1;
                        flag = true;
                        break;
                    }
                }
                tempArr = []
            }
        } else {
            tempArr = []
        }

        if (flag === true) {
            break
        }
    }

    if (flag && k < bookingSlots.length) {
        self.bookingSlotCreate(k)
    }

    if (finalBookingSlots[0]) {
        let finalslot = this.state.finalBookingSlots;
        finalslot.unshift(finalBookingSlots[0]);

        let incTimes = this.state.includeTimes;
        //console.log('incTimes', incTimes);
        incTimes.unshift(includeTimes[0]);
        // console.log('finalslot', finalslot);
        let morningCount = 0;
        let afternoonCount = 0;
        let eveningCount = 0;
        for (let i = 0; i < finalslot.length; i++) {
            let bookingSlot = finalslot[i];
            let bookingIncTimes = incTimes[i];
            if (bookingSlot) {
                if (bookingSlot.status === 0 && bookingSlot.startTime < 720) {
                    morningCount = 1
                }
                if (bookingSlot.status === 0 && (bookingSlot.startTime >= 720 && bookingSlot.startTime < 1020)) {
                    afternoonCount = 1
                }
                if (bookingSlot.status === 0 && bookingSlot.startTime >= 1020) {
                    eveningCount = 1
                }
            }

        }
       
        let selectedSlot = _.find(finalslot, function (slot) {
            let incTimeSlot = new Date(self.state.selectSlot);
         
            let hr =incTimeSlot.getHours();
            let min =incTimeSlot.getMinutes();
            // console.log("selectedSlot###",slot.startTime === hr*60+min,slot.startTime,hr*60+min);
            return slot.startTime === hr*60+min;
        })
        //console.log("selectedSlot",selectedSlot,incTimes[0],self.state.selectSlot);
        if(!selectedSlot){
            if(self.state.selectSlot&&self.state.selectSlot!=="") {
                let incTimeSlot = new Date(self.state.selectSlot);

                let hr = incTimeSlot.getHours();
                let min = incTimeSlot.getMinutes();
                selectedSlot = {
                    startTime: hr * 60 + min,
                    endTime: hr * 60 + min + 5,
                    list: [{startTime: hr * 60 + min, endTime: hr * 60 + min + 5, info: {}, type: ""}]
                };
                self.setState({
                    selectSlot: self.state.selectSlot
                })
            }else{
                let workingTime=self.getTodayWorkingTime(self.props.hairdresser.rotaInfo);
               // console.log("workingTime",workingTime);
                if(workingTime){
                    selectedSlot = {
                        startTime: workingTime.startTime,
                        endTime: workingTime.endTime + 5,
                        list: [{startTime:  workingTime.startTime, endTime: workingTime.endTime + 5, info: {}, type: ""}]
                    };
                    self.setState({
                        selectSlot: self.state.selectSlot ? self.state.selectSlot : new Date(workingTime.todayStartWorking)
                    })
                }else {
                    selectedSlot = finalslot[0];
                    self.setState({
                        selectSlot: incTimes[0]
                    })
                }
            }
        }
       let hairdresserError='';
        if(finalslot.length===0){
            hairdresserError='This stylist is not scheduled to work this day.'
        }
        this.setState({
            finalBookingSlots: finalslot,
            hairdresserError,
            includeTimes: incTimes,
            includeEndTimes:incTimes,
            morningCount,
            afternoonCount,
            eveningCount,
            slotInfo: selectedSlot,
            //selectSlot: incTimes[0]
        })
    }else{
        this.setState({
            hairdresserError:'This stylist is not scheduled to work this day.'
        })
    }
}

    getTodayWorkingTime = (rotaInfo) => {
        let self = this;
        let todayStr = moment().format('YYYY-MM-DD');
        if (rotaInfo?.rota?.schedule) {
            //console.log("hairdresser.rotaInfo.rota",hairdresser.rotaInfo.rota.schedule[0],moment().format('ddd').toLowerCase());
            let selected = _.find(rotaInfo?.rota?.schedule[0].profiles, function (o) {
                return o.day === moment(self.state.bookingDate).format('ddd').toLowerCase();
            })
            if (selected) {
                return {
                    selectable: 1,
                    todayStartWorking: todayStr + 'T' + getslotTimeSec(selected.startTime),
                    todayEndWorking: todayStr + 'T' + getslotTimeSec(selected.endTime),
                    startTime:selected.startTime,
                    endTime:selected.endTime
                }
            } else {
                return false;
            }
        } else {
             return false;
        }
    }

  selectDay = (e) => {
    this.setState({
      startTimeError: "",
      serviceError: "",
    });

    this.state.bookingDate = e;
    this.state.selectedDay = e;
    //console.log(this.state.bookingDate)
    //this.setState({bookingDate:e[0]})
    let params = {};
    let self = this;
    let s_date = new Date(e);
    self.props.dispatch(bookingActions?.selectHairdresserBookingDate({date:e}));
    self.setState({
      bookingDate: e,
      month: monthNames[s_date.getMonth()],
      year: s_date.getFullYear(),
    });
    let salonId = this.props.match.params.salonId;
    let hairdresserId = self.props.hairdresserNewBookingStaff?._id
      ? self.props.hairdresserNewBookingStaff?._id
      :self?.props?.match?.params?.hairdresserId?self?.props?.match?.params?.hairdresserId:localStorage.getItem("userId");
    params.hairdresserId = hairdresserId;
    params.latitude = this.state.latitude;
    params.longitude = this.state.longitude;
    params.slotsForBookingDate = dateFormat(e, "yyyy-mm-dd");
    self.props
      .dispatch(hairdresserActions.getHairdresserInfo(params))
      .then(function (response) {
        if (response.data.error) {
          self.setState({
            message: response.data.error.message,
          });
        } else {
  
          
          self.setState(
            {
              bookingSlots: response.data.data.bookingSlots,
              selectedBookingSlotsIndex: "",
              finalBookingSlots: [],
              includeTimes: [],
            },
            function () {
              self.bookingSlotCreate(0);
            }
          );
        }
      });
  };

  unselectDay(e) {
    console.log("unselectDay", e);
  }

  getslotTime(time) {
    let hr = parseInt(time / 60);
    let min = time - hr * 60;
    return (
      (hr <= 9 ? "0" + hr : hr) +
      ":" +
      (min == 0 ? "00" : min <= 9 ? "0" + min : min) +
      ":00"
    );
  }

  
  selectEndTime(e,totalSelectedMin=5,from){
      console.log("endTimeData",e,"##",totalSelectedMin,"##",from);
    let self = this;
    let _includeTimes =self.state.includeEndTimes?.length>0?self.state.includeEndTimes:self.state.includeTimes;
    let endTimeData = '';
    if(e&&from==='duration'){
      endTimeData=moment(e).add(totalSelectedMin, 'm').toDate();
    }else{
      endTimeData =e 
    }
    
    let isRelativeTime =0;
    let differenceValue =0;
    let totalDuration=totalSelectedMin;
   
    if(totalSelectedMin===5){
      if(e!==undefined){
        differenceValue =(e.getTime() - this.state.selectSlot.getTime()) / 1000;
        differenceValue /= 60;
        isRelativeTime = Math.round(differenceValue)
        totalDuration = Math.abs(Math.round(differenceValue))>0?Math.abs(Math.round(differenceValue)):totalSelectedMin;
      }
    }
    self.setState({
      includeEndTimes:_includeTimes
    })
  
    
    if(isRelativeTime<0){
      totalDuration ='';
    }
  
    
   console.log("endTimeData",endTimeData);
    const bookingInfo = {
      selectEndTimeSlot:endTimeData,
      totalTime:totalDuration
      };
  
    self.props.dispatch(
      bookingActions?.updateBookingInfo(bookingInfo)
    );
     self.setState(bookingInfo)
  }


  changeServiceDuration(durationTime){
    let self = this;
  
    if(durationTime%5===0 && durationTime>0){
      self.setState({
        durationError:''
         })
         self.setState({
          totalTime:parseInt(durationTime)
           });
    }else{
      self.setState({
        durationError:self.state.durationErrorMessage
         })   
    }
  
    if(durationTime===''){
      self.setState({
        durationError:self.state.durationErrorMessage
         })
    }
    self.selectEndTime(self.state.selectSlot,parseInt(durationTime),'duration');
  }

  selectTimeSlot(e) {
    this.setState({
        startTimeError:'',
        serviceError:''
    })

    let self = this;
    this.setState({
        finalBookingSlots: [],
        includeTimes: []
    }, function () {
        self.bookingSlotCreate(0);
        //console.log('slotInfo,index',slotInfo,index,isSelect);
        let includeTimes = self.state.includeTimes;
        let finalBookingSlots = self.state.finalBookingSlots;
        let totalTime = self.state.totalTime;

        _.remove(includeTimes, function (o) {
            return o <= moment(e).add(totalTime, 'm').toDate() && o > e
        });

        let m = moment(e);
        let minutes = (m.hour() * 60) + m.minute();
        let slotInfo = _.find(finalBookingSlots, function (o) {
            return o.startTime === minutes
        })

        this.selectEndTime(e,totalTime,'duration');
        self.setState({
            slotInfo: slotInfo,
            includeTimes,
            selectSlot: e,
            endTimeSlot: e,
        })
    })
}


  createBooking(param) {
    let self = this;
    // console.log(localStorage.getItem('accessToken'))
    //self.props?.hairdresserNewBookingData

    let params = {};
    // params.servicesToBook = _.map(self?.props?.hairdresserNewBookingData, function (o) {
    //   return o.serviceId;
    // });

    params.servicesToBook =  self?.props?.hairdresserNewBookingData;
    if(this.state?.slotInfo?.startTime===undefined){
      let m = moment(self.state.selectSlot);
      let _startTime = (m.hour() * 60) + m.minute();
      params.bookingSlot = [
        _startTime,
        _startTime + parseInt(this.state?.totalTime)
      ];
    }else{
      params.bookingSlot = [
        this.state?.slotInfo?.startTime,
        this.state?.slotInfo?.startTime + parseInt(this.state?.totalTime)
      ];
    }
    params.userId = this.state.selectedStaff?._id;
    params.bookingDate = dateFormat(this.state?.bookingDate, "yyyy-mm-dd");
    params.deviceToken = "dfdsfdsfsd";
    params.isPayAtSalonBooking = 1;
    params.clientId = this.state.selectedClient?._id;
    params.createdBy = localStorage.getItem('userId');
    params.paidType = this.state.paidType;
    params.transaction = this.state.transaction;
    params.totalAmount = this.state.totalAmount;
    params.invoiceNumber = localStorage.getItem("invoiceNumber");
    self.setState({
        isbookingSubmit:true
    })
    self.props
      .dispatch(bookingActions.createBooking(params))
      .then(function (response) {
          self.setState({
              isbookingSubmit:false
          })
        if (response.data.errorCode) {
          toast.error(response.data.messages);
          // self.setState({
          //     bookingError: response.data.messages
          // })
        } else {
          if (param === 0) {
            let info = {
              bookingDate: self.state.bookingDate,
            };
            self.resetBooking();
            self.props.history.push({
              pathname: "/diary/" + localStorage.getItem("salonId"),
              state: info,
            });
          } else if (param === 2) {
            self.setState({
              isInvoice: 1,
              mobileSelectedStep: 5,
              bookingID: response.data.data.bookingInfo._id,
            });
          } else if (param === 5) {
            const stateObj = { pageLoadingStatus: 1};
            const url =  "/checkout/" + response.data.data.bookingInfo._id;
            self.props.history.push({
              pathname: url,
              state: stateObj,
            });
            //self.props.history.push('/booking-checkout/'+response.data.data.bookingInfo._id)
          } else {
            //self.props.history.push('/newbooking/' + localStorage.getItem('salonId'))
            //window.location.reload();
          }
        }
      });
  }

  checkReadOnly = () => {
    if (
      this.props.user &&
      this.props.user.userInfo &&
      this.props.user.userInfo.hairdresser.isSalonOwner === 1
    ) {
      return true;
    } else {
      return false;
    }
  };


  findRestServices = () => {
    let restServices = [];
    let ids = this.state.selectedServices.map((service) => {
      return service.serviceId;
    });
    for (let i = 0; i < this.state.allServices.length; i++) {
      let allServiceId = this.state.allServices[i].serviceId;

      if (ids.includes(allServiceId)) {
        continue;
      } else {
        restServices.push(this.state.allServices[i]);
      }
    }
    return restServices;
  };

  updateDuration = (event) => {
    const inputTime = event.target.value;
    const duration = parseInt(inputTime, 10);

    if (isNaN(duration)) {
      this.setState({
        totalTime: duration,
      });
    }
  };

  calculateEndTime = () => {
    let endTime = new Date(this.state.selectSlot);
    endTime.setMinutes(endTime.getMinutes() + this.state.totalTime);

    let date = new Date(endTime.getTime());

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    if(this.state.selectSlot && this.state.selectSlot!=''){
      if(this.state.selectSlot.getTime()==date?.getTime()){
        return '_ _';
      }
    }
    
     
    if(isNaN(hours) && isNaN(hours)){
      return '_ _';
    }

    return `${hours}:${minutes}`;
  };



  setSelectedCheckoutData() {
   
    let self = this;
    let selectedStaffData = self?.props?.hairdresserNewBookingStaff;
    let selectedBookingItems = self?.props?.hairdresserNewBookingData;
    let selectedHairdresserId = self?.props?.match?.params?.hairdresserId!==undefined?self?.props?.match?.params?.hairdresserId:localStorage.getItem("userId");
      let currentDate = new Date(self.state.bookingDate);
      let selectedBookingDate = self?.props?.selectHairdresserBookingDate?.date!==undefined?new Date(self?.props?.selectHairdresserBookingDate?.date):currentDate;
      self.selectDay(selectedBookingDate);
    this.setSelectedClient();
    this.setSelectedService();
    
  }

  setSelectedClient = () => {
    let self = this;
    let selectedClientData = self.props.selectedClient;
    if (selectedClientData?._id !== undefined) {
      self.props.dispatch(
        bookingActions?.hairdresserNewBookingClient(selectedClientData)
      );
      self.setState({
        selectedClient: selectedClientData,
        totalAmount:self.state.totalAmount,
      });
    }else{
        self.props.dispatch(
        bookingActions?.hairdresserNewBookingClient(siteSetting.noClientDAta)
      );
      self.props.dispatch(productAction.selectedClient(siteSetting.noClientDAta));
      self.setState({
        selectedClient: siteSetting.noClientDAta,
        totalAmount:self.state.totalAmount,
      });
    }
  };

  checkoutBooking() {
    let self = this;

    if(self.state.durationError!==''){
      return;
    }

    if(isNaN(self.state.totalTime)){
      self.setState({
        durationError:self.state.durationErrorMessage
      })
      return;
    }

    if(self.state.totalTime==='' || self.state.totalTime===0 ){
      self.setState({
        durationError:self.state.durationErrorMessage
      })
      return;
    }

    if (self?.state?.selectSlot === "") {
      self.setState({
        startTimeError: "Please select start time",
      });
    } else if (self?.state?.totalTime === 0) {
      self.setState({
        serviceError: "Please select service",
      });
    } else {
      // self.setState({
      //     isSaveBooking: 1,
      //     mobileSelectedStep:4
      // })
      // self.saveBooking(1);
      self.props.dispatch(productAction?.selectedCheckoutList([]));
      console.log('checkData',44444)
     setTimeout(()=>{
      self.createBooking(5);
     }) 
    }
  }

  addServiceItem() {
    let self = this;
    let data = self.props?.hairdresserNewBookingData?.filter(service=>service?.serviceName==undefined);
    if(data?.length>0){
      self.setState({
        serviceSelectionError:`Please select Service ${data[0].indexId} before adding another service`
      })
      return;
    }

      if(self.props?.hairdresserNewBookingData?.length===0){
          // self.setState({
          //     hairdresserError:`Please select Service before adding another service`
          // })
          return;
      }

    if(self.state.totalTime==='' || isNaN(self.state.totalTime)){
      self.setState({
        durationError:self.state.durationErrorMessage
      })
      return;
    }

    if(self.state.durationError!==''){
      return;
    }


  

    if(self.state.totalTime==='' || self.state.totalTime===0 ){
     
      self.setState({
        durationError:self.state.durationErrorMessage
      })
      return;
    }

    let dispatch = self.props?.dispatch;
    let _selectedServices = self.props?.hairdresserNewBookingData;
    let lastIndex = _selectedServices.length + 1;

    const newService = {
      id: lastIndex,
      indexId: lastIndex,
      type: "service",
    };
    let serviceData = [..._selectedServices, newService];
    dispatch(bookingActions?.hairdresserNewBookingData(serviceData));
    self.setState({
      bookingSelectedItems: serviceData,
    });
  }

  removeServiceItem = (type, selectedItemId, index) => {
    let self = this;
    let dispatch = self.props?.dispatch;
    let isEndDisable=false;
    if (type === "service") {
      let selectedService = self.props.hairdresserNewBookingService;
      selectedService.splice(index, 1);
      let updatedProductList = self.state.bookingSelectedItems?.filter(
        (productItem) => productItem?.id !== selectedItemId
      );

      dispatch(bookingActions?.hairdresserNewBookingData(updatedProductList));
      self.setState({
        durationError:'',
        bookingSelectedItems: updatedProductList,
      });
    }
  };


  setSelectedService() {
    let self = this;
    let dispatch = self.props.dispatch;
    let selectedServiceData = self.props?.selectedService;
    let totalTime =0;
    let totalAmount=0;
    let servicesBooked = [];
    let isEndDisable=false;
    if (selectedServiceData?._id !== undefined) {
      let updatedList;
        if(self.props?.hairdresserNewBookingData.length > 1) {
            updatedList=self.props?.hairdresserNewBookingData.map((item) => {
                totalTime += isNaN(item.serviceTime) ? selectedServiceData.serviceTime : item.serviceTime;
                totalAmount += isNaN(item.servicePrice) ? parseFloat(selectedServiceData?.servicePrice) : parseFloat(item.servicePrice);
                console.log('this.statettt',totalAmount)
          
                if (item?.indexId === selectedServiceData.indexId) {
                    item = {
                        ...item,
                        ...selectedServiceData,
                        id: selectedServiceData?.serviceId,
                    };
                }
                if(item.processingTime&&item.processingTime!==0){
                    if(isEndDisable===false) {
                        isEndDisable = true
                    }
                }
                servicesBooked.push(item)
                return item;
            })
        }else {
            selectedServiceData.id=1;
            selectedServiceData.indexId=1;
            selectedServiceData.type="service"
            updatedList=[selectedServiceData];
            totalAmount=parseFloat(selectedServiceData?.servicePrice);
            totalTime=selectedServiceData?.serviceTime;
        }
        
      self.setState({
        totalAmount,
        totalTime,
        bookingSelectedItems: updatedList,
        selectedService:servicesBooked,
        selectedServiceStatus:0,
          isEndDisable:isEndDisable
      },()=>{
        self.selectEndTime(self.state.selectSlot,totalTime,'duration');
        dispatch(hairdresserActions.selectedService({}));
        dispatch(bookingActions?.hairdresserNewBookingData(updatedList));
      });

    }else{
      let servicesBooked = [];

      let serviceData =self.props?.hairdresserNewBookingData.length > 0?self.props?.hairdresserNewBookingData:self.state.bookingSelectedItems;
        let updatedList;
        if(serviceData.length > 0) {
             updatedList = serviceData.map((item) => {
                totalTime += isNaN(item.serviceTime) ? selectedServiceData.serviceTime : item.serviceTime;
                totalAmount += isNaN(item.servicePrice) ? parseFloat(selectedServiceData?.servicePrice) : parseFloat(item.servicePrice);
                if (item?.indexId === selectedServiceData.indexId) {
                    item = {
                        ...item,
                        ...selectedServiceData,
                        id: selectedServiceData?.serviceId,
                    };
                }
                 if(item.processingTime&&item.processingTime!==0){
                     if(isEndDisable===false) {
                         isEndDisable = true
                     }
                 }
                servicesBooked.push(item)
                return item;
            });
        }else{
            updatedList=[{
                id: 1,
                indexId: 1,
                type: "service",
            }]
        }

        self.setState({
          totalAmount,
          totalTime,
          bookingSelectedItems: updatedList,
          selectedService:servicesBooked,
          selectedServiceStatus:0,
            isEndDisable:isEndDisable
        },()=>{
          self.selectEndTime(self.state.selectSlot,totalTime,'duration');
          dispatch(hairdresserActions.selectedService({}));
          dispatch(bookingActions?.hairdresserNewBookingData(updatedList));
        });

    }
  }

  resetBooking() {
    let self = this;
    let client = siteSetting.noClientDAta;
    self.props.dispatch(
      bookingActions?.hairdresserNewBookingClient(client)
    );
    self.props.dispatch(productAction.selectedClient(client));
    self.props.dispatch(hairdresserActions.selectedService({}));
    self.props.dispatch(bookingActions?.hairdresserNewBookingData([]));
    this.setState({
        bookingDate: this.props.location.state&&this.props.location.state.bookingDate?new Date(this.props.location.state.bookingDate):new Date(),
        selectSlot: '',
        showOptionsModal: false,
        isSelected: false,
        showTipModal: false,
        latitude: siteSetting.default_latitude,
        longitude: siteSetting.default_longitude,
        hairdresserName: 'Select Staff Member',
        selectedService: [],
        finalBookingSlots: [],
        includeTimes: [],
        slotInfo: {},
        totalAmount: 0,
        totalTime: 0,
        hairdresserId: this.props.match.params.hairdresserId?this.props.match.params.hairdresserId:'',
        selectedClientId: '',
        selectedClient: siteSetting.noClientDAta,
        isSaveBooking: 0,
        bookingSlots: [],
        paymentType: '',
        isBookingAllowed: false,
        searchKey: '',
        selectTab:1,
        pendingAmount:0,
        transaction:[],
        paymentAmount:0,
        totalPaymentAmount:0,
        isInvoice:0,
        eventError:'',
        startTimeError:'',
        serviceError:'',
        bookingError:'',
        eventStartTime:0,
        eventEndTime:0,
        eventTitle:'',
        eventTitleError:'',
        hairdresserIdEventError:'',
        currencySymbol:"£",
        isSelectClient:false,
        mobileSelectedStep:0,
        selectedPaymentType:2,
        selectedAmount:0,
        checkOutAmountType:true,
        selectedFixedAmount:0,
        isPaymentComplete:0,
    })
}

  navigateTabs = (navName) =>{
  let self = this;
  let currentPageUrl = self.props.history.location.pathname;
  const { state } = self.props.location;
  const stateObj = { ...state,pageLoadingStatus: 1, previousPageUrl:currentPageUrl};
  const url =  `/${navName}/` + self.props.match.params.salonId;
 
   self.props.history.push({
    pathname: url,
    state: stateObj,
  });
}


changeItemPrice(item,price){
   console.log('change item price',item,price);
}

componentWillUnmount() {
  this.props.dispatch(productAction?.selectedCheckoutList({}));
}

onChangeProcessingTime = (data, selectedItemId) => {
  let self = this;
  let dispatch = self.props?.dispatch;
    let updatedProductList = self.state.bookingSelectedItems?.map(
      (productItem) =>{
        if(productItem?.id === selectedItemId){
          productItem.processingTime = data;
        }
        return productItem;
      } 
    );
    self.setState({
      durationError:'',
      bookingSelectedItems: updatedProductList,
    });
  
};

  showCheckout=()=>{
      // console.log("########.showCheckout",this.props?.user?.userInfo?._id===this.state.hairdresserId,this.props?.user?.userInfo?._id,this.state.hairdresserId);
      if((this.state.totalAmount>0) || (this?.state?.totalAmount==0 && this.state?.bookingSelectedItems[0]?.serviceId!==undefined)){
          // return true;
          if ((this.props?.user?.userInfo?.hairdresser?.isSalonOwner === 1)||(this.props?.user?.userInfo?._id===this.state?.selectedStaff?._id)) {
              return true;
          }else{
              return false;
          }
      }else{
          return false;
      }
  }

  render() {
      {console.log('this.state',this.state)}
      {console.log('this.propsrad',this.props)}
    return (
      <div className="h_n">
        {/* <div className="body_container cm_bg_white"> */}
        {console.log('check the selected service',this.state?.bookingSelectedItems)}
        <div className="body_container body_bg_lightGray">
          <TopHeader 
            title="New Appointment" 
            cancelButton={this.state.cancelButton} 
            saveBookingButton={(this.state.totalAmount>0 || (this?.state?.totalAmount==0 && this.state?.bookingSelectedItems[0]?.serviceId!==undefined)) ?true:false}
            checkoutButton={this.showCheckout()}
            headerButtonStatus={true}
            checkoutBooking={this.checkoutBooking} 
            saveBooking={this.saveBooking}  
            history={this?.props?.history}
            previousPageUrl={'/diary/'+this.props.match.params.salonId}
            isbookingSubmit={this.state.isbookingSubmit}
            {...this.props}
             />
          <SideNav {...this.props} />
          <nav className="nav nav-tabs _newCheckout_wrapper navbar__space">
            <span
              className="nav-item nav-link active"
              onClick={()=>this.navigateTabs('newBooking')}
            >
              Booking
            </span>
            <span
              className="nav-item nav-link"
              onClick={()=>this.navigateTabs('event')}
            >
              Event
            </span>
            {/* <span
              className="nav-item nav-link"
              to={`/new-checkout/${this.props.match.params.salonId}`}
              onClick={()=>this.navigateTabs('new-checkout')}
            >
              Checkout
            </span> */}
          </nav>
          <div className="service_flex_div service_flex_div service_flex__space">
            <div className="main_service_div">
              {this.state.bookingSelectedItems.length > 0
                ? this.state.bookingSelectedItems.map((service, i) => (
                    <div className="row" key={"service_" + i}>
                      <ServiceCheckout
                        index={i + 1}
                        key={i}
                        removeService={this.removeServiceItem}
                        bookingDate={this.state.bookingDate}
                        //this.props.hairdresserNewBookingData?.bookingDate
                        selectSlotFunc={this.selectTimeSlot}
                        selectEndTimeSlot={this.state.selectEndTimeSlot}
                        selectEndTime={this.selectEndTime}
                        includeEndTimes={this.state.includeEndTimes}
                        changeServiceDuration={this.changeServiceDuration}
                        includeTimes={this.state.includeTimes}
                        startTimeError={this.state.startTimeError}
                        calculateEndTime={this.calculateEndTime}
                        totalTime={this.state.totalTime}
                        durationError={this.state.durationError}
                        updateDuration={this.updateDuration}
                        selectedTime={this.state.selectSlot}
                        slotInfo={this.state.slotInfo}
                        staffList={this.state.staffList}
                        item={service}
                        propData={this.props}
                        selectedStaff={this.state.selectedStaff}
                        selectedClient={this.state.selectedClient}
                        changeHairdresser={this.changeHairdresser}
                        selectDay={this.selectDay}
                        resetBooking={this.resetBooking}
                        clientStatus={true}
                        changeItemPrice={this.changeItemPrice}
                        onChangeProcessingTime={this.onChangeProcessingTime}
                        finalBookingSlots={this.state.finalBookingSlots}
                        bookingSelectedItems={this.state.bookingSelectedItems}
                        serviceSelectionError={this.state.serviceSelectionError}
                        isEndDisable={this.state.isEndDisable}
                        hairdresserError={this.state.hairdresserError}
                      />
                    </div>
                  ))
                : null}

               <div className="row">
                <div className="col-sm-12 pl-0">
                  <button
                    type="button"
                    className="btn btn-light b-s-1 mt-4"
                    onClick={this.addServiceItem}
                  >
                    Add Service
                  </button>
                </div>
              </div>
            </div>
            {(this.state.bookingSelectedItems[0]?._id !== undefined && this?.state?.hairdresserId!=='')?
            <div className="checkout_page">
             <ClientCheckout  
               borderr="borderemove"
               currencySymbol={this.state.currencySymbol}
               hairdresserId={this?.state?.hairdresserId}
               pagetype="newbooking"
               checkoutBooking={this.checkoutBooking}
               checkoutButton={this.showCheckout()}
               selectedClientId={this?.state?.selectedClient?._id}
               selectedClient={this?.state?.selectedClient} 
               saveBooking={this.saveBooking}  
               />
            </div>
              :null}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(NewBooking);
