import { newServicesConstants } from "../_constants";

const initialState = {}

export function newServices(state = initialState, action) {
    // console.log("under reducer page", state)
    let response;
    switch (action.type) {
        case newServicesConstants.CREATE_CATEGORY_SUCCESS:
            response = action.createCategorySuccess
            // console.log("response reducer  : ", response)
            return {...state, response}

        case newServicesConstants.CREATE_CATEGORY_ERROR:
            return {}

        case newServicesConstants.EDIT_CATEGORY_SUCCESS:
            response = action.editCategorySuccess
            // console.log("response edit reducer  : ", response)
            return {...state, response}

        case newServicesConstants.EDIT_CATEGORY_ERROR:
            return {}

        case newServicesConstants.DELETE_CATEGORY_SUCCESS:
            response = action.deleteCategorySuccess
            // console.log("response reducer  : ", response)
            return {...state, response}

        case newServicesConstants.DELETE_CATEGORY_ERROR:
            return {}

        case newServicesConstants.LIST_CATEGORY_SUCCESS:
            response = action.listCategorySuccess
            // console.log("response list category  reducer: ", response)
            return {...state, listCategory : response?.listCategory}

        case newServicesConstants.LIST_CATEGORY_ERROR:
            return {}
        
        case newServicesConstants.LIST_SERVICES_SUCCESS:
            response = action.listServicesSuccess
            // console.log("response list services reducer  : ", response)
            return {...state, listServices : response?.listServices,shareService:response?.shareService}

        case newServicesConstants.LIST_SERVICES_ERROR:
            return {}

        case newServicesConstants.LIST_HAIRDRESSER_SUCCESS:
            response = action.listHairdresserSuccess
            // console.log("response list Hairdresser reducer  : ", response)
            return {...state, listHairdresser : response?.listHairdresser}

        case newServicesConstants.LIST_HAIRDRESSER_ERROR:
            return {}
            
        case newServicesConstants.CREATE_SERVICES_SUCCESS:
            response = action.createServicesRequest
            // console.log("response reducer  : ", response)
            return {...state, response}

        case newServicesConstants.CREATE_SERVICES_ERROR:
            return {}
        
        case newServicesConstants.VIEW_SERVICE_SUCCESS:
            response = action.viewServiceRequest
            // console.log("response reducer  : ", response)
            return {...state, response}

        case newServicesConstants.VIEW_SERVICE_ERROR:
            return {}

        case newServicesConstants.CATEGORY_ORDERCHANGE_SUCCESS:
            response = action.categoryOrderChange
            // console.log("response reducer  : ", response)
            return {...state, response}

        case newServicesConstants.CATEGORY_ORDERCHANGE_ERROR:
            return {}

        case newServicesConstants.SERVICE_ORDERCHANGE_SUCCESS:
            response = action.serviceOrderChange
            // console.log("response reducer  : ", response)
            return {...state, response}

        case newServicesConstants.SERVICE_ORDERCHANGE_ERROR:
            return {}

        default:
            return state;
    }
}

// Make sure we're exporting the reducer function correctly
export default newServices;
