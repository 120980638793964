import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as d3 from "d3";
// import { Modal } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SideNav from '../template/SideNav';
import TopHeader from '../template/TopHeader';
import { Link } from 'react-router-dom';
import CalendarCard from './CalendarCard';
import { DragIcon, ExpandICon, Plus } from '../SvgIcon';
import {  bookingActions, hairdresserActions, productAction} from '../../_actions';
import siteSetting from "../../config/env";
import moment from 'moment';
import promise from 'bluebird'
import { default as ReactSelect } from "react-select";
// import {hairdresserConstants} from "../../_constants";
// import Steps from '../hairdresserAuth/Steps';
// import GoThroughModalAfterLogin from '../hairdresserAuth/GoThroughModalAfterLogin';
// import Login from '../hairdresserAuth/Login';
// import DailySchedule from 'reactjs-schedule-calendar'
import 'reactjs-schedule-calendar/dist/index.css'
// import { hairdresserConstants } from "../../_constants";
import CalenderModule from "./CalenderModule";
import Lottie from 'react-lottie';
import animationData from '../../assets/animation-data/loader.json';
import { components } from "react-select";
import './fullCalender.css';
import ReactDatePicker from 'react-datepicker';
import { Modal } from 'react-bootstrap';
import toast from "react-toast-notification";


const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
          
        </components.Option>
      </div>
    );
  };

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const removeMenuClass = () => {
    document.body.classList.remove('nav_menu_open')
}

const exclude = [
    //{ start: 0, end: 480 },
    // { start: 1080, end: 1440 },
    //{ start: 1320, end: 1440 }
]
var _ = require('lodash');
let userIdHolder = [];
const newCalendar = [];
let timeSlot = [];
for (let i = 480; i <= 24 * 60; i += 15) {
    timeSlot.push(i)
}
const colors = ['#D6D5EC', '#C8EEEE', '#D6D5EC'];

function getslotTime(time) {
    let hr = parseInt(time / 60);
    let min = time - (hr * 60);
    //console.log("time",time,hr,min);
    //return hr + ':' + (min == 0 ? '00' : min);
    return (hr <=9 ? '0'+hr : hr)+ ':' + (min == 0 ? '00' : (min <=9 ? '0'+min : min));
}

function getServiceName(item) {
    let serviceName = "";
    if (item.list[0].info?.servicesBooked) {
        item.list[0].info.servicesBooked.map((service) => {
            serviceName = serviceName + (serviceName == "" ? "" : ", ");
            serviceName = serviceName + service?.service?.serviceName;
        })
    }
    return serviceName;
}

function getslotTimeSec(time) {
    let hr = parseInt(time / 60);
    let min = time - (hr * 60);
    //console.log("time",time,hr,min);
    return (hr <= 9 ? '0' + hr : hr) + ':' + (min == 0 ? '00' : (min <= 9 ? '0' + min : min)) + ":00";
}

var clientWidth = 0;

class NewDiary extends Component {

    constructor(props) {
        super(props);
        // console.log("radha props",props)
        this.state = {
            scrollTopPos: 0,
            tempArray: [],
            bookingDate: this.props.location.state && this.props.location.state.bookingDate ? moment(this.props.location.state.bookingDate).format('YYYY-MM-DD') : localStorage.getItem('cardBookingDate')!=null?moment(localStorage.getItem('cardBookingDate')).format('YYYY-MM-DD'):moment().format('YYYY-MM-DD'),
            latitude: siteSetting.default_latitude,
            longitude: siteSetting.default_longitude,
            hairdresserList: [],
            optionList:[
                { id: 'item-1', content: 'Item 1' },
                { id: 'item-2', content: 'Item 2' },
                { id: 'item-3', content: 'Item 3' },
              ],
            timeSlotbar: 0,
            timebarStatus: false,
            setbarTime: 0,
            timeBarWidth: 0,
            showTimebar: false,
            initial_events: null,
            initial_events_status: false,
            calenderStartTime: 1440,
            calenderEndTime: 0,
            scrollTimelineStatus: false,
            allStaffStatus:true,
            workingStaffStatus:false,
            selectedFilterName:this.props?.dairyFilterData?.length==1 ? props?.dairyFilterData[0]?.name:'all_staff',
            selectedHairdressers: [],
            selected_filter_text:'All Staff',
            scrollLeft: 0,
            staffList:this.props?.dairyStaffOrderList?.length>0?this.props?.dairyStaffOrderList:[],
            myData: [{ label: "All Staff", value: "0" }],
            openDragFilterPopup:false,
            filterHideShow:false,
            staffStatus:'Staff not selected',
            isLoading:true,
            isFilterChange:false,
            isDefaultStaff:true,
            isFirstTimeLoad:1,
            hairdresserCount:0,
            dynamictableWidth:"",
            processingTimeStatus:false,
            openShiftDay: false,
            selectedStaff: null,
            selectedDate: null,
            startTime:'',
            endTime:'',
            todayStartWorking:'',
            todayEndWorking:'',
            isTodayWorking:false
        }
       


        this.getslotTime = this.getslotTime.bind(this);
        this.setCalenderDate = this.setCalenderDate.bind(this);
        this.getCalenderData = this.getCalenderData.bind(this);
        this.noLocation = this.noLocation.bind(this);
        this.foundLocation = this.foundLocation.bind(this);
        this.getHairdresserTime = this.getHairdresserTime.bind(this);
        this.getHairdresserTimeClass = this.getHairdresserTimeClass.bind(this);
        this.checkHairdresserTime = this.checkHairdresserTime.bind(this);
        this.getHairdresserTimeClassDiary = this.getHairdresserTimeClassDiary.bind(this);
        this.diary_page_ref = React.createRef();
        this.timeLinewidthRef = React.createRef();
        this.scrollFixTimeLine = this.scrollFixTimeLine.bind(this);
        this.scrollref = React.createRef();
        this.getScrollValue = this.getScrollValue.bind(this);
        this.showDragFilter = this.showDragFilter.bind(this)
        this.filterRef = React.createRef();
        this.startNewBooking = this.startNewBooking.bind(this);
        this.filterSelectedInput= this.filterSelectedInput.bind(this);
        this.dragAndDropOrderedHairdresser= this.dragAndDropOrderedHairdresser.bind(this);
        this.processingTimeBooking = this.processingTimeBooking.bind(this);
        // console.log('frfer', this.getScrollValue)
      
    }
    setContainerRef = (ref) => {
        this.containerRef = ref;
      };
    

    componentWillUpdate(prevProps) {
        let self = this;
        let i = 13;
        let { calendar, bookingUpdatesCount } = prevProps.hairdresserSchedule;
        let arrayIndex;
        while (i--) {
            newCalendar.push("");
        }

        if (calendar) {
            calendar.map(function (data, index) {
                arrayIndex = Math.ceil(data.startTime / bookingUpdatesCount)
                arrayIndex = arrayIndex % 9;
                newCalendar[arrayIndex] = data
            })
        }
        // console.log("####1", self.props && self.props.hairdresserSchedule && self.props.hairdresserSchedule.length)

        if (document.body.classList.contains('diary-temp')) {
            if (self.props && self.props.hairdresserSchedule && self.props.hairdresserSchedule.length === 1) {
                document.body.classList.add('first-column');
            }
            else if (self.props && self.props.hairdresserSchedule && self.props.hairdresserSchedule.length === 2) {
                // console.log("2nd len")
                // document.body.remove('first-column');
                document.body.classList.add('second-column');

            }
            else if (self.props && self.props.hairdresserSchedule && self.props.hairdresserSchedule.length === 3) {
                // document.body.remove('second-column');
                document.body.classList.add('hi-column');
            }

            if (self.props && self.props.hairdresserSchedule && self.props.hairdresserSchedule.length > 3) {
                if(document.getElementById('clnder-0')?.clientWidth > 0){
                    clientWidth = document.getElementById('clnder-0').clientWidth * self.state.selectedHairdressers.length;
                }
                
                // self.setState({
                //     clientWidth: document.getElementById('clnder-0').clientWidth * self.state.staffList.length,
                // })
            }
        }

    }


    // componentDidUpdate(prevProps) {
        // let self = this;
        // if (prevProps.activeFilterStatus !== self.props.activeFilterStatus) {
        //     let _status = self.props.activeFilterStatus;
        //     if(_status?.allStaffStatus){
        //         self.props.dispatch(hairdresserActions.dairyFilterData([]));
        //         self.props.dispatch(hairdresserActions.dairyFilterStaffOrderList([]));
        //         this.loadDairyData(false);
        //     }

        //   }
    //   }
      


    // setTimeBarSlot() {
    //     if (this.state.showTimebar) {
    //         setInterval(() => {
    //             let getCurrentTime = this.getBarCurrentTime();
    //             d3.select('.fc-timegrid-now-indicator-arrow').text(`${getCurrentTime.hour}:${getCurrentTime.minutes}`);
    //         }, 5000);
    //     } else {
    //         setTimeout(() => {
    //             let getCurrentTime = this.getBarCurrentTime();
    //             d3.select('.fc-timegrid-now-indicator-arrow').text(`${getCurrentTime.hour}:${getCurrentTime.minutes}`);
    //         }, 4000)
    //
    //     }
    // }


    scrollFixTimeLine(startTime, endTime) {
        this.setState({ scrollTimelineStatus: true }, () => {
            d3.selectAll(`.new--diary-clnder-body>.calendar_wrapper:nth-child(1) .fc-timegrid-slots>table>tbody>tr>td:nth-child(1)`).style('position', 'absolute');
        });
    }


    getScrollValue() {
        let self = this;
        const scrollLeft = self.scrollref.current.offsetLeft;
        this.setState({ scrollLeft: scrollLeft})
        //  if(scrollLeft>0){
        //     d3.selectAll(`.new--diary-clnder-body>.calendar_wrapper:nth-child(1) .fc-timegrid-slots>table>tbody>tr>td:nth-child(1)`).attr( 'style',`position:fixed;margin-top: ${-scrollTop}px;background-color: #fff; z-index:999` );
        //  }else{
        //     d3.selectAll(`.new--diary-clnder-body>.calendar_wrapper:nth-child(1) .fc-timegrid-slots>table>tbody>tr>td:nth-child(1)`).attr( 'style',`position:relative;margin-top:auto`);
        //  }
    }


    // getBarCurrentTime() {
    //     const d = new Date();
    //     let minutes = d.getMinutes();
    //     let hour = d.getHours();
    //     let minlen = String(minutes).length;
    //     if (minlen < 2) {
    //         minutes = `0${minutes}`;
    //         this.setState({ setbarTime: `${hour}:${minutes}` })
    //     } else {
    //         this.setState({ setbarTime: `${hour}:${minutes}` })
    //
    //     }
    //
    //     return { minutes, hour }
    // }


    hairdresserSort = (hairdresserList) => {
        // console.log("hairdresserSort hairdresserList",hairdresserList)
        let self = this;
        let newList = [];
        let notWorkingList = [];
        return new promise(function (resolve, reject) {

            _.map(hairdresserList, function (schedule) {
                // console.log("======",schedule);
                if (schedule.rotaInfo.rota.schedule) {
                    let selected = _.find(schedule.rotaInfo.rota.schedule[0].profiles, function (o) {
                        return o.day === moment(self.state.bookingDate).format('ddd').toLowerCase();
                    })
                    if (selected) {
                        newList.push(schedule)
                    } else {
                        notWorkingList.push(schedule)
                    }
                    //console.log("if",schedule);
                } else {
                    notWorkingList.push(schedule);
                    //console.log("else",schedule);
                }
            })
            // let staffData = newList.concat(notWorkingList);
            let staffData = self.state.isDefaultStaff ? hairdresserList : hairdresserList  ;
            // console.log("staffData====",staffData);
            // console.log("staffData",staffData,newList,notWorkingList,hairdresserList);
            self.props.dispatch({
                type: 'HAIRDRESSER_STAFF_ROTA_SUCCESS',
                hairdresserStaffRotaSuccess: { rota: staffData }
            });
            resolve(staffData);
        })
    }


componentDidMount() {
    var self = this;
    const activeFilterStatus = self.props?.activeFilterStatus;
    const cardBookingDate = localStorage.getItem('cardBookingDate');
   
    if(self?.props?.location?.state?.bookingDate!==undefined || cardBookingDate!=null){
     const checkDate = (self?.props?.location?.state?.bookingDate==undefined&&cardBookingDate!=null)?cardBookingDate:(self?.props?.location?.state?.bookingDate!=undefined)?self?.props?.location?.state?.bookingDate:cardBookingDate;
    //  console.log('booking Date', checkDate)
     this.setCalenderDate(checkDate);
     if(self?.props?.location?.state?.isFirstTimeLoad!==undefined ){
        self.setState({
           isFirstTimeLoad:self?.props?.location?.state?.isFirstTimeLoad,
        })
       }else{
        self.setState({
            isFirstTimeLoad:0,
         })
       }
    }


     self.props.dispatch(bookingActions?.selectHairdresserBookingDate({}));
     self.props.dispatch(productAction?.selectedCheckoutList({}));

    if (localStorage.getItem('accessToken')) {
        //this.setTimeBarSlot();
        self.props.dispatch(bookingActions?.hairdresserNewBookingData([]));
        self.setState({ showTimebar: true, });
        // setTimeout(() => {
        //     self.setTimeBarSlot();
        // }, 60000)

        if(((!activeFilterStatus?.allStaffStatus && !activeFilterStatus?.workingStaffStatus) && activeFilterStatus?.selectedHairdressers?.length>0)){
            self.props.dispatch(hairdresserActions.getHairdresserScheduleReset());
            self.setState({
              selectedHairdressers:activeFilterStatus?.selectedHairdressers,
              allStaffStatus:false
            },()=>{
                 self.filterSelectedInput(activeFilterStatus?.selectedFilterName);
                 self.changeCheckBoxStatus('',false);

                _.forEach(activeFilterStatus?.selectedHairdressers,(selectedHairdressers)=>{
                    self.changeCheckBoxStatus(selectedHairdressers?._id,true)
                })
                self.dragAndDropOrderedHairdresser();
                // self.createCalendarSlots(activeFilterStatus?.selectedHairdressers);
            })


        }else{
            if((!activeFilterStatus?.allStaffStatus && !activeFilterStatus?.workingStaffStatus)){
                // console.log("test--->1");
                this.loadDairyData(false);
            }else{
                if(activeFilterStatus?.allStaffStatus){
                    // console.log("test--->2");
                    this.loadDairyData(false);
                }else{
                    if(activeFilterStatus?.workingStaffStatus){
                        // console.log("test--->3");
                        this.loadDairyData(true);
                    }
                }
            }

        }

    } else {
        this.props.history.push('/')
    }
    window.addEventListener('scroll', self.handleScroll);
    document.body.classList.add('diary-temp');
    let daryCl= document.getElementById('dairy-scroll-positon');
    let dartime= document.getElementById('timeIdPostiotn');
    let dt = window.scrollX + document.getElementById('dairy-scroll-positon').getBoundingClientRect().left;
    document.addEventListener("mousedown", this.handleClickOutsideFilter);
}



loadDairyData(reduxStatus=true){
        let self = this;
        let hairdresserId = localStorage.getItem('userId');
        let workingStaff = [];
        let params = {
            searchKey: this.state.searchKey,
            'hairdresserId': hairdresserId,
            page: 1,
            listType: 1,
            isGetRota:1,
            diary:1,
            workingDate: moment(self.state.bookingDate).format('YYYY-MM-DD')
        };
        self.props.dispatch(hairdresserActions.getHairdresserScheduleReset())
        if(self.props.dairyFilterData.length>0&&reduxStatus){
            self.props.dispatch(hairdresserActions.hairdresserStaffRota(params))
            .then(function (response) {
                _.map(response.data.data.rota, function (schedule) {
                    if (schedule.rotaInfo.rota.schedule) {
                        let selected = _.find(schedule.rotaInfo.rota.schedule[0].profiles, function (o) {
                            return o.day === moment(self.state.bookingDate).format('ddd').toLowerCase();
                        })
                        if (selected) {
                            workingStaff.push(schedule)
                        }
                    }
                });

                let _hairdresser_data = workingStaff;
                let _filterState = {
                    allStaffStatus :self.props.activeFilterStatus?.allStaffStatus?self.props.activeFilterStatus?.allStaffStatus:false,
                    workingStaffStatus:self.props.activeFilterStatus?.workingStaffStatus?self.props.activeFilterStatus?.workingStaffStatus:false,
                    selectedFilterName:self.props.activeFilterStatus?.selectedFilterName?self.props.activeFilterStatus?.selectedFilterName:true,
                 }

                 let orderForRota = self.compareAndRetainSameOrder(self.props.dairyFilterData,self.props?.staffRota?.rota);
                
                 self.filterSelectedInput('working_staff');
                 self.setState({
                    ..._filterState,
                    staffList: orderForRota,
                    selectedHairdressers : _hairdresser_data ,
                    isLoading:false,
                });

                _hairdresser_data.forEach(item=>{
                    self.changeCheckBoxStatus(item?._id,true)
                })
                 self.createCalendarSlots(_hairdresser_data);
            });

        }else{
            self.props.dispatch(hairdresserActions.hairdresserStaffRota(params))
            .then(function (response) {
                self.changeCheckBoxStatus('',true);
              let _hairdresserList = response.data.data.rota?.map(item=>{
                   item.checkStatus = true;
                   return item;
                })

                let reorderStaff = self.compareAndRetainSameOrder(self.props.dairyFilterData,_hairdresserList);

                // console.log('_hairdresserList',reorderStaff);               
                let _filterState = {
                    allStaffStatus: true,
                    workingStaffStatus:false,
                 }

                 self.setState({
                    ..._filterState,
                    staffList: self.props?.staffRota?.rota,
                    selectedHairdressers:[],
                });
                self.setState({
                        staffList: reorderStaff,
                        selectedHairdressers:reorderStaff,
                    });
                self.props.dispatch(hairdresserActions.dairyFilterStaffOrderList(reorderStaff));
                self.createCalendarSlots(reorderStaff);
            })
        }

}


compareAndRetainSameOrder(arr1, arr2) {
    let result = [];
    _.forEach(arr1,(staffData)=>{
      let checkData =  arr2.find(_workingStaff=>_workingStaff?._id===staffData?._id);
      if(checkData!==undefined){
        result.push(checkData);
      }
    })
    let workingStaffResult = [...result,...arr2];
    let uniqueArray = Array.from(new Set(workingStaffResult));
    return uniqueArray; 
}

createCalendarSlots(hairdresserData){
    let self = this;
    //console.log("createCalendarSlots",hairdresserData);
    self.hairdresserSort(hairdresserData)
            .then(function (hairRes) {
                console.log("hairRes.length",hairRes.length);
                let Promisekey = [];
                let startTime = 1440;
                let endTime = 0;
                self.setState({
                    hairdresserCount:hairRes.length
                },function (){
                hairRes.map((hairdresser, i) => {
                    // response.data.data.rota.map((hairdresser, i) => {
                    // Promisekey.push(self.getHairdresserScheduleList(hairdresser._id,hairdresser,i))
                    self.getHairdresserScheduleList(hairdresser._id, hairdresser, i);
                    if (hairdresser.rotaInfo.rota.schedule) {
                        //console.log("hairdresser.rotaInfo.rota",hairdresser.rotaInfo.rota.schedule[0],moment().format('ddd').toLowerCase());
                        let selected = _.find(hairdresser.rotaInfo.rota.schedule[0].profiles, function (o) {
                            return o.day === moment(self.state.bookingDate).format('ddd').toLowerCase();
                        })

                        if (selected) {
                            //console.log("selected.startTime<startTime",selected.startTime,startTime)
                            if (selected.startTime < startTime) {
                                startTime = selected.startTime;
                            }
                            if (selected.endTime > endTime) {
                                endTime = selected.endTime;
                            }
                        }
                        //console.log("selected",selected);
                    }
                })
            })

                self.setState({
                    calenderStartTime: startTime,
                    calenderEndTime: endTime
                })
                let hr = parseInt(startTime / 60);
                //console.log("######",startTime,hr);
                timeSlot = [];
                for (let i = hr * 60; i <= endTime; i += 15) {
                    timeSlot.push(i)
                }
                exclude.push({ start: 0, end: hr * 60 })
            })
   }


    foundLocation(location) {
        let self = this;
        this.setState({
            currentLocation: location.coords,
            latitude: location.coords.latitude ? location.coords.latitude : siteSetting.default_latitude,
            longitude: location.coords.longitude ? location.coords.longitude : siteSetting.default_longitude,
        }, function () {
            self.getCalenderData();
        })
    }

    noLocation() {
        this.getCalenderData();
    }

    getCalenderData() {
        let { dispatch } = this.props;
        let self = this;
        let params = {}
        let salonId = this.props?.match?.params?.salonId;
        let hairdresserId = this.props?.match?.params?.hairdresserId;

        if (salonId) {
            params.salonId = salonId;
            params.latitude = this.state.latitude;
            params.longitude = this.state.longitude;
            self.props.dispatch(hairdresserActions.getSalonProfile(params))
                .then(function (response) {
                    if (!response.data.data.salons) {
                        self.props.history.push("/404");
                    }
                    let displayData = [];
                    let salon = { salonInfo: response.data.data };
                    //console.log("salon",salon);
                    if (salon.salonInfo) {
                        _.uniqBy(salon?.salonInfo?.salons?.rota, function (e) {
                            _.uniqBy(salon?.salonInfo?.salons?.rota, '_id');

                            userIdHolder.push(e.userId);
                        });
                        let uniq = [...new Set(userIdHolder)];

                        _.forEach(salon?.salonInfo?.salons?.hairdresser, function (value, index) {
                            if (uniq.indexOf(value._id) > -1) {
                                displayData.push(salon?.salonInfo?.salons?.hairdresser[index]);
                            }
                        })
                        self.props.dispatch(hairdresserActions.getHairdresserScheduleReset());
                        // console.log("displayData.length",displayData.length);
                        self.setState({
                            hairdresserCount:displayData.length
                        },function (){
                            displayData.map((hairdresser) => {
                                self.getHairdresserScheduleList(hairdresser._id, hairdresser);
                            })
                        })
                    }

                    if (response.data.error) {
                        self.setState({
                            message: response.data.error.message
                        })
                    } else {
                    }
                });
        } else {
            this.props.history.push("/404");
        }
    }

    getslotTime(time) {
        let hr = parseInt(time / 60);
        let min = time - (hr * 60)
        //return hr + ':' + (min == 0 ? '00' : min);
        return (hr <=9 ? '0'+hr : hr)+ ':' + (min == 0 ? '00' : (min <=9 ? '0'+min : min));
    }

    getServiceName(item) {
        let serviceName = "";
        if (item.list[0].info.servicesBooked) {
            item.list[0].info.servicesBooked.map((service) => {
                serviceName = serviceName + (serviceName == "" ? "" : ", ");
                serviceName = serviceName + service.service.serviceName;
            })
        }
        return serviceName;
    }

    getHairdresserTime(schedule) {
        let self = this;
        //console.log("schedule",schedule);
        if (schedule.hairdresser.rotaInfo.rota.schedule) {
            //console.log("hairdresser.rotaInfo.rota",hairdresser.rotaInfo.rota.schedule[0],moment().format('ddd').toLowerCase());
            let selected = _.find(schedule.hairdresser.rotaInfo.rota.schedule[0].profiles, function (o) {
                return o.day === moment(self.state.bookingDate).format('ddd').toLowerCase();
            })
            if (selected) {
                return this.getslotTime(selected.startTime) + '-' + this.getslotTime(selected.endTime);
            } else {
                return <span className="text_red">Not Working Today</span>
            }

        } else {
            return <span className="text_red">Not Working Today</span>
        }

    }

    getHairdresserTimeClassDiary(schedule, data) {
        let self = this;

        if (schedule.hairdresser.rotaInfo.rota.schedule) {
            //console.log("hairdresser.rotaInfo.rota",hairdresser.rotaInfo.rota.schedule[0],moment().format('ddd').toLowerCase());
            let selected = _.find(schedule?.hairdresser?.rotaInfo?.rota.schedule[0].profiles, function (o) {
                return o.day === moment(self.state.bookingDate).format('ddd').toLowerCase();
            })
            //console.log("schedule==",schedule,selected,schedule.hairdresser.name,data);
            if (selected) {
                //return true;
                if (selected.startTime <= data.start && selected.endTime >= data.end) {
                    return 'aaaaa';
                } else {
                    return 'cm_dark_b';
                }
            } else {
                return 'cm_dark_b';
            }

        } else {
            return 'cm_dark_b';
        }

    }

    getHairdresserTimeClass(schedule) {
        let self = this;

        if (schedule.hairdresser.rotaInfo.rota.schedule) {
            //console.log("hairdresser.rotaInfo.rota",hairdresser.rotaInfo.rota.schedule[0],moment().format('ddd').toLowerCase());
            let selected = _.find(schedule?.hairdresser?.rotaInfo?.rota?.schedule[0].profiles, function (o) {
                return o.day === moment(self.state.bookingDate).format('ddd').toLowerCase();
            })
            //console.log("schedule",schedule,i,selected,schedule.hairdresser.name);
            if (selected) {
                return true;
            } else {
                return 'cm_dark_b';
            }

        } else {
            return 'cm_dark_b';
        }

    }

    checkHairdresserTime(schedule, data) {
        let self = this;

        if (schedule.hairdresser.rotaInfo.rota.schedule) {
            //console.log("hairdresser.rotaInfo.rota",hairdresser.rotaInfo.rota.schedule[0],moment().format('ddd').toLowerCase());
            let selected = _.find(schedule?.hairdresser?.rotaInfo?.rota?.schedule[0].profiles, function (o) {
                return o.day === moment(self.state.bookingDate).format('ddd').toLowerCase();
            })
            //console.log("schedule",schedule,selected,schedule.hairdresser.name,data);
            let currentTime = parseInt(moment().format("HH") * 60) + parseInt(moment().format("mm"));
            //console.log("currentTime",currentTime,self.state.bookingDate,moment().format("YYYY-MM-DD"),self.state.bookingDate===moment().format("YYYY-MM-DD"))
            if (selected) {
                // return true;
                if (selected.startTime <= data.start && selected.endTime >= data.end) {
                    //return true;
                    if (self.state.bookingDate === moment().format("YYYY-MM-DD")) {
                        if (currentTime <= data.end) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (self.state.bookingDate <= moment().format("YYYY-MM-DD")) {
                        return false;
                    } else {
                        return true;
                    }

                } else {
                    return false;
                }
            } else {
                return false;
            }

        } else {
            return false;
        }
     
    }


setCalenderDate(bookingDate) {
        let self = this;
         const _bookingDate = moment(bookingDate).format('YYYY-MM-DD');
         localStorage.setItem('cardBookingDate',_bookingDate);
        this.setState({ bookingDate: _bookingDate }, function () {
            // this.setTimeBarSlot();
            self.props.dispatch(hairdresserActions.getHairdresserScheduleReset());
            if(self?.props?.activeFilterStatus?.allStaffStatus==false && self?.props?.activeFilterStatus?.workingStaffStatus){
                self.filterSelectedInput('working_staff');
                // console.log('cardBookingDate 1', _bookingDate,self?.props?.activeFilterStatus?.selectedHairdressers);
                self.loadDairyData(true);
            }else{
                if(((self?.props?.activeFilterStatus?.allStaffStatus==false && self?.props?.activeFilterStatus?.workingStaffStatus==false) && self?.props?.activeFilterStatus?.selectedHairdressers?.length>0)){
                    console.log("#######if");
                    _.forEach(self?.props?.activeFilterStatus?.selectedHairdressers,(__hairdressers)=>{
                    self.changeCheckBoxStatus(__hairdressers?._id,true)
                   })
                   self.dragAndDropOrderedHairdresser();
                //    console.log('cardBookingDate 2', _bookingDate,self?.props?.activeFilterStatus?.selectedHairdressers);
                }else{
                    console.log("######else");
                    _.forEach(self.props.dairyFilterData,(__hairdressers)=>{
                        self.changeCheckBoxStatus(__hairdressers?._id,true)
                       })


                    self.loadDairyData(false);
                    // self.dragAndDropOrderedHairdresser();
                }
            }
        })
    }






    getHairdresserScheduleList = (hairdresserId, hairdresser, i) => {
        let self = this;
        let params = `${hairdresserId}/calendar?calendarDate=${this.state.bookingDate}&deviceToken=fedfvcfdvfdvfdvfgvfgv`;
        return new promise(function (resolve, reject) {
            self.props.dispatch(hairdresserActions.getHairdresserSchedule(params, hairdresser, i))
                .then(function (result) {
                    resolve(result.data.data);
                })
        })
    }

    getEvent = (schedule) => {
        let self = this;
        let appointmentList = [];
        if (schedule?.calendar?.calendar?.length > 0) {
            schedule.calendar.calendar.map((item, i) => {
                // let todayStr = new Date().toISOString().replace(/T.*$/, '');
                let todayStr = moment().format('YYYY-MM-DD');
                if (item.list[0].type === "booking") {
    //data.calendar[3].list[0].servicesBookeddata.calendar[3].list[0].servicesBooked
                    let data = {
                        id: i,
                        title: getServiceName(item),
                        start: todayStr + 'T' + getslotTimeSec(item.startTime),
                        end: todayStr + 'T' + getslotTimeSec(item.endTime),
                        eventId: item.list[0].info._id,
                        services:item.list[0].servicesBooked,
                        type: 'booking',
                        info: item
                    }
                    // if(item.list[0].info.isServiceProcessingTime===1){
                    //     data.editable=false;
                    // }
                    appointmentList.push(data);
                }
                if (item.list[0].type === "event") {
                    let data = {
                        id: i,
                        title: item.list[0].info.title,
                        start: todayStr + 'T' + getslotTimeSec(item.startTime),
                        end: todayStr + 'T' + getslotTimeSec(item.endTime),
                        eventId: item.list[0].info._id,
                        type: 'event',
                        info: item
                    }
                    appointmentList.push(data);
                }

            })
        }
        if (schedule?.calendar?.todayRota?.breakTimes && schedule?.calendar?.todayRota?.breakTimes.length > 0) {
            schedule.calendar.todayRota.breakTimes.map((item, i) => {
                // let todayStr = new Date().toISOString().replace(/T.*$/, '');
                let todayStr = moment().format('YYYY-MM-DD');
                let data = {
                    id: i,
                    title: 'Break',
                    start: todayStr + 'T' + getslotTimeSec(item.startTime),
                    end: todayStr + 'T' + getslotTimeSec(item.endTime),
                    //eventId:item.list[0].info._id,
                    type: 'break',
                    info: item
                }
                appointmentList.push(data);
            })
        }
        let todayStr = moment().format('YYYY-MM-DD');
        if (schedule?.hairdresser?.rotaInfo?.rota?.schedule) {
            //console.log("hairdresser.rotaInfo.rota",hairdresser.rotaInfo.rota.schedule[0],moment().format('ddd').toLowerCase());
            let selected = _.find(schedule?.hairdresser?.rotaInfo?.rota?.schedule[0].profiles, function (o) {
                return o.day === moment(self.state.bookingDate).format('ddd').toLowerCase();
            })
            if (selected) {
                //return this.getslotTime(selected.startTime) + '-' + this.getslotTime(selected.endTime);
                appointmentList.push({
                    id: '100',
                    // title: 'Event One',
                    start: todayStr + 'T00:00:00',
                    display: 'background',
                    selectable: false,
                    end: todayStr + 'T' + getslotTimeSec(selected.startTime),
                    color: '#727272'
                })
                appointmentList.push({
                    id: '100',
                    // title: 'Event One',
                    start: todayStr + 'T' + getslotTimeSec(selected.endTime),
                    display: 'background',
                    selectable: false,
                    end: todayStr + 'T24:00:00',
                    color: '#727272'
                })
            } else {
                appointmentList.push({
                    id: '100',
                    // title: 'Event One',
                    start: todayStr + 'T00:00:00',
                    display: 'background',
                    selectable: false,
                    end: todayStr + 'T24:00:00',
                    color: '#727272'
                })
            }
        } else {
            appointmentList.push({
                id: '100',
                // title: 'Event One',
                start: todayStr + 'T00:00:00',
                display: 'background',
                selectable: false,
                end: todayStr + 'T24:00:00',
                color: '#727272'
            })
        }
        //console.log("appointmentList",appointmentList);
        return appointmentList;
    }


    getEventForTime = (schedule) => {
        let self = this;
        let appointmentList = [];

        let todayStr = moment().format('YYYY-MM-DD');
        if (schedule?.hairdresser?.rotaInfo?.rota?.schedule) {
            //console.log("hairdresser.rotaInfo.rota",hairdresser.rotaInfo.rota.schedule[0],moment().format('ddd').toLowerCase());
            let selected = _.find(schedule?.hairdresser?.rotaInfo?.rota?.schedule[0].profiles, function (o) {
                return o.day === moment(self.state.bookingDate).format('ddd').toLowerCase();
            })
            // console.log("selected",selected);
            if (selected) {
                //return this.getslotTime(selected.startTime) + '-' + this.getslotTime(selected.endTime);
                appointmentList.push({
                    id: '100',
                    // title: 'Event One',
                    start: todayStr + 'T00:00:00',
                    display: 'background',
                    selectable: false,
                    end: todayStr + 'T' + getslotTimeSec(selected.startTime),
                    color: '#727272'
                })
                appointmentList.push({
                    id: '100',
                    // title: 'Event One',
                    start: todayStr + 'T' + getslotTimeSec(selected.endTime),
                    display: 'background',
                    selectable: false,
                    end: todayStr + 'T24:00:00',
                    color: '#727272'
                })
            } else {
                appointmentList.push({
                    id: '100',
                    // title: 'Event One',
                    start: todayStr + 'T00:00:00',
                    display: 'background',
                    selectable: false,
                    end: todayStr + 'T24:00:00',
                    color: '#727272'
                })
            }
        } else {
            appointmentList.push({
                id: '100',
                // title: 'Event One',
                start: todayStr + 'T00:00:00',
                display: 'background',
                selectable: false,
                end: todayStr + 'T24:00:00',
                color: '#727272'
            })
        }
        //console.log("appointmentList",appointmentList);
        return appointmentList;
    }

    getTodayWorkingTime = (schedule) => {
        let self = this;
        let todayStr = moment().format('YYYY-MM-DD');
        if (schedule?.hairdresser?.rotaInfo?.rota?.schedule) {
            //console.log("hairdresser.rotaInfo.rota",hairdresser.rotaInfo.rota.schedule[0],moment().format('ddd').toLowerCase());
            let selected = _.find(schedule?.hairdresser?.rotaInfo?.rota?.schedule[0].profiles, function (o) {
                return o.day === moment(self.state.bookingDate).format('ddd').toLowerCase();
            })
            if (selected) {
                return {
                    selectable: 1,
                    todayStartWorking: todayStr + 'T' + getslotTimeSec(selected.startTime),
                    todayEndWorking: todayStr + 'T' + getslotTimeSec(selected.endTime),
                }
            } else {
                return {
                    selectable: 2,
                    todayStartWorking: '',
                    todayEndWorking: '',
                }
            }
        } else {
            return {
                selectable: 2,
                todayStartWorking: '',
                todayEndWorking: '',
            }
        }
    }


    componentWillUnmount() {
        let self = this;
        window.removeEventListener('scroll', self.handleScroll);
        document.body.classList.remove('diary-temp');
        //document.removeEventListener("mousedown", this.handleClickOutsideFilter);
     
    }

    handleScroll = event => {
        this.setState({ scrollTopPos: window.pageYOffset });
    }


// start filter drag and drop code here;

handleOnDragEnd = (result) => {
    let self = this;

    if (!result.destination) return;
    const newItems = Array.from(self.state.staffList);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);
    let _orderedListData =[];

    if((self?.state?.workingStaffStatus && self?.state?.selectedFilterName==='working_staff')||(self?.state?.allStaffStatus && self?.state?.selectedFilterName==='all_staff')){
        _orderedListData=newItems;
    }else{
        _orderedListData=newItems;
    }
    
    self.setState({
        staffList:newItems,
        isDefaultStaff:false,
        workingStaffStatus:self.state.workingStaffStatus,
    })
    self.props.dispatch(hairdresserActions.dairyFilterStaffOrderList(_orderedListData));
  };

  showDragFilter = ()=>{
    let self = this;
    // console.log("this.state.hairdresserCount===this.props?.hairdresserSchedule?.length",this.state.hairdresserCount,this.props?.hairdresserSchedule?.length)
    if(this.state.hairdresserCount===this.props?.hairdresserSchedule?.length) {
        this.setState({
            openDragFilterPopup: true,
            workingStaffStatus: self.state.workingStaffStatus,

        })
        document.body.classList.add('dairy-filter-menu_open')
    }
}

//   showDragFilter = ()=>{
//     let self = this;
//     this.setState({
//         openDragFilterPopup : !self.state.openDragFilterPopup,
//      })
//      if(!this.state.openDragFilterPopup){
//         document.body.classList.add('dairy-filter-menu_open');
//      }else{
//         document.body.classList.remove('dairy-filter-menu_open');
//         if(self.state?.isFilterChange){
//            self.setFilterCalenderData();
//            self.setState({
//             isFilterChange:false
//            });
//         }
//      }
//   }

  filterByWorkingStaff = (e)=>{
    let self = this;
    let fieldName = e?.target?.name;
    let fieldStatus = e?.target?.checked;

    if(fieldName){
        self.filterSelectedInput(fieldName);
        if(fieldName==='working_staff'){
          if(fieldStatus){
            let workingDay= moment(self?.state?.bookingDate).format('ddd').toLowerCase();
            let workingStaff =  self.state.staffList.filter((_hairdresser,i)=>{
                let _workingStaff;
                if(_hairdresser?.rotaInfo?.rota?.schedule){
                    _workingStaff = _hairdresser?.rotaInfo?.rota?.schedule[0]?.profiles?.filter((today,i)=>today?.day==workingDay);
                    if(_workingStaff.length>0){
                        return _hairdresser
                    }
                }
            })

         let workingStaffIds = self?.getStaffIds(workingStaff);
         if(document.getElementById('clnder-0')?.clientWidth > 0){
            clientWidth = document.getElementById('clnder-0').clientWidth * workingStaff.length;
            // console.log("clientWidth",clientWidth);
        }
         self.workingStaffStatus(workingStaffIds)
         let _filterState = {
            allStaffStatus :false,
            workingStaffStatus:fieldStatus,
            selectedFilterName:fieldName,
            isFilterChange:false,
            isDefaultStaff:false,
         }
            this.setState(_filterState,  self.props.dispatch(hairdresserActions.activeFilterStatus(_filterState)))
           // self.changeCheckBoxStatus('working_staff',fieldStatus);
          }else{

            self.workingStaffStatus([]);
            let _filterState = {
                allStaffStatus :false,
                workingStaffStatus:fieldStatus,
                selectedFilterName:'Staff not selected',
                selected_filter_text: (!fieldStatus&&!self.state.allStaffStatus)?'Staff not selected':'Working Staff',
                isFilterChange:false,
                isDefaultStaff:false,
             }

            this.setState(_filterState,self.props?.dispatch(hairdresserActions.activeFilterStatus(_filterState)))
          }
        }else{
            self.changeCheckBoxStatus('',!self.state.allStaffStatus);
            let _filterState = {
                allStaffStatus :!self.state.allStaffStatus,
                workingStaffStatus:!self.state.allStaffStatus,
                selectedFilterName:!self.state.allStaffStatus?'all_staff':'Staff not selected',
                selected_filter_text:!self.state.allStaffStatus?'All Staff':'Staff not selected',
                selectedHairdressers:[],
                isFilterChange:false,
                isDefaultStaff:false,
             }

            self.props.dispatch(hairdresserActions.activeFilterStatus(_filterState));
            this.setState(_filterState)
        }
    }
  }



  getStaffIds=(workingStaff)=>{
    let workingStaffIds = workingStaff.map(__staff=>{
        return __staff._id;
     })
        return workingStaffIds;
  }

  workingStaffStatus = (workingStaffIds,status)=>{
    let self = this;
    let workingStaffList =[];
    let staffDataList = self.state?.staffList?.map((staff,i)=>{
        if(workingStaffIds.includes(staff._id)){
            staff.checkStatus=true;
            workingStaffList.push(staff)
        }else{
            staff.checkStatus=false;
        }
        return staff;
    })

    this.setState({
        staffList:staffDataList,
        selectedHairdressers:workingStaffList,
        workingStaffStatus:false,
     })
    
  }

  filterByHairdresser = (e,hairdresserId)=>{
    let self = this;
    let checkStatus = e?.target?.checked!=undefined?e?.target?.checked:e;
    let _selectedHairdresserId;
    let _data; 

    if(checkStatus){
        _selectedHairdresserId = self?.state?.staffList?.filter(_hairdresserId=>_hairdresserId._id===hairdresserId)
         self.changeCheckBoxStatus(hairdresserId,checkStatus);
        _data =[...self.state.selectedHairdressers,..._selectedHairdresserId]
    }else{
        _selectedHairdresserId = self.state?.selectedHairdressers?.filter(_hairdresserId=>_hairdresserId._id!==hairdresserId);
        self.changeCheckBoxStatus(hairdresserId,checkStatus);
        _data =[..._selectedHairdresserId]
    }

    if(_data.length>1){
        // console.log("_data_data_data-2",_data)
        let selectedHairdresserData = `Visible Staff (${(_data.length)})`
        let _filterState ={
            selectedHairdressers :_data,
            selectedFilterName:selectedHairdresserData,
            selected_filter_text:selectedHairdresserData,
            allStaffStatus:false,
            workingStaffStatus:false,
         };

    self.filterSelectedInput(selectedHairdresserData);
    this.setState(_filterState,self?.props?.dispatch(hairdresserActions.activeFilterStatus(_filterState)));
    }else{
        // console.log("_data_data_data",_data)
        // console.log("_data_data_data__selectedHairdresserId",_selectedHairdresserId)
        // let selectedFilterName = _data.length===1? _selectedHairdresserId[0]?.name?_selectedHairdresserId[0]?.name:'':'Staff not selected';
        let selectedFilterName = _data.length===1? _data[0]?.name:'Staff not selected';
        
        // console.log('check data',checkStatus);

        let _filterState ={
            selectedHairdressers :_selectedHairdresserId,
            selectedFilterName: selectedFilterName,
            selected_filter_text:selectedFilterName,
            allStaffStatus:false,
            workingStaffStatus:false,
            // isDefaultStaff: false,
         }; 
    
         self.filterSelectedInput(selectedFilterName);
         this.setState(_filterState,self?.props?.dispatch(hairdresserActions.activeFilterStatus(_filterState)));
    }
   
    //self.state.staffList;
    // let fieldName = e.target.name;

  }

  
  filterSelectedInput(input_value){
    let self = this;
    switch (input_value) {
        case 'all_staff':
            self.setState({
                selected_filter_text:'All Staff'
            })
            break;
        case 'working_staff':
          
        self.setState({
            selected_filter_text:'Working Staff'
        })
            break;
        default:
            if(self?.state?.selectedHairdressers?.length>1){
                self.setState({
                    selected_filter_text:`Visible Staff (${(self?.state?.selectedHairdressers?.length)})`
                })
            }else{
                // console.log('checkdddddd',input_value, self.props.activeFilterStatus)
                self.setState({
                    selected_filter_text:self.state.selectedFilterName
                })
            }  
    }
  }


  changeCheckBoxStatus(hairdresserId,_status){
    let self = this;
    let transformData= self.state?.staffList?.map(function (_hairdresserId) {
        if(hairdresserId!==''){
            if(_hairdresserId._id===hairdresserId){
                _hairdresserId.checkStatus=_status;
            }else{
                _hairdresserId.checkStatus=_hairdresserId?.checkStatus!=undefined?_hairdresserId.checkStatus:false;
            }
        }else{
            _hairdresserId.checkStatus=_status;
        }
        
        return _hairdresserId
    });

    self.setState({
        staffList:transformData
    })
 }

 handleClickOutsideFilter = (event) => {
    let self = this;
    if (this.filterRef.current && !this.filterRef?.current?.contains(event.target)) {
      self.setState({
        openDragFilterPopup :!this.state.openDragFilterPopup
     })

     if(!this.state.openDragFilterPopup){
        document.body.classList.remove('dairy-filter-menu_open');
        self.setState({
            openDragFilterPopup:false
        })
     }
     this.dragAndDropOrderedHairdresser();
    }
  };


  dragAndDropOrderedHairdresser(){
    let self = this;
     self.props.dispatch(hairdresserActions.getHairdresserScheduleReset());
    
     if(self.state.selectedHairdressers?.length>0){
        let _selectedData = self.state?.staffList?.filter(item=>item?.checkStatus==true);
        self.props.dispatch(hairdresserActions.dairyFilterData([]));
        self.props.dispatch(hairdresserActions.dairyFilterData(_selectedData));
        // console.log("_selectedData",_selectedData);
        self.createCalendarSlots(_selectedData);
     }else{
        let _selectedData = self.state?.staffList?.filter(item=>item?.checkStatus==true);
        self.props.dispatch(hairdresserActions.dairyFilterData(_selectedData));
        //  console.log("_selectedData",_selectedData);
        self.createCalendarSlots(_selectedData);
     }
  }

// setFilterCalenderData = () =>{
//     let self = this;
//     self.props.dispatch(hairdresserActions.getHairdresserScheduleReset());
    
//      if(self.state.selectedHairdressers?.length>0){
//         let _selectedData = self.state.staffList.filter(item=>item?.checkStatus==true);
//         self.props.dispatch(hairdresserActions.dairyFilterData([]));
//         self.props.dispatch(hairdresserActions.dairyFilterData(_selectedData));
//         self.createCalendarSlots(_selectedData); 
        
//      }else{
//         let _selectedData = self.state.staffList.filter(item=>item?.checkStatus==true);
//         self.props.dispatch(hairdresserActions.dairyFilterData(_selectedData));
//         self.createCalendarSlots(_selectedData);
//      }
// }

startNewBooking(){
    let self = this;
    let currentPageUrl = self?.props.history.location.pathname;
    const stateObj = { pageLoadingStatus: 1,
        previousPageUrl:currentPageUrl,
        bookingDate:self.state.bookingDate,
        isFirstTimeLoad:self.state.isFirstTimeLoad
    };

    const url =  "/newBooking/" + self.props.match.params.salonId;
    self.props.history.push({
      pathname: url,
      state: stateObj,
    });
}


processingTimeBooking(status){
         let self = this;
        //  console.log('checksssss', status);
         self.setState({
            processingTimeStatus:status,
         })
}
handleShiftDayClick = (staff) => {
    // debugger;
    let self=this;
    let todayStartWorking;
    let todayEndWorking;
    let isTodayWorking=false;
    console.log("######",staff?.rotaInfo)
    if (staff?.rotaInfo?.rota?.schedule) {
        let selected = _.find(staff?.rotaInfo?.rota.schedule[0].profiles, function (o) {
            return o.day === moment(self.state.bookingDate).format('ddd').toLowerCase();
        })
        if(selected&&selected?.startTime!==''&&selected?.endTime!=='') {
            let hrBSt = parseInt(selected.startTime / 60);
            let minBSt = selected.startTime - (hrBSt * 60)
            todayStartWorking = new Date().setHours(hrBSt, minBSt);

            let hrBEt = parseInt(selected.endTime / 60);
            let minBEt = selected.endTime - (hrBEt * 60);
            todayEndWorking = new Date().setHours(hrBEt, minBEt);
            console.log("######", todayStartWorking, todayEndWorking);
            isTodayWorking=true;
        }
    }
    this.setState({
        selectedStaff: staff,
        selectedDate: this.state.bookingDate,
        todayStartWorking:todayStartWorking,
        todayEndWorking:todayEndWorking,
        isTodayWorking
    },function (){
        self.setState({
            openShiftDay: true,
        })
    });
}

handleShiftDayClose = () => {
    this.setState({
        openShiftDay: false,
        selectedStaff: null
    });
}
selectSlot(type,e) {
    // console.log("e", e);
    // let self = this;
    this.setState({
        [type]: e
    })
}
getMinTime(time){
    if(time&&time!=='') {
        let sTime = moment(time).format('HH:mm');
        let sTimeArr = sTime.split(":");
        console.log("sTimeArr", sTimeArr);
        let min = (parseInt(sTimeArr[0]) * 60) + parseInt(sTimeArr[1])
        return min;
    }else {
        return 0;
    }
}
saveOpenShiftDay=()=>{
let self=this;
 let params = {
     isRemoved:2,
     workingDate:moment(this.state.bookingDate).format('YYYY-MM-DD'),
     startTime:this.getMinTime(this.state.todayStartWorking),
     endTime:this.getMinTime(this.state.todayEndWorking),
     hairdresserId:this.state.selectedStaff._id
 }
 console.log("@@@@@",this.getMinTime(this.state.todayStartWorking));
    console.log("@@@@@",this.getMinTime(this.state.todayEndWorking));
 if(!this.getMinTime(this.state.todayStartWorking)){
     toast.error('Please select a start time');
 }else if (!this.getMinTime(this.state.todayEndWorking)){
     toast.error('Please select an end time');
 }else if(this.getMinTime(this.state.todayStartWorking)>this.getMinTime(this.state.todayEndWorking)){
     toast.error('Please select an end time greater than the start time');
 }else {
     // console.log("paramparamstaffIdstaffId",params)
     self.props.dispatch(hairdresserActions.saveWorkingRota(params))
         .then(function (response) {
             console.log("response",response)

                 self.setState({
                     openShiftDay: false
                 })
                 console.log("response", response, window.location.href)
                 // self.loadDairyData(false)
                 const noCacheUrl = window.location.href.split('?')[0] + '?nc=' + new Date().getTime();
                 // console.log("@@@@@@@@@@@@@@@@@@@@",noCacheUrl);
                 window.location.href = noCacheUrl;
                 //window.location.replace(window.location.href+"?d"=new Date().getTime());
             // }
             // else{
             //     toast.error(response?.editCategory?.error? response?.editCategory?.error?.responseMessage : response?.editCategory?.responseData?.message);
             // }

         })
         .catch((err) => {
            // console.log("err",err)
         })
 }
}

    deleteOpenShiftDay=()=>{
        let self=this;
        let params = {
            isRemoved:1,
            workingDate:moment(this.state.bookingDate).format('YYYY-MM-DD'),
            startTime:this.getMinTime(this.state.todayStartWorking),
            endTime:this.getMinTime(this.state.todayEndWorking),
            hairdresserId:this.state.selectedStaff._id
        }
        // console.log("paramparamstaffIdstaffId",params)
        self.props.dispatch(hairdresserActions.saveWorkingRota(params))
            .then(function (response) {
                self.setState({
                    openShiftDay:false
                })
                console.log("response",response)
                // self.loadDairyData(false)
                // window.location.replace(window.location.href);
                const noCacheUrl = window.location.href.split('?')[0] + '?nc=' + new Date().getTime();
                // console.log("@@@@@@@@@@@@@@@@@@@@",noCacheUrl);
                window.location.href = noCacheUrl;
            })

    }
render() {
    let self = this;
    console.log("this.state",this.state);
    console.log("this.props",this.props);
    // console.log("this.state",this.state,this.props?.hairdresserSchedule?.length);
    return (
        <>
            <div className="dairy-container-header __new_one">
                <TopHeader title="" {...this.props} />
                <CalendarCard bookingDate={this.state.bookingDate} isFirstTimeLoad={this.state.isFirstTimeLoad}  setCalenderDate={this.setCalenderDate} />
            </div>
            <SideNav {...this.props} />
            <div className="bookingClanderView bg-white">
                <div className='visible-staff-body'>
                <div> 
                    {this.state.openDragFilterPopup && (
                         <div className="client_filter_up"></div>
                    )}
                    <div className={`client_filter ${this.state.openDragFilterPopup ? 'open' : ''}`} >
                    <div className='drag-show_body' onClick={()=>this.showDragFilter()}>
                        <label>{this.state.selected_filter_text}</label>
                        <span  className={this?.state?.openDragFilterPopup?'right_corner _filter_rotate':'right_corner'}><ExpandICon/></span>
                    </div>
                    {this.state.openDragFilterPopup===true?
                    <>
                     <DragDropContext onDragEnd={this.handleOnDragEnd}>
                        <Droppable droppableId="items">
                            {(provided) => (
                           <div className='drag_dropdown_body' ref={this.filterRef}>
                             <ul {...provided.droppableProps} ref={provided.innerRef}>
                                <li className="mb-2">
                                    <div className='inner'>
                                      <input type='checkbox' name='all_staff' checked={this?.state?.allStaffStatus} onChange={(e)=>this.filterByWorkingStaff(e)}/>  <label> All Staff</label>
                                    </div>
                                </li>
                                <li className='mb-4'>
                                    <div className='inner'>
                                      <input type='checkbox' onChange={(e)=>this.filterByWorkingStaff(e)} checked={this?.state?.workingStaffStatus} name='working_staff'/>  <label>Working Staff</label>
                                    </div>
                                </li>
                                {this.state.staffList.map((staff_data, index) => (
                                <Draggable key={staff_data?._id} draggableId={staff_data?._id} index={index}>
                                    {(provided) => (
                                    <li
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                    >
                                     <div className='inner'>
                                      <input type='checkbox' name={staff_data?._id} onChange={(e)=>this.filterByHairdresser(e,staff_data?._id)} checked={staff_data?.checkStatus?staff_data?.checkStatus:false}/> <label> {staff_data?.name}</label>
                                     </div>
                                     <span><DragIcon/></span>
                                    </li>
                                    )}
                                </Draggable>
                                ))}
                                {provided.placeholder}
                            </ul>
                           </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    </>:''}
                   </div>
                </div>
                <div style={{display:"none"}} className={`client_filter ${ this?.state?.isSelectopen ? "openSelect" : "closeSelect"}`} >
                    <ReactSelect
                    isSearchable={false}
                    options={this.state.myData}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option }}
                    allowSelectAll={true}
                    placeholder="Visible Staff"
                    isClearable={false}
                    menuIsOpen
                    menuPlacement="auto"
                    value={this.state.selectedOption}
                    />
                   </div>
                </div>
                {console.log("this.props?.hairdresserSchedule?.length",this.props?.hairdresserSchedule)}
                <div className={`new--diary--clndr ${this.props?.hairdresserSchedule?.length>5?'more_column_fix_width':''}`} ref={this.scrollref} onScroll={this.getScrollValue} style={{left: this.getScrollValue}} id="dairy-scroll-positon">
                    {
                        this.props?.hairdresserSchedule?.length > 0 && this.state.hairdresserCount===this.props?.hairdresserSchedule?.length ?
                            <div  className='new--dairy-clnader-body timeonly-from-td' style={{width: this.props?.hairdresserSchedule.length > 4 ? `${clientWidth}px` : 'auto'}}>
                             {this.state.processingTimeStatus?<div className={'processing-dairy-loader'}> <img src={require('../../assets/images/loading.gif')} className="img-fluid"/></div>:null}
                            <div id="hello" className={`new--diary-clnder-body ${this.props.hairdresserSchedule.length <= 4 ? 'new-dairy-temp-4' : ""}`} >

                                {this.props.hairdresserSchedule.map((schedule, index) => (
                                    index===0?
                                        <React.Fragment key={'mba-'+index}><CalenderModule __id={`clnder-abcd`}
                                                        clClass={`clnder--${index}`} key={'cl_abcd' + index}
                                                        dispatch={this?.props?.dispatch}
                                                        calenderStartTime={this?.state?.calenderStartTime}
                                                        workTime={this.getTodayWorkingTime(schedule)}
                                                        calenderEndTime={this?.state?.calenderEndTime}
                                                        loadProcessingTime={this.processingTimeBooking}
                                                        initial_events={this?.getEvent(schedule)}
                                                        user={this.props.user}
                                                        bookingDate={this?.state?.bookingDate}
                                                        scrollFixTimeLine={this?.scrollFixTimeLine}
                                                        staff={schedule?.hairdresser}
                                                        scrollTimelineStatus={this?.state?.scrollTimelineStatus}
                                                        showTime={true}
                                                        userIndex={index}
                                        />
                                        <CalenderModule __id={`clnder-${index}`}
                                                        clClass={`clnder--${index} clnderbox_${this.props.hairdresserSchedule.length}`} key={'cl_' + index}
                                                        dispatch={this?.props?.dispatch}
                                                        calenderStartTime={this?.state?.calenderStartTime}
                                                        workTime={this.getTodayWorkingTime(schedule)}
                                                        calenderEndTime={this?.state?.calenderEndTime}
                                                        loadProcessingTime={this.processingTimeBooking}
                                                        initial_events={this?.getEvent(schedule)}
                                                        user={this.props.user}
                                                        bookingDate={this?.state?.bookingDate}
                                                        scrollFixTimeLine={this?.scrollFixTimeLine}
                                                        staff={schedule?.hairdresser}
                                                        scrollTimelineStatus={this?.state?.scrollTimelineStatus}
                                                        showTime={false}
                                                        userIndex={index}
                                                        noOfBox={this.props.hairdresserSchedule.length}
                                                        onShiftDayClick={this.handleShiftDayClick}
                                        /></React.Fragment>
                                        :
                                        <CalenderModule __id={`clnder-${index}`}
                                        clClass={`clnder--${index} clnderbox_${this.props.hairdresserSchedule.length}` } key={'cl_' + index}
                                        dispatch={this?.props?.dispatch}
                                        calenderStartTime={this?.state?.calenderStartTime}
                                        workTime={this.getTodayWorkingTime(schedule)}
                                        calenderEndTime={this?.state?.calenderEndTime}
                                        loadProcessingTime={this.processingTimeBooking}
                                        initial_events={this?.getEvent(schedule)}
                                        user={this.props.user}
                                        bookingDate={this?.state?.bookingDate}
                                        scrollFixTimeLine={this?.scrollFixTimeLine}
                                        staff={schedule?.hairdresser}
                                        scrollTimelineStatus={this?.state?.scrollTimelineStatus}
                                        showTime={false}
                                        userIndex={index}
                                        noOfBox={this.props.hairdresserSchedule.length}
                                        onShiftDayClick={this.handleShiftDayClick}
                                        />
                                ))}
                            </div>
                            </div>
                            :
                            <>{(this?.state?.selectedFilterName!=='Staff not selected') ?<div className="text-center pr-md-5">
                                <Lottie options={defaultOptions}
                                    height={50}
                                    width={200}
                                />
                                <center><h4 className="text-center">Loading Data...</h4></center>
                                </div>:null}
                                <center><h4 className="text-center">{this?.state?.selectedFilterName==='Staff not selected'? this?.state?.selectedFilterName:null}</h4></center>
                                </>}
                </div>
                <div id="addbutton">
                    <div className="add-newbooking" style={{cursor:'pointer'}} onClick={()=>this.startNewBooking()}><Plus /></div>
                </div>
            </div>

            <Modal show={this.state.openShiftDay}
                   // onHide={this.handleShiftDayClose}
            size="lg"
        className=" text-center __service_edit_modal shift_day_modal" centered>
                        {/* <i className="fa fa-times cm_hide_mod" onClick={this.handleShiftDayClose} /> */}
                        <Modal.Body>
                            <div className="p-1 pt-2 pl-3 pr-3 pb-2 text-left">
                            <h5 style={{fontSize: '22px', fontWeight: '500'}} className="mb-3 mdl-hdkng">{this.state.selectedStaff?.name}’s shift for {moment(this.state.selectedDate).format("dddd, DD MMMM YY")}</h5>
                            <p style={{fontSize: '15px', fontWeight: '300'}}>This adjustment will only apply for this specific day. To make a permanent schedule change, go to <Link to="/staff/rota" style={{color: "#007EA0",fontWeight:'600'}}>rota</Link>
                                 .</p>
                            <div className="_shift_day_time mt-4">
                              <div className="form-group sm_date_picker w-60 mb-0">
                                  <label style={{fontSize: '14px', fontWeight: '600',marginBottom: '5px'}}>Start Time</label>
                                  <ReactDatePicker
                                    selected={this.state.todayStartWorking}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={5}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    timeFormat="HH:mm" placeholderText="9.00am"
                                    onChange={this.selectSlot.bind(this,'todayStartWorking')}
                                    />
                              </div>
                              <div className="_dashed_centert"><span/></div>
                              <div className="form-group sm_date_picker mb-0">
                                  <label style={{fontSize: '14px', fontWeight: '600',marginBottom: '5px'}}>Finish Time</label>
                                  <ReactDatePicker
                                      selected={this.state.todayEndWorking}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={5}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    timeFormat="HH:mm"
                                    placeholderText="10.00am"
                                    onChange={this.selectSlot.bind(this,'todayEndWorking')}
                                      minTime={this.state.todayStartWorking?this.state.todayStartWorking:new Date().setHours(0,0)}
                                      maxTime={new Date().setHours(23,59)}
                                    />
                              </div>
                            </div>

                                <div className="btn-group-container mt-3">
                                    <div className="mt-4 text-left btn_">
                                        {this.state.isTodayWorking?
                                        <button className="btn btn-danger mr-2" onClick={this.deleteOpenShiftDay}>Delete</button>
                                            :""}
                                    </div>
                                    <div className="mt-4 text-right btn_">
                                        <button className="btn btn-outline-dark mr-3" onClick={this.handleShiftDayClose}>Cancel</button>
                                        <button className="btn btn-info" onClick={this.saveOpenShiftDay}>Save</button>
                                    </div>
                                </div>


                            </div>
                        </Modal.Body>
            </Modal> 
        </>
    )
}
}

function mapStateToProps(state) {
// console.log(state.calender, "this.props======++::2")
// console.log(state, "this.props======++::3")

// dairyFilterData
let { hairdresserSchedule } = state?.calender
return {
    hairdresserSchedule,
    ...state
};
}

export default connect(mapStateToProps)(NewDiary);





