import axios from 'axios';
import API from "../config/API";
import siteSetting from "../config/env";

export const hairdresserService = {
    hairdresserProfile,
    hairdresserReview,
    salonProfile,
    salonList,
    getService,
    hairdresserSchedule,
    getClient,
    switchProfile,
    getClientDetail,
    clientAdd,
    staffList,
    addStaff,
    editStaff,
    signup,
    updateHairdresser,
    hairdresserUserService,
    staffListService,
    staffServiceDetail,
    addService,
    updateService,
    deleteService,
    staffRota,
    getRota,
    saveRota,
    editClient,
    removeStaff,
    deleteClient,
    checkEmailAvailability,
    serviceHairdresserList,
    getLastBookedClients,
    staffRotaV2,
    staffListServiceV2,
    staffListV2,
    staffListServiceV2WithoutService,
    updateInterval,
    hairdresserProfileWeb,
    getHairdresserPolicies,
    saveWorkingRota
};

function hairdresserProfile(params) {
    params.clientId=siteSetting.api_clientId;
    params.clientSecret=siteSetting.api_clientSecret;
    //console.log('params',params);
    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    };
    return axios.get(API.HAIRDRESSER_PROFILE+params.hairdresserId+'/bookingProfile', {params:params,headers:headers})
}

function hairdresserProfileWeb(params) {
    params.clientId=siteSetting.api_clientId;
    params.clientSecret=siteSetting.api_clientSecret;
    //console.log('params',params);
    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    };
    return axios.get(API.HAIRDRESSER_PROFILE+params.hairdresserId+'/bookingProfile/web', {params:params,headers:headers})
}


function hairdresserSchedule(params) {

    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    };
    return axios.get(API.HAIRDRESSER_PROFILE+params, {headers:headers})
}

function hairdresserReview(params) {
    params.clientId=siteSetting.api_clientId;
    params.clientSecret=siteSetting.api_clientSecret;
    //console.log('params',params);
    return axios.get(API.HAIRDRESSER_REVIEW+params.hairdresserId+'/review', {params:params})
}


function salonProfile(params) {
    params.clientId=siteSetting.api_clientId;
    params.clientSecret=siteSetting.api_clientSecret;
    //console.log('params',params);
    return axios.get(API.SALON_PROFILE+params.salonId, {params:params})
}

function salonList(params) {
    params.clientId=siteSetting.api_clientId;
    params.clientSecret=siteSetting.api_clientSecret;
    //console.log('params',params);
    return axios.get(API.SALON_LIST, {params:params})
}

function getService(){
    let params = {}
    params.clientId=siteSetting.api_clientId;
    params.clientSecret=siteSetting.api_clientSecret;
    //console.log('params',params);
    return axios.get(API.SERVICES, {params:params})
}

function getClient(params) {

    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    };
    return axios.post(API.HAIRDRESSER_PROFILE+params.hairdresserId+'/client/list',params, {headers:headers})
}

function switchProfile(params) {

    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    };
    let accessToken = localStorage.getItem('accessToken');
    if(accessToken && accessToken!=="") {
        axios.defaults.headers.common['Authorization'] = `${'Bearer ' + accessToken}`;
    }
    return axios.get(API.HAIRDRESSER_SWITCH_PROFILE,{params:params}, {headers:headers})
}



function getClientDetail(params) {

    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    };
    return axios.post(API.HAIRDRESSER_PROFILE+params.hairdresserId+'/client/details',params, {headers:headers})
}


function clientAdd(params) {

    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    };
    return axios.post(API.HAIRDRESSER_PROFILE+params.hairdresserId+'/client/add',params, {headers:headers})
}

function staffList(params) {

    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    };
    return axios.get(API.HAIRDRESSER_PROFILE+'employee', {headers:headers})
}

function staffListV2(params) {

    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    };
    return axios.get(API.HAIRDRESSER_PROFILE+'employee/v2', {headers:headers})
}

function addStaff(params) {
    let accessToken=localStorage.getItem('accessToken')
    let  headers= {
        'Content-Type' : 'multipart/form-data',
        //'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    // return axios.post(API.HAIRDRESSER_PROFILE+'addemp',params, {headers:headers})
    return axios.post(API.ADD_STAFF_V2,params, {headers:headers})
}

// function editStaff(params) {
//
//     let  headers= {
//         'Content-Type' : 'multipart/form-data',
//         'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
//     };
//     return axios.post(API.HAIRDRESSER_PROFILE+'editemp',params, {headers:headers})
// }

function editStaff(params) {
    let accessToken=localStorage.getItem('accessToken')
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    return axios.post(API.EDIT_STAFF_V2,params, {headers:headers})
}

function signup(params) {
    params.append('clientId', siteSetting.api_clientId);
    params.append('clientSecret', siteSetting.api_clientSecret);
    //console.log('params',params);
    return axios.post(API.HAIRDRESSER_SIGNUP, params)
}

function updateHairdresser(params,hairdresserId) {
    let  headers= {
        'Content-Type' : 'multipart/form-data',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    };
    params.append('clientId', siteSetting.api_clientId);
    params.append('clientSecret', siteSetting.api_clientSecret);
    //console.log('params',params);
    return axios.put(API.HAIRDRESSER_PROFILE+hairdresserId, params,{headers:headers})
}

function hairdresserUserService(params) {
    // params.clientId=siteSetting.api_clientId;
    // params.clientSecret=siteSetting.api_clientSecret;
    params.deviceToken='sdgfsdgsd';
    //console.log('params',params);
    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    };
    return axios.get(API.HAIRDRESSER_PROFILE+params.hairdresserId+'/dashboard', {params:params,headers:headers})
}

function staffListService(params) {

    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    };
    return axios.post(API.HAIRDRESSER_PROFILE+'employee/service',params, {headers:headers})
}

function staffListServiceV2(params) {

    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    };
    return axios.post(API.HAIRDRESSER_PROFILE+'employee/service/v2',params, {headers:headers})
}

function staffListServiceV2WithoutService(params) {

    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    };
    return axios.post(API.HAIRDRESSER_PROFILE+'employee/service/v2WithoutService',params, {headers:headers})
}

function staffServiceDetail(params) {

    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    };
    return axios.post(API.HAIRDRESSER_PROFILE+'employee/serviceDetail',params, {headers:headers})
}

function addService(params) {

    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    };
    return axios.post(API.ADD_SERVICE,params, {headers:headers})
}

function updateService(params) {

    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    };
    return axios.put(API.ADD_SERVICE,params, {headers:headers})
}

function deleteService(params) {

    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    };

    // return axios.delete(API.ADD_SERVICE,{data:params}, {headers:headers});
    return axios.delete(API.ADD_SERVICE, {
        headers:headers,
        data:params
    });
}

function staffRota(params) {
    params.deviceToken='sdgfsdgsd';
    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    };
    return axios.get(API.HAIRDRESSER_PROFILE+params.hairdresserId+'/rota/emp', {params:params,headers:headers})
}

function staffRotaV2(params) {
    // params.deviceToken='sdgfsdgsd';
    // let  headers= {
    //     'Content-Type' : 'application/json',
    //     'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    // };
    // return axios.get(API.HAIRDRESSER_PROFILE+params.hairdresserId+'/rota/emp/v2', {params:params,headers:headers})

    let accessToken=localStorage.getItem('accessToken')
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    return axios.post(API.STAFF_LIST_V2,params, {headers:headers})
}

function getRota(params) {
    params.deviceToken='sdgfsdgsd';
    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    };
    return axios.get(API.HAIRDRESSER_PROFILE+params.hairdresserId+'/rota', {params:params,headers:headers})
}

function saveRota(params) {
    params.deviceToken='sdgfsdgsd';
    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    };
    return axios.put(API.HAIRDRESSER_PROFILE+params.hairdresserId+'/rota',params, {headers:headers})
}

function editClient(params) {

    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    };
    return axios.post(API.HAIRDRESSER_PROFILE+params.hairdresserId+'/client/editnote',params, {headers:headers})
}

// function removeStaff(params) {
//     let  headers= {
//         'Content-Type' : 'application/json',
//         'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
//     };
//     return axios.post(API.HAIRDRESSER_PROFILE+'empremove',params, {headers:headers})
// }

function removeStaff(params) {
    let accessToken=localStorage.getItem('accessToken')
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    return axios.post(API.REMOVE_STAFF_V2,params, {headers:headers})
}

function deleteClient(params) {

    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    };
    return axios.post(API.HAIRDRESSER_PROFILE+params.hairdresserId+'/client/remove',params, {headers:headers})
}

function checkEmailAvailability(params) {
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA=="
    };
    return axios.post(API.CHECK_EMAIL, params,{headers:headers})
}

function serviceHairdresserList(params) {
    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    };
    return axios.post(API.HAIRDRESSER_PROFILE+'serviceHairdresserList',params, {headers:headers})
}


function getHairdresserPolicies(params) {
    let accessToken=localStorage.getItem('accessToken')
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    return axios.post(API.GET_HAIRDRESSER_POLICIES_V2,params, {headers:headers})

    
}




function getLastBookedClients(params) {
    let accessToken=localStorage.getItem('accessToken')
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    return axios.post(API.LAST_BOOKED_CLIENTS,params, {headers:headers})
}

function updateInterval(params) {
    let accessToken=localStorage.getItem('accessToken')
    let  headers= {
        'Content-Type' : 'application/json',
        'authorization': "Basic YmV1YXBwOmJldWFwcA==",
        'accessToken': `${accessToken}`
    };
    return axios.post(API.HAIRDRESSER_UPDATE_INTERVAL,params, {headers:headers})
}

function saveWorkingRota(params) {
    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    };
    return axios.post(API.HAIRDRESSER_SAVE_WORKING_ROTA+params.hairdresserId+'/working-rota',params, {headers:headers})
}

