import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactWeeklyDayPicker from "react-weekly-day-picker";
import {Modal} from 'react-bootstrap';
import { bookingConstants, settingConstants } from "../../../_constants";
import {hairdresserActions, bookingActions, paymentActions} from "../../../_actions";
import { BackArrow, MySpinner, NotAvailableCalenderICon } from "../../SvgIcon";
import ConfirmBookingPopup from "../../hairdresser/ConfirmBookingPopup"
import toast from "react-toast-notification";
import { ServiceLoader, SlotLoader } from '../SkletonLoader';
import DatePicker from 'react-datepicker';
import moment from "moment";
import siteSetting from '../../../config/env';
import dateFormat from "dateformat";
import Summary from '../Summery/Summary';
import { connect } from "react-redux";
import Draggable from 'react-draggable';
import { loadStripe } from '@stripe/stripe-js';
import { json } from "d3";
import SmoothCollapse from "react-smooth-collapse";
import {userBookingState, userSelectedService} from "../../../_reducers/booking.reducer";
import queryString from "query-string";
import {bookingService} from "../../../_services";


const isMobile = (window.innerWidth <= 768);
const stripePromise = loadStripe(siteSetting.stripeKey);

var _ = require("lodash");
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const weekDays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

const CustomCalender = React.forwardRef(({ value, onClick ,isOpen,toggleCalender}, ref) =>{

  return(
  <h3 className="__newcalender " onClick={()=>{
   if(isOpen){
    toggleCalender(!isOpen);
   }else{
    toggleCalender(true);
    onClick()
   }
   }} ref={ref}>
  {moment(value).format('MMMM')}  {moment(value).format('YYYY')} 
  <span className="icon"><BackArrow/></span>
  </h3>
  )
});

class BookingSlots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weekStart: moment().startOf("week"),
      selectedDay: new Date(),
      month: monthNames[new Date().getMonth()],
      year: new Date().getFullYear(),
      unavailablesDays: [],
      dragableDisabled:false, 
      selectedService:[],
      nextWorking: moment(),
      selectedTime:null,
      finalBookingSlots: [],
      totalTime:0,
      hairdresserDetails:{},
      bookingConf:false,
      totalDeposit:0,
      startTime:null,
      isFormLoader:false,
      showMobileRightPannel:false,
      loader:false,
      expanded: false,
      selectedCalenderDate:new Date(),
      isCalendarOpen:true,
      isbookingSubmit:false,
      userInfo:{},
      salonOwnerInfo:{},
      maxDate:new Date(moment().add(12,'months')),
        daysCount:365,
        exceptions:{},
        unavailablesDate:[],
        upcomingWorkingRota:[]
    };

    this.bookingSlotCreate = this.bookingSlotCreate.bind(this);
    this.selectDay = this.selectDay.bind(this);
    this.getslotTime = this.getslotTime.bind(this);
    this.selectSlot = this.selectSlot.bind(this);
    this.checkSelectedBookingSlots = this.checkSelectedBookingSlots.bind(this);
    this.getHairdressersDetail = this?.getHairdressersDetail.bind(this);
    this.createBooking = this.createBooking.bind(this);
    this.toggleBookingConf = this.toggleBookingConf.bind(this);
    this.convertTo12HourFormat = this.convertTo12HourFormat.bind(this);
    this.datePicker = React.createRef();
    this.scrollDivRef = React.createRef();

  }


componentDidMount(){
   let self = this;
   const {state} = self?.props?.location;
    document.body.classList.add('no-topbar');


  if(state){
    let totalDeposit = state?.selectedServices?.reduce(
      (accumulator, currentValue) => accumulator + currentValue?.depositPrice,
      0,
    );
  
   
  self.setState({
    totalTime:state?.totalTime,
    selectedService:state?.selectedServices,
    totalDeposit:totalDeposit?totalDeposit:0,
    loader:true,
  })
    self.props.dispatch(bookingActions.userBookingState(state))
  // self.toggleBookingConf();
}else{
    let totalDeposit = self.props.userBookingState?.selectedServices?.reduce(
        (accumulator, currentValue) => accumulator + currentValue?.depositPrice,
        0,
    );


    self.setState({
        totalTime:self.props.userBookingState?.totalTime,
        selectedService:self.props.userBookingState?.selectedServices,
        totalDeposit:totalDeposit?totalDeposit:0,
        loader:true,
    })
}

  let today = new Date();
  let date = moment(today).format();

    let params = queryString.parse(self.props.history.location.search);
    console.log("params",params);
    let bookingId=params?.bid;
    if(bookingId&&bookingId!=="") {
        bookingService.removeBooking({bookingId})
        .then(function (response) {
            self.selectDay([date]);
        })
    }else{
        self.selectDay([date]);
    }


  let _weekWrapper = document.querySelector('.rwdpDayBoxDesktop.rwdp-flex-box.rwdp-flex-row.rwdp-justify-content-space-between');
  let prevScrollLeft = 0;


  _weekWrapper.addEventListener('scroll',(e)=>{
    this.weekDraggable(_weekWrapper,prevScrollLeft,e)
  });
  

  document.querySelector('.react-datepicker-wrapper').addEventListener('click',()=>{
    console.log('check dateffff')
    self.setState({isCalendarOpen:!self.state.isCalendarOpen});
   
  })

 
  

}





handleModification(){
  // Your modification logic here
  console.log('Calendar modified!');
};



 weekDraggable(_weekWrapper,prevScrollLeft,e){
   let _timeWrapper; 
   const datePicker = this.datePicker.current;
    const scrollPosition = _weekWrapper.scrollLeft;
    // console.log(scrollPosition, prevScrollLeft)
    if (scrollPosition > prevScrollLeft) {
      _timeWrapper = setTimeout(()=>{
        // console.log('Right scroll',datePicker,e);
        
      },500)
    
    } else if (scrollPosition < prevScrollLeft) {
      _timeWrapper = setTimeout(()=>{
        // console.log('Left scroll',datePicker,e);
      },500)
     
    }
    prevScrollLeft = scrollPosition;
    return ()=>{
      clearTimeout(_timeWrapper);
    }
 }

 bookingDateCreate=()=>{
     let self = this;
     if (
         this.props.hairdresser.rotaInfo &&
         this.props.hairdresser.rotaInfo.rota.schedule[0] &&
         this.props.hairdresser.rotaInfo.rota.schedule[0].profiles
     ) {
         let workingRota =this.props.hairdresser.rotaInfo.rota.schedule[0].profiles;
         //console.log("#####bookingSlotCreate",workingRota);
         let unavailablesDays = [];
         let workingDays=[];

         let nextWorking;
         let upcomingWorkingRota=self.state.upcomingWorkingRota;
         _.map(weekDays, function (days, k) {
             let isCheck = _.find(workingRota, function (o) {
                 return days === o.day;
             });
             // let isCheck1 = _.find(workingRota, function (o) {
             //     return days === o.day;
             // });

             let workingDate = _.find(upcomingWorkingRota, function (obj) {
                 return moment(obj.startDate).format("ddd").toLowerCase()===days && obj.isRemoved===false;
             });

             if (!isCheck) {
                 if(!workingDate){
                     unavailablesDays.push(k);
                 }
             }else{
                 workingDays.push(k);
             }
             if (moment(self.state.selectedDay).format("d") >= k) {
                 nextWorking = moment(self.state.selectedDay)
                     .day("Sunday")
                     .add(k + 1, "days");
             }
         });

         let unavailablesDate=self.state.unavailablesDate;
         let exceptions=self.state.exceptions;

         let nonWorkingDays = new Set();
         let nonWorkingDates = new Set();
         let today = new Date();
         let nextYear = new Date();
         // nextYear.setFullYear(today.getFullYear() + 1);
         nextYear.setMonth(today. getMonth() + 3);

         while (today < nextYear) {
             let dateString = today.toISOString().split('T')[0];
             let dayOfWeek = today.getDay();

             if (exceptions.hasOwnProperty(dateString)) {
                 if (exceptions[dateString]) {
                     nonWorkingDays.add(dayOfWeek);
                     nonWorkingDates.add(dateString);
                 }
             } else if (!workingDays.includes(dayOfWeek)) {
                 nonWorkingDays.add(dayOfWeek);
                 nonWorkingDates.add(dateString);
             }

             today.setDate(today.getDate() + 1);
         }

         // console.log("nonWorkingDays",Array.from(nonWorkingDays),workingDays,unavailablesDays,unavailablesDate,nonWorkingDates)
         var merge = _.concat(unavailablesDate, Array.from(nonWorkingDates));
         this.setState({
             unavailablesDays: unavailablesDays,
             nextWorking: nextWorking,
             unavailablesDate:merge
         });
         //nextWorking
     }
 }


  bookingSlotCreate(index) {
    let self = this;
    //console.log("#####",this.props.hairdresser.rotaInfo, this.props.hairdresser.rotaInfo.rota.schedule[0], this.props.hairdresser.rotaInfo.rota.schedule[0].profiles)

    let finalBookingSlots = [];
    let pre;
    let next;
    let tempArr = [];
    let flag = false;
    let k = index;
    let arrslots = [];
    let bookingSlots = self.state.bookingSlots;

    //bookingSlots.map(function(slots, i) {
    for (let i = index; i < bookingSlots.length; i++) {
      // console.log(index,bookingSlots.length,i, self.state.totalTime, bookingSlots[i],bookingSlots[i].status);
      let check = false;
      if (bookingSlots[i].status === 0) {
        tempArr.push(bookingSlots[i]);
        // console.log('tempArr', tempArr);
        if (
          tempArr[tempArr.length - 1]["endTime"] - tempArr[0]["startTime"] >=
          self.state.totalTime
        ) {
          finalBookingSlots.push(tempArr[0]);

          for (let m = 0; m < bookingSlots.length; m++) {
            if (tempArr[0]["startTime"] === bookingSlots[m]["startTime"]) {
              k = m + 1;
              flag = true;
              break;
            }
          }
          tempArr = [];
        }
      } else {
        tempArr = [];
      }

      if (flag === true) {
        break;
      }
    }
    if (flag && k < bookingSlots.length) {
      self.bookingSlotCreate(k);
    }

    // console.log('check',bookingSlots);
    // console.log('finalBookingSlots', finalBookingSlots);

    if (finalBookingSlots[0]) {
      let finalslot = this.state.finalBookingSlots;
      finalslot.unshift(finalBookingSlots[0]);
      // console.log('finalslot', finalslot);
      let morningCount = 0;
      let afternoonCount = 0;
      let eveningCount = 0;
      for (let i = 0; i < finalslot.length; i++) {
        let bookingSlot = finalslot[i];
        if (bookingSlot) {
          if (bookingSlot.status === 0 && bookingSlot.startTime < 720) {
            morningCount = 1;
          }
          if (
            bookingSlot.status === 0 &&
            bookingSlot.startTime >= 720 &&
            bookingSlot.startTime < 1020
          ) {
            afternoonCount = 1;
          }
          if (bookingSlot.status === 0 && bookingSlot.startTime >= 1020) {
            eveningCount = 1;
          }
        }
      }


      this.setState({
        finalBookingSlots: finalslot,
        morningCount,
        afternoonCount,
        eveningCount,
        slotInfo: {},
        loader:false,
      });
    } else {
      this.setState({
        morningCount: 0,
        afternoonCount: 0,
        eveningCount: 0,
        loader:false,
      });
    }
  }
  
  getslotTime(time) {
    let hr = parseInt(time / 60);
    let min = time - hr * 60;
    return hr + ":" + (min === 0 ? "00" : min < 10 ? "0" + min : min);
  }

 selectDay(e) {
    console.log("selectDay", e);
    let params = {};
    let self = this;
    let sdate = new Date(e[0]);

    console.log("selectDay", moment(sdate).day(4,'d').startOf("week"));

  

    self.setState({
      loader:true,
      //weekStart: moment(sdate).day(3,'d').startOf("week"),
      selectedCalenderDate:new Date(e[0]),
      bookingDate:e[0],
      selectedDay:e[0],
      month: monthNames[sdate.getMonth()],
      year: sdate.getFullYear(),
      isCalendarOpen:false
    });
  
    let salonId = this.props.match.params.salonId;
    let hairdresserId = this.props.match.params.hairdresserId;
    params.hairdresserId = hairdresserId;
    params.latitude = this.props.latitude?this.props.latitude:siteSetting.default_latitude;
    params.longitude = this.props.longitude?this.props.longitude:siteSetting.default_longitude;

    params.slotsForBookingDate = dateFormat(e[0], "yyyy-mm-dd");
    self.props
      .dispatch(hairdresserActions.getHairdresserInfoWeb(params))
      .then(function (response) {
        if (response.data.error) {
          self.setState({
            message: response.data.error.message,
          });
        } else {
            let noShowCancelPolicy=response?.data?.data?.noShowCancelPolicy?response?.data?.data?.noShowCancelPolicy:{};
            let maxDate=self.state.maxDate;
            let daysCount=self.state.daysCount;
            if(noShowCancelPolicy&&noShowCancelPolicy.onlineBookingUpto){
                let today=moment();
                let maxDateLimit=moment().add(noShowCancelPolicy.onlineBookingUpto,'months');
                daysCount=maxDateLimit.diff(today, 'days');
                maxDate=new Date(moment().add(daysCount,'days'));
            }
            let upcomingWorkingRota=response?.data?.data?.upcomingWorkingRota;
            let exceptions={};
            upcomingWorkingRota.map((obj)=>{
                exceptions[obj.workingDate]=obj.isRemoved
            })
            // console.log("daysCount",daysCount);
            // console.log("maxDate",maxDate);
          self.setState(
            {
              bookingSlots: response.data.data.bookingSlots,
              selectedBookingSlotsIndex: "",
              finalBookingSlots: [],
              isBookingButton: false,
                hairdresserDetails: response.data.data,
                activeServiceCategory: response.data.data.hairdresserOnlineServices[0]?.categoryName,
                salonOwnerInfo:response?.data?.data?.salonOwnerInfo?response?.data?.data?.salonOwnerInfo:{},
                noShowCancelPolicy:response?.data?.data?.noShowCancelPolicy?response?.data?.data?.noShowCancelPolicy:{},
                maxDate,
                daysCount:daysCount>0?daysCount:self.state.daysCount,
                upcomingWorkingRota,
                exceptions
            },
            function () {
                self.bookingDateCreate();
              self.bookingSlotCreate(0);
            }
          );
        }
      });
  }

  selectSlot(slotInfo, index, isSelect) {
    let self = this;
    let startTime = this.convertTo12HourFormat(this.getslotTime(slotInfo.startTime));

    // self.setState({
    //     startTime: startTime,
    //   });
    this.setState(
      {
        finalBookingSlots: [],
      },
      function () {
        self.bookingSlotCreate(0);

        let finalBookingSlots = self.state.finalBookingSlots;
        let totalTime = self.state.totalTime;
        // console.log('check finalBookingSlots',finalBookingSlots)
        
        if (isSelect === 1) {
          let start = index + 1;
          // let res = _.remove(finalBookingSlots, function (o) {
          //   return (
          //     o.startTime < slotInfo.startTime + totalTime && o.startTime > slotInfo.startTime
          //   );
          // });
         
     
          let objectData = JSON.stringify(slotInfo);
          let selectedSlotInfo =JSON.parse(objectData)
        

          // console.log('check_time',this.convertTo12HourFormat(this.getslotTime(selectedSlotInfo.startTime)),this.convertTo12HourFormat(this.getslotTime(selectedSlotInfo.endTime)));
          //finalBookingSlots=res;
          //console.log('finalBookingSlots',finalBookingSlots);
          
        //   finalBookingSlots.splice(index+1, 0, {startTime: selectedSlotInfo.startTime+totalTime, endTime:selectedSlotInfo.endTime+totalTime, status: 0, list: Array({
        //     "startTime": selectedSlotInfo.startTime+totalTime,
        //     "endTime": selectedSlotInfo.endTime+totalTime,
        //     "type": "available",
        //     "info": {}
        // })});

          self.setState(
            {
              selectedBookingSlotsIndex: slotInfo.startTime,
              slotInfo: slotInfo,
              finalBookingSlots,
                startTime
            },
            function () {
          // console.log('slotInfo,index',slotInfo,index,isSelect,totalTime,finalBookingSlots);
          // return;
              if (
                self.state.selectedService.length > 0 &&
                self.state.slotInfo.startTime
              ) {
                // console.log(self.state.selectedService.length > 0 && self.state.slotInfo.startTime,'ssssssssssss')
                self.setState({
                  isBookingButton: true,
                });
              } else {
                self.setState({
                  isBookingButton: false,
                });
              }
            }
          );
        } else {
          self.setState(
            {
              selectedBookingSlotsIndex: "",
              slotInfo: {},
                startTime:null
            },
            function () {
   
             
              if (
                self.state.selectedService.length > 0 &&
                self.state.slotInfo.startTime
              ) {
                self.setState({
                  isBookingButton: true,
                });
              } else {
                self.setState({
                  isBookingButton: false,
                });
              }
            }
          );
        }
        self.setState({expanded: true})
      }
    );
  }


  handleScroll = () => {
    let self = this; 
    const scrollDiv = self.scrollDivRef.current;
    // alert('Page is scrolling');
    if(self.state.expanded==true){
      if (scrollDiv.scrollTop > 100) {
        console.log('let see when Page is scrolling');
        self.setState({expanded: false});
      }
    }
    
  };

  selectNextDate() {
    let sdate = [];
    sdate.push(this.state.nextWorking);
    this.selectDay(sdate);
  }

  checkSelectedBookingSlots(index) {
    if (this.state.selectedBookingSlotsIndex === index) {
      return true;
    } else {
      return false;
    }
  }

  handleItemClick = (category,categoryId) => {
    this.setState({ activeServiceCategory: category,dragableDisabled:true }); 
  };

  getHairdressersDetail(){
    let params = {};
    let self = this;
    let hairdresserId = this.props.match.params.hairdresserId;
          params.hairdresserId = hairdresserId;
          params.latitude =  siteSetting.default_latitude;
         params.longitude =  siteSetting.default_longitude;
          self.props.dispatch(hairdresserActions.getHairdresserInfoWeb(params))
              .then(function (response) {
                console.log('dddddd',response.data.data)
                  if (response.data.errorCode) {
                      self.setState({
                          error: response.data.error.messages
                      })
                  } else {
                      self.setState({
                        hairdresserDetails: response.data.data,
                        activeServiceCategory: response.data.data.hairdresserOnlineServices[0]?.categoryName,
                        salonOwnerInfo:response?.data?.data?.salonOwnerInfo?response?.data?.data?.salonOwnerInfo:{}
                      })
                  }
              });
  }


  createBooking= async() => {
    let self = this;
    let bookingId;
    const stripe = await stripePromise;
    self.setState({
        isbookingSubmit:true
    })
    // console.log('createBooking');
    // console.log(localStorage.getItem('accessToken'));
    if (localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== '') {
        //console.log(this.state.selectedService);
        self.setState({
            isFormLoader: true,
            isBookingButton:false,
        });

        if(this?.state?.slotInfo?.startTime===undefined){
          toast.error('Please select available slot.');
           self.setState({
             isFormLoader: false,
               isbookingSubmit:false
           });
          return; 
         }

        let params = {};
        params.servicesToBook = _.map(this.state.selectedService, function (o) {
            return {...o,id:o.serviceId};
        })
        params.userId = this.props.match.params.hairdresserId;
        params.bookingSlot = [this?.state?.slotInfo?.startTime, (this?.state?.slotInfo?.startTime + this?.state?.totalTime)]
        params.bookingDate = dateFormat(this.state.bookingDate, 'yyyy-mm-dd')
        params.deviceToken = "dfdsfdsfsd"
        params.isPayAtSalonBooking = 1;
        params.clientId = '';
        params.accessToken = self.props.user.tokenInfo?self.props.user.tokenInfo.accessToken:localStorage.getItem('accessToken');
        params.isOnlineBooking = 1;

        self.props.dispatch(bookingActions.createBooking(params))
            .then(async function (response) {
                self.setState({
                    isFormLoader: false,
                    isBookingButton:true,
                    isbookingSubmit:false
                });
                localStorage.removeItem('isBooking');
                localStorage.removeItem('redirectUrl');
                console.log('booking response', response);
                if (response.data.errorCode) {
                    self.setState({
                        error: response.data.messages
                    })
                    toast.error(response.data.messages);
                } else {
                  //check booking here booking cancellation here
                  params.isActiveIntend = 1;
                  params.bookingId=response?.data?.data?.bookingInfo?.id;
                  params.isDepositPay=response?.data?.data?.bookingInfo?.isDepositPay;
                  params.depositAmount=response?.data?.data?.bookingInfo?.depositAmount;
                  bookingId=response.data.data.bookingInfo.id;
                   // console.log("#########",self.props.hairdresser?.userInfo,self.props.hairdresser?.userInfo?.hairdresser?.noShowStatus);
                    let checkCancelPolicy=self.props.hairdresser?.userInfo?.hairdresser?.cancellationPolicy&&self.props.hairdresser?.userInfo?.hairdresser?.cancellationPolicy.length>0?true:false;
                    let noShowStatus =self.props.hairdresser?.userInfo?.hairdresser?.noShowStatus;

                    if(self.props.hairdresser?.userInfo?.hairdresser?.employmentTypeId===1&&self.props.hairdresser?.userInfo?.hairdresser?.isSalonOwner==0){
                      //emp case
                      checkCancelPolicy = self.state.salonOwnerInfo?.cancellationPolicy && self.state.salonOwnerInfo?.cancellationPolicy?.length>0?true:false;
                      noShowStatus = self.state.salonOwnerInfo?.noShowStatus && self.state.salonOwnerInfo?.noShowStatus;

                      console.log("owner data details",noShowStatus,checkCancelPolicy);
                    }

                    if(self.state.totalDeposit>0||noShowStatus===1||checkCancelPolicy){
                        let paymentParam={
                            bookingId: response.data.data.bookingInfo.id,
                        }
                        self.setState({
                            isFormLoader: true,
                            isBookingButton:false
                        });


                  
                        console.log("checkCancelPolicy 3",noShowStatus,checkCancelPolicy);

                        self.props.dispatch(paymentActions.bookingPayment(paymentParam))
                            .then(function (response) {
                                self.setState({
                                    isFormLoader: false,
                                    isBookingButton:true
                                });
                           
                                if (response.data.errorCode) {
                                    self.setState({
                                        error: response.data.messages
                                    })
                                    toast.error(response.data.messages);
                                } else {

                                  console.log('payment response', response);
                                    let transactionId=response.data.responseData.result.transactionId;
                                    localStorage.setItem('bookingId',bookingId);
                                    localStorage.setItem('transactionId',transactionId);
                                    let sessionId=response.data.responseData.result.id;
                                    stripe.redirectToCheckout({
                                        sessionId: sessionId,
                                    }).then(function (res){
                                        console.log("stripe res=",res);
                                        self.setState({
                                            paymentType:"Paid Online"
                                        })
                                        let getparams = {
                                            bookingId: bookingId,
                                            getBookingsOfType: 'upcoming',
                                            deviceToken: 'sfsadfsdfds',
                                            error: ''
                                        }
                                        // self.props.dispatch(bookingActions.getBooking(getparams))
                                        //     .then(function (response) {
                                        //     })
                                        //self.toggleBookingConf();
                                    }).catch(function (error){
                                      toast.error(error.messages);
                                        console.log("stripe error=",error);
                                    })
                                }
                            })
                    }else{
                      localStorage.setItem('bookingId',bookingId);
                      //window.location.assign(`/card-setup/${self.props.match.params.salonId}/professional/${self.props.match.params.hairdresserId}/card`)
                       window.location.assign(`/salon/${self.props.match.params.salonId}/professional/${self.props.match.params.hairdresserId}/success?bid=`+bookingId);
                    }
                }
            })
    } else {
        localStorage.setItem('isBooking', 1);
        localStorage.setItem('redirectUrl', `/salon/${self.props.match.params.salonId}/${self.props.match.params.hairdresserId}/booking-slot`);
        self.props.dispatch({ type: bookingConstants.IS_BOOKING, value: 1});
        // self.props.dispatch({ type: settingConstants.TOGGLE_LOGIN});
        self.props.dispatch({ type: settingConstants.TOGGLE_SOCIAL_LOGIN});
    }
}
  
toggleBookingConf() {
  localStorage.removeItem('isBooking');
  localStorage.removeItem('redirectUrl');
  // localStorage.removeItem('bookingId');
  let self = this;
  self.setState({
      date: new Date(),
      selectedService: [],
      isBookingButton: false,
      selectedDay: new Date(),
      bookingDate: null,
      month: monthNames[new Date().getMonth()],
      year: new Date().getFullYear(),
      totalAmount: 0,
      totalTime: 0,
      totalDeposit:0,
      selectedBookingSlots: '',
      selectedBookingSlotsIndex: '',
      finalBookingSlots: []
  })
  let params = {}
  this.props.dispatch({ type: settingConstants.TOGGLE_CONF_BOOKING });
  let hairdresserId = this.props.match.params.hairdresserId;
  params.hairdresserId = hairdresserId;
  params.latitude = this.props.latitude
  params.longitude = this.props.longitude
  self.props.dispatch(hairdresserActions.getHairdresserInfoWeb(params))
      .then(function (response) {

          if (response.data.errorCode) {
              self.setState({
                  error: response.data.error.messages
              })
          } else {
              self.setState({
                  bookingSlots: response.data.data.bookingSlots,
                  date: new Date(),
                  selectedService: [],
                  isBookingButton: false,
                  selectedDay: new Date(),
                  bookingDate: null,
                  month: monthNames[new Date().getMonth()],
                  year: new Date().getFullYear(),
                  totalAmount: 0,
                  totalTime: 0,
                  totalDeposit:0,
                  selectedBookingSlots: '',
                  selectedBookingSlotsIndex: '',
                  finalBookingSlots: []
              })
          }
      });
}


 convertTo12HourFormat(time24) {
  // Split the time string into hours and minutes
  const [hours, minutes] = time24.split(":");
  // Convert hours to a number
  const hoursNum = parseInt(hours, 10);

  // Determine whether it's AM or PM
  const period = hoursNum >= 12 ? "pm" : "am";

  // Convert to 12-hour format
  const hours12 = hoursNum % 12 || 12; // Handle midnight (0) as 12

  // Create the 12-hour time string
  const time12 = `${hours12}:${minutes} ${period}`;

  return time12;
}


PrevClick(e) {
  //console.log('PrevClick',e);
  let date = new Date(e._d);
  this.setState({
      weekStart:new Date(e._d),
      month: monthNames[date.getMonth()],
      year: date.getFullYear()
  }, function () {
      //console.log('@@@@@@@@@@@@@');
  })
}

NextClick(e) {
  //console.log('NextClick',e);
  let date = new Date(e._d)
  this.setState({
      weekStart:new Date(e._d),
      month: monthNames[date.getMonth()],
      year: date.getFullYear()
  }, function () {
  })
}

toggleRightPannel =()=>{
  this.setState({showMobileRightPannel:!this.state.showMobileRightPannel})
}
_toggle() {
  this.setState({expanded: !this.state.expanded});
}

    bookingStatusChange=(status)=>{
        this.setState({
            isbookingSubmit:status
        })
    }


     isWeekday = (date) => {
        let self=this;
        const day = date.getDay();
        let unavailablesDays=self.state.unavailablesDays;
        return unavailablesDays.indexOf(day) === -1;
    };

  datepickerSelect=(date)=>{
      let _weekWrapper = document.querySelector('.rwdpDayBoxDesktop.rwdp-flex-box.rwdp-flex-row.rwdp-justify-content-space-between');
      let prevScrollLeft = 0;


      let datediff=moment(date).diff(moment(), 'days');
      console.log("datediff",datediff);
      if(isMobile){
          _weekWrapper.scrollLeft=datediff*70;
      }else{
          _weekWrapper.scrollLeft=datediff*125;
      }

      this.setState({
          selectedCalenderDate:date,
      })
      this.selectDay([`${moment(date).format('YYYY-MM-DDTHH:mm:ssZ')}`]);
  }

  render() {
    let{expanded} =this.state;
    console.log("this.props",this.props);
      console.log("this.state",this.state);
    var date = new Date().getDate(); //Current Date
    let salonId = this.props.match.params.salonId;
    let hairdresserId = this.props.match.params.hairdresserId;
    let classNamesObj={
        // container : 'red',
        // prevWeekArrow: 'red',
        // nextWeekArrow: 'red',
        // dayBox: 'gray-holiday',
        // dayCircleContainer: 'gray-holiday',
        // dayCicle: 'gray-holiday',
        // dayCircleTodayText: 'rwdp-DayCircle rwdp-flex-box rwdp-flex-column rwdp-justify-content-center rwdp-align-items-center pink',
        // dayCircleUnavailable: 'rwdp-DayCircle rwdp-flex-box rwdp-flex-column rwdp-justify-content-center rwdp-align-items-center Disabled',
        dayCircleUnavailable:'rwdp-DayCircle-container rwdp-flex-box rwdp-flex-column rwdp-justify-content-center rwdp-align-items-center Disabled'
        //dayCircleUnavailableText: 'gray-holiday',
        // dayCicleSelected: 'rwdp-DayCircle rwdp-flex-box rwdp-flex-column rwdp-justify-content-center rwdp-align-items-center darkblue',
    }


    return (
      <div className="home_main">
        <div className="__left p-4">
          <ul className="__left_breadcrumb mb-5">
         <li className="arrow_li" onClick={()=>{
            this.props.history.push({
              pathname:`/salon/${this.props.match.params.salonId}/${hairdresserId}/service`,
              state:{
                isBackArrow:this?.props?.location?.state?.isBackArrow,
                marketPlaceUrl: this?.props?.location?.state?.marketPlaceUrl,
                prevUrl: this?.props?.location?.state?.prevUrl,
                prevUserId:this?.props?.location?.state?.prevUserId,
                salonId: this?.props?.location?.state?.salonId
              }
          });
          }}>
            <BackArrow/>
          {/* <img alt="img" src={require('../../../assets/images/down-arrow-icon1.png')} className="img-fluid mb-3 client-booking-back-arrow" /> */}
          </li>
            <li>
              <Link to={{
                pathname:`/salon/${this.props.match.params.salonId}/staff`,
                state:{
                  isBackArrow:this?.props?.location?.state?.isBackArrow,
                  marketPlaceUrl: this?.props?.location?.state?.marketPlaceUrl,
                  prevUrl: this?.props?.location?.state?.prevUrl,
                  prevUserId:this?.props?.location?.state?.prevUserId,
                  salonId: this?.props?.location?.state?.salonId
                }
            }}>Staff</Link>
            </li>
            <li >
              <Link to={{
                pathname:`/salon/${this.props.match.params.salonId}/${this.props.match.params.hairdresserId}/service`,
                state:{
                  isBackArrow:this?.props?.location?.state?.isBackArrow,
                  marketPlaceUrl: this?.props?.location?.state?.marketPlaceUrl,
                  prevUrl: this?.props?.location?.state?.prevUrl,
                  prevUserId:this?.props?.location?.state?.prevUserId,
                  salonId: this?.props?.location?.state?.salonId
                }
            }}
              >
                Services
              </Link>
            </li>
            <li>Slots</li>
          </ul>
          <div className="_bokking_solt new_timeslot"
          ref={this.scrollDivRef}
          onScroll={this.handleScroll}>

          <div className="parent__calender">
            <h3 className={this.state.isCalendarOpen?'__newcalender _calender_open':'__newcalender'}  >
              {moment(this.state.selectedCalenderDate).format('MMMM')}  {moment(this.state.selectedCalenderDate).format('YYYY')} 
              <span className="icon"><BackArrow/></span>
            </h3> 
          <DatePicker 
            selected={this.state.selectedCalenderDate} 
            className="hiden_picker "
            open={this.state.isCalendarOpen}
              onChange={(date) =>this.datepickerSelect(date)}
            minDate={new Date()}
            maxDate={new Date(this.state.maxDate)}
            filterDate={this.isWeekday}
            />
          </div>
            <ReactWeeklyDayPicker
              daysCount={this.state.daysCount+1}
              ref={this.datePicker}
              mode="single"
              onPrevClick="none"
              classNames={`new_timeslot ${classNamesObj}}`}
              // className={classNamesObj}
              multipleDaySelect={false}
              //startDay={this.state.weekStart} // First day as Date Object or 22 June 2016
              startDay={new Date()}
              selectedDays={[this.state.selectedDay]}
              selectDay={this.selectDay.bind(this)}
              todayText={date}
              //unselectDay={this.unselectDay.bind(this)}
              //format={'YYYY-MM-DD'} //format of dates that handled in selectDay and unselectDay functions
              firstLineFormat={"ddd"} // format for the first line of the day button
              secondLineFormat={"D"} // format for the second line of the day button
              //firstLineMobileFormat={'dddd'} // format for the first line of the day button mobile
              //secondLineMobileFormat={'MMMM D, Y'} // format for the second line
              // onPrevClick={this.PrevClick.bind(this)} // called with the new startDay
              // onNextClick={this.NextClick.bind(this)} // called with the new startDay
              unavailables={{
                weekly: this.state.unavailablesDays,
                dates:this.state.unavailablesDate,  //unavailable dates list
                // relative:[0,1],  //unavailable dates list relative to today (0:today, 1:tomorrow, -1:yesterday)
              }}
            />
            {/* --- staff not available then open this --- */}
           
           
            {this?.state?.loader?<SlotLoader/>:
            this.state.finalBookingSlots &&
            this.state.finalBookingSlots?.length > 0 ? (
              <div className="__booking_slot_time mt-4 pb-5">
                <div className="_time_slot">
                  {this.state.morningCount ? <h4>Morning</h4> : ""}
                  <ul className="slot_parent">
                    {this.state.finalBookingSlots
                      ? this.state.finalBookingSlots.map((bookingSlot, i) =>
                          bookingSlot ? (
                            bookingSlot.status === 0 ? (
                              bookingSlot.startTime < 720 ? (
                                <li
                                  key={i}
                                  className={
                                    this.checkSelectedBookingSlots(bookingSlot.startTime)
                                      ? "selected"
                                      : ""
                                  }
                                  onClick={this.selectSlot.bind(
                                    this,
                                    bookingSlot,
                                    i,
                                    this.checkSelectedBookingSlots(bookingSlot.startTime) ? 2 : 1
                                  )}
                                >
                                  {this.convertTo12HourFormat(this.getslotTime(bookingSlot.startTime))}
                                </li>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )
                        )
                      : ""}
                  </ul>
                </div>
                <div className="_time_slot">
                  {this.state.afternoonCount ? <h4>Afternoon</h4> : ""}
                  <ul className="slot_parent">
                    {this.state.finalBookingSlots
                      ? this.state.finalBookingSlots.map((bookingSlot, i) =>
                          bookingSlot ? (
                            bookingSlot.status === 0 ? (
                              bookingSlot.startTime >= 720 &&
                              bookingSlot.startTime < 1020 ? (
                                <li
                                  key={i}
                                  className={
                                    this.checkSelectedBookingSlots(bookingSlot.startTime)
                                      ? "selected"
                                      : ""
                                  }
                                  onClick={this.selectSlot.bind(
                                    this,
                                    bookingSlot,
                                    i,
                                    this.checkSelectedBookingSlots(bookingSlot.startTime) ? 2 : 1
                                  )}
                                >
                                  {this.convertTo12HourFormat(this.getslotTime(bookingSlot.startTime))}
                                </li>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )
                        )
                      : ""}
                    {/* <li className='selected'>8:00am</li> */}
                  </ul>
                </div>
                <div className="_time_slot">
                  {this.state.eveningCount ? <h4>Evening</h4> : ""}
                  <ul className="slot_parent">
                    {this.state.finalBookingSlots
                      ? this.state.finalBookingSlots.map((bookingSlot, i) =>
                          bookingSlot ? (
                            bookingSlot.status === 0 ? (
                              bookingSlot.startTime >= 1020 ? (
                                <li
                                  key={i}
                                  className={
                                    this.checkSelectedBookingSlots(bookingSlot.startTime)
                                      ? "selected"
                                      : ""
                                  }
                                  onClick={this.selectSlot.bind(
                                    this,
                                    bookingSlot,
                                    i,
                                    this.checkSelectedBookingSlots(bookingSlot.startTime) ? 2 : 1
                                  )}
                                >
                                  {this.convertTo12HourFormat(this.getslotTime(bookingSlot.startTime))}
                                </li>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )
                        )
                      : ""}
                  </ul>
                </div>
              </div>
            ) : (
              <div className="_not_available">
                <p>
                  <span>
                    <NotAvailableCalenderICon />
                  </span>
                </p>
                <h6 className="mt-4">
                  {this.props.hairdresser && this.props.hairdresser.userInfo
                    ? this.props.hairdresser.userInfo.name
                    : ""}{" "}
                  is not available on this day
                </h6>
                <p className="mb-4">
                  but you can book{" "}
                  {moment(this.state.nextWorking).format("dddd") +
                    " " +
                    moment(this.state.nextWorking).format("DD MMM")}
                </p>
                <button
                  className={`btn __black_btn pl-3 pr-3`}
                  onClick={this.selectNextDate.bind(this)}
                >
                  View{" "}
                  {moment(this.state.nextWorking)
                    .format("dddd")
                    .substring(0, 3) +
                    " " +
                    moment(this.state.nextWorking).format("DD MMM")}
                  {/* View Wed 24 Oct */}
                </button>
              </div>
            )}
          </div>
        </div>
        {Object.keys(this?.state?.hairdresserDetails).length>0?<>
          <>
        {this.state?.selectedService?.length>0 ?
        <>

        <div className='phone_right_panel_parent hide-desktop'>
          <div className={`invisible_button ${expanded ? 'Hide _full_height_transparent' : 'Show'}`} 
          onClick={() => this._toggle()} ></div>
          {/* <p className='mb-0'> </p> */}
          <img src={require('../../../assets/images/down-arrow-icon1.png')} className={`icon_arrow ${expanded?'':'closed'}`}/>
          <SmoothCollapse 
          expanded={expanded} 
          // className={`smooth_scroll_body ${expanded ? 'hi':'height_70'}`}
          heightTransition='.8s ease-in-out'
          collapsedHeight = {this.state.selectedService.length>0?'58px':'0px'}
          closing = {false}
          eagerRender = {false}
          > 
            <Summary 
                  {...this?.props?.userBookingState}
                    {...this.props}
                    page="booking-slots"
                    createBooking={this.createBooking}
                    totalDeposit={this.state.totalDeposit}
                    bookingDate={this.state.bookingDate}
                    startTime={this.state.startTime}
                    isLoading={this?.state?.isFormLoader}
                    getCancelPolicy={this.getCancelPolicy}
                    bookingState={this.state}
                    />
          </SmoothCollapse>
        </div>
       </>
         :''
        }
        </>
          <div className='__right  align-items-start _service __mobile  hide-mobile'>
          <Summary
          {...this?.props?.userBookingState}
            {...this.props}
            page="booking-slots"
            createBooking={this?.createBooking}
            totalDeposit={this?.state?.totalDeposit}
            bookingDate={this?.state?.bookingDate}
            startTime={this?.state?.startTime}
            isLoading={this?.state?.isFormLoader}
            isbookingSubmit={this.state?.isbookingSubmit}
            getCancelPolicy={this.getCancelPolicy}
            bookingState={this.state}
            />

          </div>
          </>:null}

    <Modal show={this.state.bookingConf}   blurClass="blure_bg" centered dialogClassName="hide_gray_content_bg">
    {this.props.booking.bookings?
        <ConfirmBookingPopup bookingState={this.state} toggleBookingConf={this.toggleBookingConf}/>
        :""}
    </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    hairdresser,
    salon,
    hairdresserReview,
    user,
    setting,
    booking,
    createBooking,
      userBookingState,
      salonOwnerDataInfo
  } = state;
  return {
    hairdresser,
    salon,
    hairdresserReview,
    user,
    setting,
    booking,
    createBooking,
      userBookingState,
      salonOwnerDataInfo
  };
}

export default connect(mapStateToProps)(BookingSlots);
