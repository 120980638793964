export default {
  api: {
    url: 'https://api.beublog.com/api/',
    mode: 'cors',
    backend:'https://api.beublog.com/',
    weburl:'https://app.beuapp.com/',
    v2: 'https://apiv2.beublog.com/api/v2/',
    v2BaseUrl:'https://apiv2.beublog.com/'
  },
  socket: '',
  google_map_key:'AIzaSyAxx2cDzRRwbsY99ey2-G6gHVQpTwA4N_Y',
  apple_redirectURI:"https://api.beublog.com/api/user/apple-redirect",
  stripeKey:"pk_live_tFO0LmgMJXwskPv6nWloyPvS",
  stripe:{
    salonMonthlyPriceId:'price_1LqJqnJu70D4HRltB1RERj9s',
    salonYearlyPriceId:'price_1LqJppJu70D4HRltIogJBsqw',
    selfMonthlyPriceId:'price_1LqJtKJu70D4HRltrz3kIB6T',
    selfYearlyPriceId:'price_1LqJsRJu70D4HRltWAjuVoZb',
    salonMonthlyPrice:59,
    salonYearlyPrice:599,
    selfMonthlyPrice:25,
    selfYearlyPrice:249,
    salonSave:'Save  £109',
    selfempSave:'Save £51',
    oneSelfEmpPrice:5
  },
  simulated:false,
  noClientDAta : {
    "_id" : "64f5c7789a069cda0188fea3",
    "userImage" : {
        "rectangleImage" : "",
        "circleImage" : ""
    },
    "facebookId" : 0,
    "phoneNumber" :"44325325345325",
    "genderId" : 0,
    "latitude" : 28.640004,
    "longitude" : 77.376707,
    "localAvatar" : "",
    "socialAvatar" : "",
    "useAvatarType" : 0,
    "deviceTypeId" : 2,
    "deviceToken" : "",
    "defaultRedirectTo" : 0,
    "isRemoved" : false,
    "password" : "$2a$10$64EZXccFWloQrqpmKOnTCeD8.oVdt8MogCVPSJtNvgIRlUqIuDvvG",
    "stripeCustomer" : "",
    "stripeConnectedAccount" : "",
    "dob" : "",
    "status" : 1,
    "emailNotification" : true,
    "smsNotification" : true,
    "email" : "64f5c7789a069cda0188fea3@beuapp.com",
    "first_name" : "Walk",
    "last_name" : "In",
    "name" : "Walk In",
    "createdTime" : "2023-09-04T17:33:04.686+05:30",
    "updatedTime" : "2023-09-04T17:34:07.885+05:30",
    "__v" : 0
}
}
