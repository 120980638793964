import React, { useState, useEffect, useRef } from "react";
import TopHeader from "../template/TopHeader";
import SideNav from "../template/SideNav";
import { useLocation } from 'react-router-dom';
import siteSetting from "../../config/env";

function EmailPreview(props) {
    const [preview, setPreview] = useState({});
    const location = useLocation();
    const [emailPreviews, setEmailPreviews] = useState([
        { type: 'Reminder', email: siteSetting.api.v2BaseUrl +"/email/Email%20Reminder.html" },
        { type: 'Update Booking', email: siteSetting.api.v2BaseUrl +"/email/Updated%20Booking%20email.html" },
        { type: 'Cancelled booking', email: siteSetting.api.v2BaseUrl +"/email/cancelled%20booking.html" },
        { type: 'Happy birthday', email: siteSetting.api.v2BaseUrl +"/email/happy%20birthday.html" },
        { type: 'New Booking', email: siteSetting.api.v2BaseUrl +"/email/new%20booking%20email.html" },
        { type: 'newOnlineBooking', email: siteSetting.api.v2BaseUrl +"/email/new%20online%20booking%20for%20SALON%20OWNER%20OR%20SE%20STAFF.html" },
        { type: 'No-show', email: siteSetting.api.v2BaseUrl +"/email/no%20show%20email.html" },
        { type: 'Reminder to rebook', email: siteSetting.api.v2BaseUrl +"/email/reminder%20to%20rebook.html" },
        { type: 'Thank you for tipping', email: siteSetting.api.v2BaseUrl +"/email/thank%20you%20for%20tipping.html" },
        { type: 'Thank you after visiting', email: siteSetting.api.v2BaseUrl +"/email/thank%20you%20for%20visiting.html" },
        { type: 'Google Review Booster', email: siteSetting.api.v2BaseUrl +"/email/Google%20Review%20Booster.html" },
    ]);

    const iframeRef = useRef(null);

    useEffect(() => {
        const state = location.state;
        if (state && state.type) {
            let emailType = state.type;
            let selectedTemplate = emailPreviews.find(emailTemplate => emailTemplate.type === emailType);
            if (selectedTemplate) {
                setPreview(selectedTemplate);
                // Set iframe URL based on the selected template
                iframeRef.current.src = selectedTemplate.email;
            } else {
                console.log('Template not found for type:', emailType);
            }
        } else {
            console.log('No type found in location state');
        }
    }, [location.state, emailPreviews]);

    return (
        <div className="h_n">
            <div className="body_container cm_bg_white" style={{ minHeight: 'auto' }}>
                <TopHeader
                    type='reminder'
                    headerButtonStatus={true}
                    cancelButton={true}
                    cancelButtonText={"Close"}
                    title={preview?.type ? "Preview " + preview?.type : "Preview"}
                    {...props}
                />
                <SideNav {...props} />
                <div className="" style={{ height: `calc(100vh - 140px)` }}>
                    <iframe
                        ref={iframeRef}
                        height={100}
                        id="gmap_canvas"
                        style={{ width: '100%', height: '100%', border: 'none' }}
                        src=""
                        frameBorder="0"
                    ></iframe>
                </div>
            </div>
        </div>
    )
}

export default EmailPreview;
