import promise from 'bluebird'

import {hairdresserConstants, alertConstants, bookingConstants, userConstants} from '../_constants';

import {bookingService, hairdresserService, userService,categoryService} from "../_services";

import { ResponseFilter } from "../config/response-handler";

export const hairdresserActions = {
    getHairdresserInfo,
    getHairdresserReview,
    getSalonProfile,
    getSalonList,
    getService,
    getHairdresserSchedule,
    getHairdresserScheduleReset,
    getHairdresserClient,
    hairdresserSwitchProfile,
    getHairdresserClientDetail,
    createClient,
    hairdresserStaff,
    addStaff,
    editStaff,
    signup,
    updateHairdresser,
    getAllCategory,
    getHairdresserService,
    hairdresserStaffService,
    hairdresserStaffServiceDetail,
    hairdresserStaffRota,
    editClient,
    removeStaff,
    deleteClient,
    checkEmailAvailability,
    serviceHairdresserList,
    getLastBookedClients,
    updateHairdresserSchedule,
    dairyFilterData,
    dairyFilterStaffOrderList,
    hairdresserStaffWithoutService,
    staffRotaUpdateDelete,
    activeFilterStatus,
    selectedService,
    getHairdresserInfoWeb,
    salonOwnerInfo,
    getHairdresserPolicies,
    saveWorkingRota
};

/************************************** getHairdresserInfo ******************************************************/


function getHairdresserInfo(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            hairdresserService.hairdresserProfile(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    //console.log("serverResponseData", serverResponseData);
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(getHairdresserInfoRequest) { return { type: hairdresserConstants.HAIRDRESSER_INFO_REQUEST, getHairdresserInfoRequest } }
    function success(getHairdresserInfoSuccess) { return { type: hairdresserConstants.HAIRDRESSER_INFO_SUCCESS, getHairdresserInfoSuccess } }
    function failure(getHairdresserInfoFail) { return { type: hairdresserConstants.HAIRDRESSER_INFO_ERROR, getHairdresserInfoFail } }
}

function getHairdresserInfoWeb(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            dispatch(request(params));
            hairdresserService.hairdresserProfileWeb(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    //console.log("serverResponseData", serverResponseData);
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(getHairdresserInfoRequest) { return { type: hairdresserConstants.HAIRDRESSER_INFO_REQUEST, getHairdresserInfoRequest } }
    function success(getHairdresserInfoSuccess) { return { type: hairdresserConstants.HAIRDRESSER_INFO_SUCCESS, getHairdresserInfoSuccess } }
    function failure(getHairdresserInfoFail) { return { type: hairdresserConstants.HAIRDRESSER_INFO_ERROR, getHairdresserInfoFail } }
}




function getHairdresserSchedule(params,hairdresser,i) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            dispatch(request(params));
            hairdresserService.hairdresserSchedule(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                   // console.log("serverResponseData", serverResponseData);
                    dispatch(success({hairdresser:hairdresser,calendar:serverResponseData.data,pos:i}));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(getHairdresserScheduleRequest) { return { type: hairdresserConstants.HAIRDRESSER_SCHEDULE_REQUEST, getHairdresserScheduleRequest } }
    function success(getHairdresserScheduleSuccess) { return { type: hairdresserConstants.HAIRDRESSER_SCHEDULE_SUCCESS, getHairdresserScheduleSuccess } }
    function failure(getHairdresserScheduleFail) { return { type: hairdresserConstants.HAIRDRESSER_SCHEDULE_ERROR, getHairdresserScheduleFail } }
}



function updateHairdresserSchedule(data) {
    return function (dispatch) {
            dispatch(success(data));
    };

    function success(getHairdresserScheduleSuccess) { return { type: hairdresserConstants.UPDATE_HAIRDRESSER_SCHEDULE, getHairdresserScheduleSuccess }}
}

function getHairdresserScheduleReset(params,hairdresser) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            dispatch(success({}));
        })
    };

    function success(getHairdresserScheduleReset) { return { type: hairdresserConstants.HAIRDRESSER_INFO_CLEAR } }

}



function getHairdresserReview(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            let apiParams = {};
            if (params.search !== '' && params.search !== "undefined") {
                apiParams.search = params.search
            }

            apiParams.hairdresserId = params.hairdresserId;

            //dispatch(request(apiParams));
            hairdresserService.hairdresserReview(apiParams)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    //console.log("hairdresserReview serverResponseData", serverResponseData);
                    dispatch(success(serverResponseData.data.reviews));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(getHairdresserReviewRequest) { return { type: hairdresserConstants.HAIRDRESSER_INFO_REQUEST, getHairdresserReviewRequest } }
    function success(getHairdresserReviewSuccess) { return { type: hairdresserConstants.HAIRDRESSER_REVIEW_SUCCESS, getHairdresserReviewSuccess } }
    function failure(getHairdresserReviewFail) { return { type: hairdresserConstants.HAIRDRESSER_INFO_ERROR, getHairdresserReviewFail } }
}


function getSalonProfile(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {
            let apiParams = {};

            apiParams.salonId = params.salonId;

            //dispatch(request(params));
            hairdresserService.salonProfile(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    //console.log("salonProfile serverResponseData", serverResponseData);
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(getgetSalonProfileRequest) { return { type: hairdresserConstants.SALON_PROFILE_REQUEST, getgetSalonProfileRequest } }
    function success(getSalonProfileSuccess) { return { type: hairdresserConstants.SALON_PROFILE_SUCCESS, getSalonProfileSuccess } }
    function failure(getgetSalonProfileFail) { return { type: hairdresserConstants.SALON_PROFILE_ERROR, getgetSalonProfileFail } }
}


function getSalonList(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            //dispatch(request(params));
            hairdresserService.salonList(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    //console.log("salonProfile serverResponseData", serverResponseData);
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(getgetSalonProfileRequest) { return { type: hairdresserConstants.SALON_PROFILE_REQUEST, getgetSalonProfileRequest } }
    function success(getSalonListSuccess) { return { type: hairdresserConstants.SALON_LIST_SUCCESS, getSalonListSuccess } }
    function failure(getgetSalonProfileFail) { return { type: hairdresserConstants.SALON_PROFILE_ERROR, getgetSalonProfileFail } }
}


function getService() {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            dispatch(request());
            hairdresserService.getService()
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    //console.log("salonProfile serverResponseData", serverResponseData);
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(getServiceRequest) { return { type: hairdresserConstants.SALON_SERVICE_REQUEST, getServiceRequest } }
    function success(getServiceSuccess) { return { type: hairdresserConstants.SALON_SERVICE_SUCCESS, getServiceSuccess } }
    function failure(getServiceFail) { return { type: hairdresserConstants.SALON_SERVICE_ERROR, getServiceFail } }
}


function getHairdresserClient(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            //dispatch(request(params));
            let requestParam={
                searchKey: params.searchKey,
                hairdresserId: params.hairdresserId,
                page: params.page,
                listType: params.listType,
                isDownload: params.isDownload,
                empIds:params?.empIds!=undefined?params?.empIds:'',
                startDate:params?.startDate!=undefined?params?.startDate:'',
                endDate:params?.endDate!=undefined?params?.endDate:'',
            }
            hairdresserService.getClient(requestParam)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    console.log('getHairdresserClient',response);
                    //console.log("getHairdresserClient serverResponseData", serverResponseData);
                    if(params?.isDownload===1 || params?.isDownload==='1'){
                        dispatch(exportCsv(serverResponseData.data));
                    }else{
                        dispatch(success(serverResponseData.data));
                    }
                  
                })
                .catch((err) => {
                    params.history.push('/');
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function exportCsv(getHairdresserClientSuccess) {return{type: hairdresserConstants.HAIRDRESSER_CLIENT_CSV_DOWNLOAD, getHairdresserClientSuccess}}
    function request(getHairdresserClientRequest) { return { type: hairdresserConstants.HAIRDRESSER_CLIENT_REQUEST, getHairdresserClientRequest } }
    function success(getHairdresserClientSuccess) { return { type: hairdresserConstants.HAIRDRESSER_CLIENT_SUCCESS, getHairdresserClientSuccess } }
    function failure(getHairdresserClientFail) { return { type: hairdresserConstants.HAIRDRESSER_CLIENT_ERROR, getHairdresserClientFail } }
}

function hairdresserSwitchProfile(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            dispatch(request(params));
            hairdresserService.switchProfile(params)
                .then(response => {
                    let { serverResponseData } = ResponseFilter(response);
                    dispatch(userSuccess(serverResponseData.data));
                    resolve(response);
                    dispatch(success(serverResponseData.data.reviews));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function userSuccess(profileSuccess) { return { type: userConstants.PROFILE_SUCCESS, profileSuccess } }
    function request(getHairdresserReviewRequest) { return { type: hairdresserConstants.HAIRDRESSER_SWITCH_REQUEST, getHairdresserReviewRequest } }
    function success(getHairdresserReviewSuccess) { return { type: hairdresserConstants.HAIRDRESSER_SWITCH_SUCCESS, getHairdresserReviewSuccess } }
    function failure(getHairdresserReviewFail) { return { type: hairdresserConstants.HAIRDRESSER_SWITCH_ERROR, getHairdresserReviewFail } }
}


function getHairdresserClientDetail(params) {
    return function (dispatch) {

        return new promise(function (resolve, reject) {

            dispatch(request(params));
            hairdresserService.getClientDetail(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    //console.log("getHairdresserClient serverResponseData", serverResponseData);
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(getHairdresserClientDetailRequest) { return { type: hairdresserConstants.HAIRDRESSER_CLIENTDETAIL_REQUEST, getHairdresserClientDetailRequest } }
    function success(getHairdresserClientDetailSuccess) { return { type: hairdresserConstants.HAIRDRESSER_CLIENTDETAIL_SUCCESS, getHairdresserClientDetailSuccess } }
    function failure(getHairdresserClientDetailFail) { return { type: hairdresserConstants.HAIRDRESSER_CLIENTDETAIL_ERROR, getHairdresserClientDetailFail } }
}

function createClient(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {


            //dispatch(request(params));
            hairdresserService.clientAdd(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    //console.log("serverResponseData", serverResponseData);
                    //dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    //dispatch(failure(err));

                })
        })
    };
    function request(createClientRequest) { return { type: bookingConstants.HAIRDRESSER_CLIENTCREATE_REQUEST, createClientRequest } }
    function success(createClientSuccess) { return { type: bookingConstants.HAIRDRESSER_CLIENTCREATE_SUCCESS, createClientSuccess } }
    function failure(createClientFail) { return { type: bookingConstants.HAIRDRESSER_CLIENTCREATE_ERROR, createClientFail } }
}

function hairdresserStaff(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            dispatch(request(params));
            // hairdresserService.staffList(params)
            hairdresserService.staffListV2(params)
                .then(response => {
                    resolve(response);

                    let { serverResponseData } = ResponseFilter(response);

                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(getHairdresserStaffRequest) { return { type: hairdresserConstants.HAIRDRESSER_STAFF_REQUEST, getHairdresserStaffRequest } }
    function success(getHairdresserStaffSuccess) { return { type: hairdresserConstants.HAIRDRESSER_STAFF_SUCCESS, getHairdresserStaffSuccess } }
    function failure(getHairdresserStaffFail) { return { type: hairdresserConstants.HAIRDRESSER_STAFF_ERROR, getHairdresserStaffFail } }
}

function addStaff(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            //dispatch(request(params));
            hairdresserService.addStaff(params)
                .then(response => {
                    resolve(response);

                    let { serverResponseData } = ResponseFilter(response);

                    //dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    //dispatch(failure(err));

                })
        })
    };
    // function request(getHairdresserStaffRequest) { return { type: hairdresserConstants.HAIRDRESSER_STAFF_REQUEST, getHairdresserStaffRequest } }
    // function success(getHairdresserStaffSuccess) { return { type: hairdresserConstants.HAIRDRESSER_STAFF_SUCCESS, getHairdresserStaffSuccess } }
    // function failure(getHairdresserStaffFail) { return { type: hairdresserConstants.HAIRDRESSER_STAFF_ERROR, getHairdresserStaffFail } }
}

function editStaff(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            //dispatch(request(params));
            hairdresserService.editStaff(params)
                .then(response => {
                    resolve(response);

                    let { serverResponseData } = ResponseFilter(response);

                    //dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    //dispatch(failure(err));

                })
        })
    };
    // function request(getHairdresserStaffRequest) { return { type: hairdresserConstants.HAIRDRESSER_STAFF_REQUEST, getHairdresserStaffRequest } }
    // function success(getHairdresserStaffSuccess) { return { type: hairdresserConstants.HAIRDRESSER_STAFF_SUCCESS, getHairdresserStaffSuccess } }
    // function failure(getHairdresserStaffFail) { return { type: hairdresserConstants.HAIRDRESSER_STAFF_ERROR, getHairdresserStaffFail } }
}

function signup(apiParams) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            // apiParams.latitude = "28.640004"
            // apiParams.longitude = "77.376707"

            dispatch(request(apiParams));
            hairdresserService.signup(apiParams)
                .then(response => {
                    // console.log('response',response);
                    let { serverResponseData } = ResponseFilter(response);
                    // console.log("serverResponseData",serverResponseData);
                    if (serverResponseData.data) {
                        if(response.data.data.userInfo&&(response.data.data.userInfo.hairdresser.isSalonOwner===1||response.data.data.userInfo.hairdresser.employmentTypeId===2)) {
                            let data = serverResponseData.data;
                            // console.log('data',data);
                            // console.log('data.tokenInfo.accessToken',data.tokenInfo.accessToken);
                            // console.log('data.tokenInfo.accessToken',data.tokenInfo.accessToken);
                            localStorage.setItem('accessToken', data.tokenInfo.accessToken);
                            localStorage.setItem('refreshToken', data.tokenInfo.refreshToken);
                            localStorage.setItem('expiresIn', data.tokenInfo.expiresIn);
                            localStorage.setItem('tokenType', data.tokenInfo.tokenType);
                            localStorage.setItem('userId', data.userInfo.userId);
                            if (data.userInfo.hairdresser && data.userInfo.hairdresser.salon) {
                                localStorage.setItem('salonId', data.userInfo.hairdresser.salon._id);
                                localStorage.setItem('isShow', data.userInfo.hairdresser.salon.isShow);
                            }
                            localStorage.setItem('defaultRedirectTo', data.userInfo.defaultRedirectTo);
                            dispatch(success(serverResponseData.data));
                        }
                    }
                    resolve(response);
                })
                .catch((err) => {
                    dispatch(failure(err));
                    reject(err);
                })
        })
    };
    function request(signupRequest) { return { type: userConstants.SIGNUP_REQUEST, signupRequest } }
    function success(signupSuccess) { return { type: userConstants.SIGNUP_SUCCESS, signupSuccess } }
    function failure(signupFail) { return { type: userConstants.SIGNUP_ERROR, signupFail } }
}

function updateHairdresser(apiParams,hairdresserId) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            dispatch(request(apiParams));
            hairdresserService.updateHairdresser(apiParams,hairdresserId)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(updateRequest) { return { type: hairdresserConstants.HAIRDRESSER_PROFILE_REQUEST, updateRequest } }
    function success(updateSuccess) { return { type: hairdresserConstants.HAIRDRESSER_PROFILE_SUCCESS, updateSuccess } }
    function failure(updateFail) { return { type: hairdresserConstants.HAIRDRESSER_PROFILE_ERROR, updateFail } }
}

function getAllCategory(apiParams) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            //dispatch(request(apiParams));
            categoryService.getAllServices(apiParams)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(categoryRequest) { return { type: hairdresserConstants.CATEGORY_REQUEST, categoryRequest } }
    function success(getCategorySuccess) { return { type: hairdresserConstants.CATEGORY_SUCCESS, getCategorySuccess } }
    function failure(categoryFail) { return { type: hairdresserConstants.CATEGORY_ERROR, categoryFail } }
}

function getHairdresserService(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            dispatch(request(params));
            hairdresserService.hairdresserUserService(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    //console.log("serverResponseData", serverResponseData);
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(getHairdresserServiceRequest) { return { type: hairdresserConstants.HAIRDRESSER_SERVICE_REQUEST, getHairdresserServiceRequest } }
    function success(getHairdresserServiceSuccess) { return { type: hairdresserConstants.HAIRDRESSER_SERVICE_SUCCESS, getHairdresserServiceSuccess } }
    function failure(getHairdresserServiceFail) { return { type: hairdresserConstants.HAIRDRESSER_SERVICE_ERROR, getHairdresserServiceFail } }
}

function hairdresserStaffService(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            dispatch(request(params));
            // hairdresserService.staffListService(params)
            hairdresserService.staffListServiceV2(params)
                .then(response => {
                    resolve(response);

                    let { serverResponseData } = ResponseFilter(response);

                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(getHairdresserStaffServiceRequest) { return { type: hairdresserConstants.HAIRDRESSER_STAFFSERVICE_REQUEST, getHairdresserStaffServiceRequest } }
    function success(getHairdresserStaffServiceSuccess) { return { type: hairdresserConstants.HAIRDRESSER_STAFFSERVICE_SUCCESS, getHairdresserStaffServiceSuccess } }
    function failure(getHairdresserStaffServiceFail) { return { type: hairdresserConstants.HAIRDRESSER_STAFFSERVICE_ERROR, getHairdresserStaffServiceFail } }
}

function hairdresserStaffWithoutService(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            dispatch(request(params));
            hairdresserService.staffListServiceV2WithoutService(params)
                .then(response => {
                    resolve(response);

                    let { serverResponseData } = ResponseFilter(response);

                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(getHairdresserStaffServiceRequest) { return { type: hairdresserConstants.HAIRDRESSER_STAFFSERVICE_REQUEST, getHairdresserStaffServiceRequest } }
    function success(getHairdresserStaffServiceSuccess) { return { type: hairdresserConstants.HAIRDRESSER_STAFFSERVICE_SUCCESS, getHairdresserStaffServiceSuccess } }
    function failure(getHairdresserStaffServiceFail) { return { type: hairdresserConstants.HAIRDRESSER_STAFFSERVICE_ERROR, getHairdresserStaffServiceFail } }
}

function hairdresserStaffServiceDetail(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            //dispatch(request(params));
            hairdresserService.staffServiceDetail(params)
                .then(response => {
                    resolve(response);

                    let { serverResponseData } = ResponseFilter(response);

                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(getHairdresserServiceDetailRequest) { return { type: hairdresserConstants.HAIRDRESSER_SERVICE_DETAIL_REQUEST, getHairdresserServiceDetailRequest } }
    function success(getHairdresserServiceDetailSuccess) { return { type: hairdresserConstants.HAIRDRESSER_SERVICE_DETAIL_SUCCESS, getHairdresserServiceDetailSuccess } }
    function failure(getHairdresserServiceDetailFail) { return { type: hairdresserConstants.HAIRDRESSER_SERVICE_DETAIL_ERROR, getHairdresserServiceDetailFail } }
}

function hairdresserStaffRota(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            //dispatch(request(params));
            //hairdresserService.staffRota(params)
            hairdresserService.staffRotaV2(params)
                .then(response => {
                    // resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    resolve({status:200,data:{data:{rota:serverResponseData?.responseData?.result}}});

                    // dispatch(success(serverResponseData.data));
                    dispatch(success({rota:serverResponseData?.responseData?.result}));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(hairdresserStaffRotaRequest) { return { type: hairdresserConstants.HAIRDRESSER_STAFF_ROTA_REQUEST, hairdresserStaffRotaRequest } }
    function success(hairdresserStaffRotaSuccess) { return { type: hairdresserConstants.HAIRDRESSER_STAFF_ROTA_SUCCESS, hairdresserStaffRotaSuccess } }
    function failure(hairdresserStaffRotaFail) { return { type: hairdresserConstants.HAIRDRESSER_STAFF_ROTA_ERROR, hairdresserStaffRotaFail } }
}

function staffRotaUpdateDelete(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            dispatch(success(params));

        })
    };
    function request(hairdresserStaffRotaRequest) { return { type: hairdresserConstants.HAIRDRESSER_STAFF_ROTA_REQUEST, hairdresserStaffRotaRequest } }
    function success(hairdresserStaffRotaSuccess) { return { type: hairdresserConstants.HAIRDRESSER_STAFF_ROTA_SUCCESS, hairdresserStaffRotaSuccess } }
    function failure(hairdresserStaffRotaFail) { return { type: hairdresserConstants.HAIRDRESSER_STAFF_ROTA_ERROR, hairdresserStaffRotaFail } }
}

function editClient(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {


            //dispatch(request(params));
            hairdresserService.editClient(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    //console.log("serverResponseData", serverResponseData);
                    //dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    //dispatch(failure(err));

                })
        })
    };
    function request(createClientRequest) { return { type: bookingConstants.HAIRDRESSER_CLIENTCREATE_REQUEST, createClientRequest } }
    function success(createClientSuccess) { return { type: bookingConstants.HAIRDRESSER_CLIENTCREATE_SUCCESS, createClientSuccess } }
    function failure(createClientFail) { return { type: bookingConstants.HAIRDRESSER_CLIENTCREATE_ERROR, createClientFail } }
}

function removeStaff(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            //dispatch(request(params));
            hairdresserService.removeStaff(params)
                .then(response => {
                    resolve(response);

                    let { serverResponseData } = ResponseFilter(response);

                    //dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    //dispatch(failure(err));

                })
        })
    };
    // function request(getHairdresserStaffRequest) { return { type: hairdresserConstants.HAIRDRESSER_STAFF_REQUEST, getHairdresserStaffRequest } }
    // function success(getHairdresserStaffSuccess) { return { type: hairdresserConstants.HAIRDRESSER_STAFF_SUCCESS, getHairdresserStaffSuccess } }
    // function failure(getHairdresserStaffFail) { return { type: hairdresserConstants.HAIRDRESSER_STAFF_ERROR, getHairdresserStaffFail } }
}

function deleteClient(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            //dispatch(request(params));
            hairdresserService.deleteClient(params)
                .then(response => {
                    resolve(response);

                    //let { serverResponseData } = ResponseFilter(response);

                    //dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    //dispatch(failure(err));

                })
        })
    };
    // function request(getHairdresserStaffRequest) { return { type: hairdresserConstants.HAIRDRESSER_STAFF_REQUEST, getHairdresserStaffRequest } }
    // function success(getHairdresserStaffSuccess) { return { type: hairdresserConstants.HAIRDRESSER_STAFF_SUCCESS, getHairdresserStaffSuccess } }
    // function failure(getHairdresserStaffFail) { return { type: hairdresserConstants.HAIRDRESSER_STAFF_ERROR, getHairdresserStaffFail } }
}

function checkEmailAvailability(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            hairdresserService.checkEmailAvailability(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}


function serviceHairdresserList(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            hairdresserService.serviceHairdresserList(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}


function getLastBookedClients (params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            hairdresserService.getLastBookedClients(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}

function getHairdresserPolicies(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            hairdresserService.getHairdresserPolicies(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}


function dairyFilterData(data) {
    return function (dispatch) {
          dispatch(success(data));
        }
    function success(hairdresserStaff) { return { type: hairdresserConstants.HAIRDRESSER_STAFF_FILTER_DATA, hairdresserStaff } }
}

function activeFilterStatus(data) {
    return function (dispatch) {
          dispatch(success(data));
        }
    function success(activeStatus) { return { type: hairdresserConstants.ACTIVE_FILTER_STATUS, activeStatus } }
}

function dairyFilterStaffOrderList(data) {
    return function (dispatch) {
          dispatch(success(data));
        }
    function success(hairdresserStaff) { return { type: hairdresserConstants.STAFF_FILTER_ORDERED_LIST, hairdresserStaff } }
}


function selectedService(data){
    return function (dispatch) {
        dispatch({ type: hairdresserConstants.SELECTED_SERVICE, data })
    };
  };


function salonOwnerInfo(data){
    return function (dispatch) {
        dispatch({ type: hairdresserConstants.SALON_OWNER_INFO, data })
    };
  };

function saveWorkingRota(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            hairdresserService.saveWorkingRota(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}
