import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab, Modal } from 'react-bootstrap';
import SideNav from '../template/SideNav';
import TopHeader from '../template/TopHeader';
import { Link, Redirect } from 'react-router-dom';
import siteSetting from "../../config/env";
import {userActions} from "../../_actions";
import toast from "react-toast-notification";
import heic2any from "heic2any";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import {salonService} from "../../_services";
import SettingSidebar from './SettingSidebar';
import SettingHeadingTitle from './SettingHeadingTitle';

const getSuggestionValue = suggestion => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
    <div>
        {suggestion.name}
    </div>
);
const searchOptions = {
    //componentRestrictions: { country: ['GB'] },
    //types: ['city']
}
class SalonSettings extends Component {

    constructor(props){
        super(props);

        this.state = {
            isProfileSettingTab: false,
            salonImagePrev:require('../../assets/images/salonpic.png'),
            salonImage:'',
            salonName:'',
            allow:false,
            salonUrl:'',
            deviceToken:'asfasfasfas',
            isShow:localStorage.getItem('isShow'),
            deleteSalonModal: false,
            isEdit:false,
            clientShare:0,
            isLoading:false,
            diaryShare:0
        }
        this.onChanged=this.onChanged.bind(this);
        this.updateSalon=this.updateSalon.bind(this);
        this.checkReadOnly=this.checkReadOnly.bind(this);
    }

    componentDidMount() {
        let employmentType={};
        var self = this;
        if (localStorage.getItem('accessToken')) {
            let params={
                defaultRedirectTo:localStorage.getItem('defaultRedirectTo')
            }
            params.latitude = siteSetting.default_latitude;
            params.longitude = siteSetting.default_longitude;
            self.props.dispatch(userActions.profile(params))
                .then(function (response) {
                    console.log("response",response.data.data);
                    if(response.data.data) {
                        if(self.props.user.userInfo.hairdresser.salon){
                            self.setState({
                                salonUrl:siteSetting.api.weburl+'salon/' +self.props.user.userInfo.hairdresser.salon._id,
                                salonName:self.props.user.userInfo.hairdresser.salon.salonName,
                                formattedAddress:self.props.user.userInfo.hairdresser.salon.formattedAddress,
                                administrativeAreaLevel1: self.props.user.userInfo.hairdresser.salon.administrativeAreaLevel1,
                                administrativeAreaLevel2: self.props.user.userInfo.hairdresser.salon.administrativeAreaLevel2,
                                countryLongName: self.props.user.userInfo.hairdresser.salon.countryLongName,
                                countryShortName: self.props.user.userInfo.hairdresser.salon.countryShortName,
                                latitude: self.props.user.userInfo.hairdresser.salon.loc[1],
                                longitude: self.props.user.userInfo.hairdresser.salon.loc[0],
                                salonId: self.props.user.userInfo.hairdresser.salon._id,
                                isShow: self.props.user.userInfo.hairdresser.salon.isShow,
                                postalCode:self.props.user.userInfo.hairdresser.salon.postalCode,
                                clientShare:self.props.user.userInfo.hairdresser.salon.clientShare,
                                salonImagePrev:self.props.user.userInfo.hairdresser.salon.imageUrl?self.props.user.userInfo.hairdresser.salon.imageUrl:require('../../assets/images/salonpic.png'),
                                diaryShare:self.props.user.userInfo.hairdresser.salon.diaryShare,
                            })
                        }
                    }else{
                        self.props.history.push('/')
                    }
                })
                .catch((err) => {
                    self.props.history.push('/')
                })
        }else{
            self.props.history.push('/')
        }
    }

    handleChange = address => {
        this.setState({ formattedAddress:address });
    };

    handleSelect = address => {
        geocodeByAddress(address)
            .then(results => {
                // parseGooglePlace(results)
                // console.log("results===>>",results);
                let countryShortName='';
                let countryLongName='';
                let postalCode='';
                let administrativeAreaLevel1='';
                let administrativeAreaLevel2='';

                const address_components = results[0].address_components;
                for (let i = 0; i < address_components.length; i++) {
                    const component = address_components[i];
                    if (component['types'][0]) {
                        if (component['types'][0] === 'administrative_area_level_2') {

                            administrativeAreaLevel2 = component['long_name'];

                        }
                        if (component['types'][0] === 'administrative_area_level_1') {

                            administrativeAreaLevel1 = component['short_name'];

                        }
                        if (component['types'][0] === 'postal_code') {

                            postalCode = component['long_name'];

                        }
                        if (component['types'][0] === 'country') {

                            countryShortName = component['short_name'];
                            countryLongName= component['long_name'];

                        }
                    }
                }
                this.setState({
                    formattedAddress:results[0].formatted_address,
                    countryShortName:countryShortName,
                    countryLongName:countryLongName,
                    postalCode:postalCode,
                    administrativeAreaLevel1:administrativeAreaLevel1,
                    administrativeAreaLevel2:administrativeAreaLevel2,
                });

                return getLatLng(results[0])
            })
            .then(latLng => {
                console.log('Success', latLng);
                this.setState({
                    latitude: latLng.lat?latLng.lat:siteSetting.default_latitude,
                    longitude: latLng.lng?latLng.lng:siteSetting.default_longitude,
                })
            })
            .catch(error => console.error('Error', error));
    };

 

    onChanged=async (e)=> {
        let self = this;
        //console.log("e.target.name",e.target)
        this.setState({ [e.target.name]: e.target.value, copied: false });

        if (e.target.name === 'email') {
            if (!this.validateEmail(e.target.value)) {
                this.setState({
                    emailError: 'Please enter a valid email address'
                })
            } else {
                this.setState({
                    emailError: ''
                })
            }
        }

        if (e.target.name === 'first_name') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    fNameError: ''
                })
            } else {
                this.setState({
                    fNameError: '',
                })
            }
        }

        if (e.target.name === 'salonImage') {
            // console.log("e",e);
            let reader = new FileReader();
            let file = e.target.files[0];
            var fileType = file["type"]; // get file MIME type
            console.log("fileType11",file,fileType);

            if (file && file.type === "image/heic") {
                let fileDate= await self.convertToJpeg(file);

                let {url,blob}=fileDate;
                // console.log("url,blob",url,blob);
                this.setState({
                    salonImagePrev: url
                })
                this.setState({
                    salonImage: blob
                },function(){
                    self.updateSalon();
                });
            }
           else{
            reader.onloadend = () => {
                this.setState({
                    salonImagePrev: reader.result
                });
            }
            reader.readAsDataURL(file)

            this.setState({
                salonImagePrev: file
            })
            this.setState({
                salonImage: e.target.files[0]
            },function(){
                self.updateSalon();
            });
        }
        }
    }

    toggleChange = () => {
        this.setState({
            isShow: !this.state.isShow,
        });
    }
    convertToJpeg = async (file) => {
        try {
            const blob = await heic2any({ blob: file, toType: "image/jpeg" });
            const url = URL.createObjectURL(blob);
            //console.log("blob",blob);
            return {url,blob};
        } catch (error) {
            console.error("Conversion error:", error);
        }
    };
    clip=(event)=>{
        event.target.select();
        navigator.clipboard.writeText(this.state.salonUrl)
    }

    updateSalon(){
        this.setState({
            error:''
        })
        let self = this;

            if (this.state.salonName === "" || this.state.salonName === undefined) {
                this.setState({
                    salonNameError: 'Please enter salon name'
                })
            } else if (this.state.formattedAddress === "" || this.state.formattedAddress === undefined) {
                this.setState({
                    formattedAddressError: 'Please enter salon address'
                })
            } else {
                let data = new FormData();
                data.append('salonId', this.state.salonId);
                data.append('salonName', this.state.salonName);
                data.append('formattedAddress', this.state.formattedAddress);
                data.append('latitude', parseFloat(this.state.latitude));
                data.append('longitude', parseFloat(this.state.longitude));
                data.append('countryShortName', this.state.countryShortName);
                data.append('countryLongName', this.state.countryLongName);
                data.append('postalCode', this.state.postalCode);
                data.append('administrativeAreaLevel1', this.state.administrativeAreaLevel1);
                data.append('administrativeAreaLevel2', this.state.administrativeAreaLevel2);
                data.append('deviceToken', this.state.deviceToken);
                data.append('image', this.state.salonImage);
                data.append('isShow', this.state.isShow);
                data.append('clientShare', this.state.clientShare);
                data.append('diaryShare', this.state.diaryShare);
                this.setState({
                    isLoading:true
                })
                
                salonService.updateSalon(data)
                    .then(function (response) {
                        if (response.data.errorCode) {
                            if (response.data.errorCode == 51) {
                                self.setState({
                                    salonNameError: response.data.messages
                                })
                                toast.error(response.data.messages);
                            }
                            else {
                                toast.error(response.data.messages);
                                self.setState({
                                    error: response.data.messages
                                })
                            }
                        } else {
                            localStorage.setItem('isShow',self.state.isShow);
                            //self.props.history.push('/setting')
                            toast.success('Your salon has been updated successfully');
                            self.setState({
                                successMessage:'Your salon has been updated successfully!',
                                isEdit:false
                            })
                        }

                        self.setState({
                            isLoading:false
                        })
                    })
                    .catch((err) => {
                        console.log("errr", err);
                        //dispatch(failure(err));

                    })
            }

    }

    gotoHome=()=>{
        this.props.history.push('/diary/'+localStorage.getItem('salonId'))
    }

    handleClose = () => this.setState({
        deleteSalonModal: false,
    })

    checkReadOnly(){
        if(this.props.user&&this.props.user.userInfo&&this.props.user.userInfo.hairdresser.isSalonOwner===1){
            return true;
        }else{
            return false;
        }
    }

    noClick=()=>{

    }
    componentWillUnmount(){
        document.body.classList.remove('setting-temps-pages');
    }


    editStatusChange=(status)=>{
        this.setState({
            isEdit:status
        })
    }

    checkboxChange=(e)=>{
        let self=this;
        if (typeof e === "object" && e.target.id === "clientShare") {
            this.setState({ clientShare: this.state.clientShare===1?0:1 },function (){
                self.updateSalon();
            })
        }
        if (typeof e === "object" && e.target.id === "diaryShare") {
            this.setState({ diaryShare: this.state.diaryShare===1?0:1 },function (){
                self.updateSalon();
            })
        }
    }

    render() {
        let {
            deleteSalonModal,
        } = this.state;
        // console.log("settings",this.state,this.checkReadOnly());
        console.log("settings props",this.props);
        let { isProfileSettingTab } = this.state;
        return (
            <div className="h_n">
            <div className="body_container cm_bg_white setting_page">

                <TopHeader title="Salon Settings" {...this.props}/>
                <SideNav {...this.props}/>

                <div className='new-setting-wrapper'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <SettingHeadingTitle title="Salon Details" />
                                <SettingSidebar />
                            </div>
                            <div className='col-md-8'>
                                <div className='salon-body-main-wrp saloon-dtls-setting-mob'>
                                    <div className='setting-salon-card-img new-stng-cmn'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='setting-sln-img'>
                                                    <h5 className='d-block d-lg-none '>Salon Image</h5>
                                                    <div className="file_upload_wrap">
                                                    <input  ref={(ref) => this.salonImage = ref}  type="file" name="salonImage"  onChange={this.onChanged} style={{display:'none'}}/>
                                                        <img src={this.state.salonImagePrev} alt="staff" />
                                                        {/*onClick={(e) =>this.state.isEdit===true?this.salonImage.click():this.noClick()}*/}
                                                            {/*</>:""}*/}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='setting-detls-img pl-md-3'>
                                                    <h5 className='d-none d-lg-block '>Salon Image</h5>
                                                    <p>This image will be used to advertise your business on the Beu marketplace and will be sent to clients when making bookings.</p>
                                                    {this.checkReadOnly() ?
                                                    <div className='text-center'>
                                                        <button className={'btn btn-black w-100'} onClick={(e) => this.salonImage.click()} >Change Image</button>
                                                    </div>
                                                        :""}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='new-stng-cmn saloon-settings-wrp sln-fmr-dtls-mob'>
                                        <h5>Salon Details</h5>
                                        <div className="form-group">
                                            <label>Salon Name</label>
                                            <input type="text" className="form-control" value={this.state.salonName} name="salonName" onChange={this.onChanged} disabled={this.state.isEdit===true? "":"disabled"}/>
                                            {this.state.salonNameError ?
                                                <span className="cm_error_msg">{this.state.salonNameError}</span> : ''}
                                        </div>

                                        <div className="form-group">
                                            <label>Salon Location</label>
                                            {/*<input type="text" className="form-control" value="12, Thrift Street, London, SW21 6CL" />*/}
                                            <div className={this.state.isEdit===true?"address_input_wrap w-100":"address_input_wrap disabled w-100"}>
                                                <PlacesAutocomplete
                                                    searchOptions={searchOptions}
                                                    //disabled={this.checkReadOnly().toString()}
                                                    value={this.state.formattedAddress}
                                                    onChange={this.handleChange}
                                                    onSelect={this.handleSelect}
                                                >
                                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                        <div>
                                                            <input
                                                                {...getInputProps({
                                                                    placeholder: 'Search Places ...',
                                                                    className: 'location-search-input',
                                                                })}
                                                            />
                                                            {
                                                                suggestions.length ? <div className="autocomplete_location">
                                                                    {loading && <div>Loading...</div>}
                                                                    {suggestions.map(suggestion => {
                                                                        const className = suggestion.active
                                                                            ? 'suggestion-item--active'
                                                                            : 'suggestion-item';
                                                                        // inline style for demonstration purpose
                                                                        const style = suggestion.active
                                                                            ? { backgroundColor: '#ffffff', cursor: 'pointer' }
                                                                            : { backgroundColor: '#e4e4e4', cursor: 'pointer' };
                                                                        return (
                                                                            <div
                                                                                {...getSuggestionItemProps(suggestion, {
                                                                                    className,
                                                                                    style,
                                                                                })}
                                                                            >
                                                                                <span>{suggestion.description}</span>
                                                                            </div>
                                                                        );
                                                                    })}

                                                                </div> : ''
                                                            }

                                                        </div>
                                                    )}
                                                </PlacesAutocomplete>

                                                {/*<div className={`cm_check_btn ${this.state.isSalonCreated ? 'active' : ''}`}>*/}
                                                {/*    <i className="fa fa-check" onClick={this.salonCreate.bind(this)}/>*/}
                                                {/*</div>*/}

                                            </div>
                                            {this.state.formattedAddressError ?
                                                <span className="cm_error_msg">{this.state.formattedAddressError}</span> : ''}
                                        </div>
                                        {this.checkReadOnly() ?
                                            <>
                                        {this.state.isEdit===false?
                                        <div className='form-group text-right editSaloon'>
                                            <button className='btn btn-inf' onClick={()=>this.editStatusChange(true)}>Edit</button>
                                        </div>:""}
                                        {this.state.isEdit===true?
                                            <div className="text-right mt-4 pt-2">

                                                {/*<button className="btn btn-pink pl-4 pr-4">Delete Salon</button>*/}
                                                <button className="btn btn-outline-dark pl-4 pr-4 ml-3" onClick={()=>this.editStatusChange(false)}>Cancel</button>
                                                {this.state.isLoading?<button type="button" className="btn btn-dark ml-3 pl-4 pr-4" disabled>Saving...</button>:<button type="button" className="btn btn-dark ml-3 pl-4 pr-4" onClick={this.updateSalon.bind(this)}>Save</button>}
                                                <br/>
                                                <br/>

                                                {/* {this.state.error ?
                                                    <span className="cm_error_msg">{this.state.error}</span> : ''}
                                                {this.state.successMessage ?
                                                <span className="cm_succ_msg">{this.state.successMessage}</span> : ''} */}

                                            </div>
                                            :""}</>:""}
                                    </div>
                                    {this.checkReadOnly() ?
                                    <div className='new-stng-cmn saloon-settings-wrp sln-fmr-dtls-mob'>
                                        <h5>Client list</h5>
                                        <div className='salon_switch_container'>
                                            
                                           <label className="switch">
                                              <input type="checkbox" id={'clientShare'} onChange={this.checkboxChange.bind(this)} checked={this.state.clientShare}/>
                                              <span className="slider round"></span>
                                           </label><p>Share salon client list with self-employed staff</p>
                                          
                                        
                                        </div>
                                    </div>:""}
                                    {this.checkReadOnly() ?
                                        <div className='new-stng-cmn saloon-settings-wrp sln-fmr-dtls-mob'>
                                            <h5>Diary share</h5>
                                            <div className='salon_switch_container'>

                                                <label className="switch">
                                                    <input type="checkbox" id={'diaryShare'} onChange={this.checkboxChange.bind(this)} checked={this.state.diaryShare}/>
                                                    <span className="slider round"></span>
                                                </label><p>Enable all staff to see each other's diary, and to add appointments.</p>


                                            </div>
                                        </div>:""}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

                <Modal show={deleteSalonModal} onHide={this.handleClose} className="bg_white_modal text-center">
                    <i className="fa fa-times cm_hide_mod" onClick={this.handleClose} />
                    <Modal.Body>
                        <div className="p-5">
                            <img src={require('../../assets/images/delete-salon-img.png')} className="img-fluid mb-3" />
                            <h3 style={{fontSize: '38px', fontWeight: '400'}} className="mb-4">Delete Salon</h3>
                            <p style={{fontSize: '23px', fontWeight: '300'}}>Are you sure you want to delete the salon? This will cancel all appointments and remove the salon from Beu. Staff will be free to join or create another salon.</p>

                            <div className="stap-action mt-5">
                                <button className="btn btn-outline-dark mr-3">Cancel</button>
                                <button className="btn btn-pink">Delete Salon</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

        </div>
        )
    }

}



function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(SalonSettings);





